import { motion } from "framer-motion";
import mobilePhone from "../../../assets/Images/product1.webp";
import productLaptop1 from "../../../assets/Images/Group 27495.webp";
import productLaptop2 from "../../../assets/Images/Group 27496.webp";

import Badge1 from "../../../assets/Images/Frame 27253.svg";
import Badge2 from "../../../assets/Images/Frame 27262.svg";
import Badge3 from "../../../assets/Images/Frame 27263.svg";
import Badge4 from "../../../assets/Images/Frame 27264.svg";

import Badge5 from "../../../assets/Images/Frame 27254.svg";
import Badge6 from "../../../assets/Images/Frame 27255.svg";
import Badge7 from "../../../assets/Images/Frame 27256.svg";
import Badge8 from "../../../assets/Images/Frame 27257.svg";

import Badge9 from "../../../assets/Images/Frame 27258.svg";
import Badge10 from "../../../assets/Images/Frame 27259.svg";
import Badge11 from "../../../assets/Images/Frame 27260.svg";
import Badge12 from "../../../assets/Images/Frame 27261.svg";

import "./Products.css";
import yellowVector from "../../../assets/Icon/yellowVector.svg";
import orangeVector from "../../../assets/Icon/orangeVector.svg";
import greenVector from "../../../assets/Icon/greenVector.svg";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Products = () => {
  const { t } = useTranslation();

  const productsCard = [
    {
      title:
        "Discover, Explore, And Apply To Universities Abroad In Easy Simple Steps",
      desc: "Using education technologies, we have simplified the university selection, application, and admissions process for anyone to study abroad. Paramount Students offers students the opportunity to apply to universities at destinations around the world, through a simple process. Studying abroad has never been this easy, safe, and transparent. Our solutions ease the burden on students, colleges, and universities alike – making studying abroad easier than ever before.",
      list1: "Get Verified",
      list2: "Find your desired university courses",
      list3: "Apply To Your University Of Choice",
      icon: yellowVector,
      image: mobilePhone,
      badge1: Badge1,
      badge2: Badge2,
      badge3: Badge3,
      badge4: Badge4,
      buttonTex: "Discover Our App",
      href: "/mobileapp",
    },
    {
      title: "Premium Study Abroad Services For High Schools",
      desc: "At Paramount Students, we are committed to make studying abroad a simple and efficient process. Our mission is to provide high schools with the tools and resources they need to enhance the study abroad experience for their students and prepare them for a successful future anywhere in the world.",
      list1: "Streamline the application process",
      list2: "Professional career guidance",
      list3: "Efficient document management to study abroad",
      icon: orangeVector,
      image: productLaptop1,
      badge1: Badge5,
      badge2: Badge6,
      badge3: Badge7,
      badge4: Badge8,
      buttonTex: "Request a demo",
      href: "/high-school-demo",
    },
    {
      title:
        "Bridge The Gap Between Universities and Prospective International Students",
      desc: "Our simplified system connects with universities around the world. We take pride on being a global study abroad solution. Students and universities can now engage in a direct channel and conduct a safe, secure, and reliable application process. Through our services, the student verification process for universities is cut down by more than 80%. It's never been this simple! For universities, our services include:",
      list1: "A direct university overview to all study abroad candidates",
      list2: "A simplified and secure student portal to review applications",
      list3: "Easy tracking, management, and processing of data",
      icon: greenVector,
      image: productLaptop2,
      badge1: Badge9,
      badge2: Badge10,
      badge3: Badge11,
      badge4: Badge12,
      buttonTex: "Request a demo",
      href: "/university-demo",
    },
  ];

  return (
    <div className="pt-10 md:pt-16">
      {productsCard.map((card, index) => {
        return (
          <div className="products mb-20" key={index}>
            <div className="product-wrap flex flex-col md:gap-[110px] justify-around md:flex md:flex-row-reverse">
              <motion.div
                className="wrapper"
                transition={{ duration: 1, delay: 0.25 }}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}>
                <div className="intro mb-4">
                  <p className="text-base md:text-lg">{t("Our Products")}</p>
                  <hr />
                </div>
                <div className="heading">
                  <h4 className="text-2xl md:text-4xl">{t(card.title)}</h4>
                </div>
                <div className="text">
                  <p className="leading-4 md:leading-6">{t(card.desc)}</p>
                </div>
                <div className="product">
                  <div className="list flex">
                    <motion.ul
                      className="list-none"
                      transition={{ duration: 1, delay: 0.25 }}
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      viewport={{ once: true }}>
                      <li className="flex text-sm md:text-[1rem] gap-[17px] capitalize">
                        <span>
                          <img loading="lazy" src={card.icon} alt="card icon" />
                        </span>
                        {t(card.list1)}
                      </li>
                      <li className="flex text-sm md:text-[1rem] gap-[17px] capitalize">
                        <span>
                          <img loading="lazy" src={card.icon} alt="card icon" />
                        </span>
                        {t(card.list2)}
                      </li>
                      <li className="flex text-sm md:text-[1rem] gap-[17px] capitalize">
                        <span>
                          <img loading="lazy" src={card.icon} alt="card icon" />
                        </span>
                        {t(card.list3)}
                      </li>
                      <li className="flex text-sm md:text-[1rem] gap-[17px] capitalize pt-3">
                        {card.buttonTex ? (
                          card.buttonTex === "Discover Our App" ? (
                            <a
                              href={card.href}
                              className="bannerBtn1 h-14 md:h-20 px-8 md:px-10 bg-[#ffc400] text-[#131313] text-base md:text-lg"
                              target="_blank"
                              rel="noopener noreferrer">
                              {t(card.buttonTex)}
                            </a>
                          ) : (
                            <Link
                              className="bannerBtn1 h-14 md:h-20 px-8 md:px-10 bg-[#ffc400] text-[#131313] text-base md:text-lg"
                              to={card.href}>
                              {t(card.buttonTex)}
                            </Link>
                          )
                        ) : (
                          ""
                        )}
                      </li>
                    </motion.ul>
                  </div>
                </div>
              </motion.div>

              <motion.div
                className="productImg first"
                transition={{
                  duration: 1,
                  delay: 0.25,
                }}
                initial={{ opacity: 0, x: -200 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}>
                <div className="phone">
                  <img
                    className="w-full md:w-[532px]"
                    src={card.image}
                    alt="icon"
                    loading="lazy"
                  />
                  <div className="badges--container justify-between flex flex-col ">
                    <motion.div
                      className="float-right mt-[10%] swurf"
                      style={{
                        alignSelf: index === 2 ? "flex-start" : "flex-end",
                      }}
                      initial={{ opacity: 0, x: 100 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.5, delay: 1 }}>
                      <img
                        loading="lazy"
                        src={card.badge1}
                        alt="badge"
                        className="sm:w-[20%] md:w-[100%]"
                      />
                    </motion.div>

                    <motion.div
                      className=" mt-[10%] swurf2"
                      style={{
                        alignSelf: index === 2 ? "flex-end" : "flex-start",
                      }}
                      initial={{ opacity: 0, x: -100 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.5, delay: 1.2 }}>
                      <img loading="lazy" src={card.badge2} alt="badge" />
                    </motion.div>

                    <motion.div
                      className="mt-[10%] swurf"
                      style={{
                        alignSelf: index === 2 ? "flex-start" : "flex-end",
                      }}
                      initial={{ opacity: 0, x: 100 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.5, delay: 1.2 }}>
                      <img loading="lazy" src={card.badge3} alt="badge" />
                    </motion.div>

                    <motion.div
                      className="mt-[10%] swurf2"
                      style={{
                        alignSelf: index === 2 ? "flex-end" : "flex-start",
                      }}
                      initial={{ opacity: 0, x: -100 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.5, delay: 1.2 }}>
                      <img loading="lazy" src={card.badge4} alt="badge" />
                    </motion.div>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Products;
