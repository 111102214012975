import React, { useState } from 'react';
import V1 from '../../../assets/Images/v1.png';
import V2 from '../../../assets/Images/v2.png';
import V3 from '../../../assets/Images/v3.png';
import V4 from '../../../assets/Images/mV-sm.jpg';
import vIcon from '../../../assets/Images/vIcon.png';
import { Swiper, SwiperSlide } from 'swiper/react'; // Import Swiper components
import 'swiper/swiper-bundle.min.css'; // Import Swiper styles
// import Image1 from "../../../assets/Images/VirtualTour/TaksimSquare.jpg";
import './UniVirtualTour.css';
// import { ReactPhotoSphereViewer } from "react-photo-sphere-viewer";
/* import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'; */

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
const UniVirtualTour = () => {
  
  const images = [
    {
      img:V4,
      name:'Campus Library',
      text:'University of Alberta',
    },
    {
      img: V1,
      name: 'Dormitories',
      text: 'University of Alberta',
    },
    {
      img: V2,
      name: 'Lecture halls',
      text: 'University of Alberta',
    },
    {
      img: V3,
      name: 'Lecture halls',
      text: 'University of Alberta',
    },
  ];
  const defaultSelectedImage = images[0]; // Set the default selected image
  const [selectedImage, setSelectedImage] = useState(defaultSelectedImage);
  const [imageIndex, SetimageIndex] = useState(0);

  const { t } = useTranslation();
  // console.log(CountryCodes);
/*   useEffect(()=>{
    setSelectedImage(images[0])
    
  },[selectedImage]) */
  const viewTour = function(tour,index){
    SetimageIndex(index)
    setSelectedImage(tour)
  }
  return (
    <div className="relative md:my-[100px] universal-tour">
      <h1 className="block md:hidden text-2xl ml-5 mb-5">Virtual Tour</h1>
      <h1  onClick={() =>{ imageIndex<images.length-1? viewTour(images[imageIndex+1],imageIndex+1):viewTour(images[0],0) }}
        style={{ fontStyle: 'italic' }}
        className="hidden md:flex items-center text-[40px] mb-10 nextBtn"
      >
        <i>
        Next
        </i>
        <span className="ml-5">
        <svg
            width="192"
            height="23"
            viewBox="0 0 192 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M190.713 13.006C191.298 12.4202 191.298 11.4704 190.713 10.8847L181.167 1.33871C180.581 0.752924 179.631 0.752924 179.045 1.33871C178.46 1.9245 178.46 2.87424 179.045 3.46003L187.531 11.9453L179.045 20.4306C178.46 21.0164 178.46 21.9661 179.045 22.5519C179.631 23.1377 180.581 23.1377 181.167 22.5519L190.713 13.006ZM0.832031 13.4453H189.652V10.4453H0.832031V13.4453Z"
              fill="black"
            />
          </svg>
          
        </span>
      </h1>
      <div className="flex">
        <div className="relative md:rounded-[20px] flex justify-center items-center w-full md:w-[50%] h-[50vh] md:h-[80vh]" style={{ backgroundImage: 'url('+selectedImage.img+')', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
          <Link to='/vtour'>
          <img
            loading="lazy"
            alt="icon"
            src={vIcon}
            key="icon"
            className="w-[6rem] h-[6rem]"
          />
          </Link>
          <p className="text-white absolute bottom-[2rem] left-[2rem] max-w-xs text-2xl md:text-3xl font-medium" style={{textShadow:'1px 2px 3px #555'}}>
            {selectedImage.text} {selectedImage.name}
          </p>
        </div>
        <div className="ml-20 hidden md:block m">
          <h1 className="text-4xl font-semibold">Virtual Tour</h1>
          <div className="mt-10">
            <h2 className="text-3xl font-medium mb-3">Alberta University</h2>
            <p className="max-w-prose leading-[1.5rem]">
              {t(
                'Discover the beauty of every campus, just like you are there! Explore each corner and find the campus that suits you best.',
              )}
            </p>
          </div>
          <div className="mt-10 flex">
            <Link
              className="bannerBtn1 h-14 md:h-20 px-8 md:px-10 bg-[#ffc400] text-[#131313] text-sm md:text-base capitalize"
              to="/virtual-tour-request"
            >
              {t('Request for virtual tour')}
            </Link>
          </div>
        </div>
      </div>
      <div>
      <Swiper
            className="mt-5 md:mt-0 w-full md:w-[60%] md:absolute md:bottom-[0.5rem] drop-shadow-lg right-[8px] md:right-[30px]"
            
            spaceBetween={10}
            slidesPerView={3}
            loop={false}
          >
            {images.map((img, index) => (
              <SwiperSlide key={index} onClick={() => viewTour(img,index)} style={{cursor:'pointer'}}>
                <div
                  className="w-[170px] h-[170px] md:w-[140px] lg:w-[180px]  md:h-[180px] hidden md:block mx-4 relative blog-card"
                  style={{
                    background: 'url(' + img.img + ')',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                  }}
                >
                  <div
                    className="p-[10px] absolute bottom-[-5px] md:bottom-[4px] text-white"
                    style={{ zIndex: 2 }}
                  >
                    <h5 className="text-shadow">{t(img.name)}</h5>
                    <span className="text-shadow text-xs">{t(img.text)}</span>
                  </div>
                </div>
                <div
                  className="w-[115px] h-[115px] block md:hidden mx-4 relative blog-card2"
                  style={{
                    background: 'url(' + img.img + ')',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                  }}
                >
                  <div
                    className="p-[10px] absolute bottom-[-5px] md:bottom-[4px] text-white"
                    style={{ zIndex: 2 }}
                  >
                    <h5 className="text-shadow">{t(img.name)}</h5>
                    <span className="text-shadow text-xs">{t(img.text)}</span>
                  </div>
                </div>
              </SwiperSlide>
                ))}
              </Swiper>
        {/* <Splide
          hasTrack={false}
          aria-label="..."
          options={{
            perPage: 3.5,
            arrows: false,
            pagination: false,
            drag: 'free',
            type: 'loop',
            breakpoints: {
              768: {
                perPage: 3,
              },
            },
          }}
        >
          <SplideTrack className="mt-5 md:mt-0 w-full md:w-[60%] md:absolute md:bottom-[0.5rem] drop-shadow-lg right-[8px] md:right-[30px]">
            {images.map((img, index) => (
              <SplideSlide key={index} onClick={() => viewTour(img)} style={{cursor:'pointer'}}>
                <div
                  className="w-[170px] h-[170px] md:w-[180px] md:h-[180px] hidden md:block mx-4 relative blog-card"
                  style={{
                    background: 'url(' + img.img + ')',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                  }}
                >
                  <div
                    className="p-[10px] absolute bottom-[-5px] md:bottom-[4px] text-white"
                    style={{ zIndex: 2 }}
                  >
                    <h5 className="text-shadow">{t(img.name)}</h5>
                    <span className="text-shadow text-xs">{t(img.text)}</span>
                  </div>
                </div>
                <div
                  className="w-[115px] h-[115px] block md:hidden mx-4 relative blog-card2"
                  style={{
                    background: 'url(' + img.img + ')',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                  }}
                >
                  <div
                    className="p-[10px] absolute bottom-[-5px] md:bottom-[4px] text-white"
                    style={{ zIndex: 2 }}
                  >
                    <h5 className="text-shadow">{t(img.name)}</h5>
                    <span className="text-shadow text-xs">{t(img.text)}</span>
                  </div>
                </div>
              </SplideSlide>
            ))}
          </SplideTrack>
        </Splide> */}
      </div>
      <h1
      onClick={() =>{ imageIndex<images.length-1? viewTour(images[imageIndex+1],imageIndex+1):viewTour(images[0],0) }}
        style={{ fontStyle: 'italic' }}
        className="flex px-5 md:hidden items-center text-2xl mt-5 nextBtn"
      >
        <i>
        Next
        </i>
        <span className="ml-5 text-xs">
          <svg
            width="192"
            height="23"
            viewBox="0 0 192 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="max-w-[6rem]"
          >
            <path
              d="M190.713 13.006C191.298 12.4202 191.298 11.4704 190.713 10.8847L181.167 1.33871C180.581 0.752924 179.631 0.752924 179.045 1.33871C178.46 1.9245 178.46 2.87424 179.045 3.46003L187.531 11.9453L179.045 20.4306C178.46 21.0164 178.46 21.9661 179.045 22.5519C179.631 23.1377 180.581 23.1377 181.167 22.5519L190.713 13.006ZM0.832031 13.4453H189.652V10.4453H0.832031V13.4453Z"
              fill="black"
            />
          </svg>
        </span>
      </h1>
      <div className="px-5 block md:hidden">
        <div className="mt-5">
          <h2 className="text-xl mb-3">Alberta University</h2>
          <p className="text-xs leading-[1.125rem]">
            {t(
              'Discover the beauty of every campus, just like you are there! Explore each corner and find the campus that suits you best.',
            )}
          </p>
        </div>
        <div className="mt-5 flex">
          <Link
            className="bannerBtn1 h-14 md:h-20 px-8 md:px-10 bg-[#ffc400] text-[#131313] text-sm md:text-base capitalize"
            to="/virtual-tour-request"
          >
            {t('Request for virtual tour')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default UniVirtualTour;
