import { createSlice } from "@reduxjs/toolkit";

const langSlice=createSlice({
    name:"lang",
    initialState:{
        lang:"EN",
        langflag:{
            key: "EN",
            id:"ENGLISH",
            label: (
              <div className="font-bold ml-[0.75rem] my-1 mx-2 text-lg leading-[1.31rem] flex justify-between ">
                
                <img className="w-[1.31rem] h-4 " src={require("../assets/Icon/Flags.webp")} alt="" />
                <p>ENGLISH</p> 
              </div>
            ),
            image:"/assets/Icon/Flags.webp",
          },
    },
    reducers:{
        changeLang:(state,action)=>{
            state.lang=action.payload
            
        },
        changeFlag:(state,action)=>{
           
            state.langflag=action.payload
        }
    }

})
export const {changeLang,changeFlag}=langSlice.actions
export default langSlice.reducer;