import React from 'react';
import Home from '../../components/WaitList/Home';
import ExplorerSection from '../../components/WaitList/ExplorerSection';
console.log(4555555)
delete window.google
function WaitList() {
  return (
    <div className='h-screen bg-[#FDFAF4]'>
      <Home />
      <ExplorerSection/>
    </div>
  );
}
export default WaitList;
