import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import Layout from '../../components/Layout';
import PostCard from '../../components/Blog/PostCard';
import PaginationPages from '../../components/Generic/PaginationPages';
import { Link } from 'react-router-dom';
import { fetchData } from '../../api/axios';
import usePageMetadata from '../../lib/usePageMetadata';

const Blog = () => {
  const { t } = useTranslation();
  const [posts, setPosts] = useState([]);
  usePageMetadata('Blog | Paramount Students');
  useEffect(() => {    
    fetchData('/api/blog')
      .then((data) => {
        
        setPosts(data.data); // Update posts state with fetched data
      })
      .catch((error) => {
        console.log('Error fetching blog content:', error);
      });

  }, []);

  return (
    <Layout transparentHeaderOnPhone>
      <main className="page">
        <section className="mb-20 md:mb-28 md:mt-28">
          <div className="max-w-screen-lg mx-auto md:px-8">
            <div className="flex flex-col md:flex-row gap-8">
              <div className="md:max-w-3xl">
              <Link aria-label={posts?.data?.[0]?.title} to={`/blog/${posts?.data?.[0]?.id}`} className='text-inherit'>
                <PostCard
                  title={posts?.data?.[0]?.title}
                  description={posts?.data?.[0]?.description}
                  dateString={posts?.data?.[0]?.created_at}
                  bannerImage={posts?.data?.[0]?.bannerImage}
                  hoverStyle="none"
                  cardSize="lg"
                />
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section className="my-20 md:my-28">
          <div className="max-w-screen-lg mx-auto px-4 sm:px-8">
            <div className="title mb-4 px-2">
              <span className="hr-sect text-lg">{t('Blog')}</span>
            </div>
            <div className="px-2 mb-8 md:mb-16 max-w-xl mx-auto">
              <h1 className="text-center text-2xl md:text-3xl capitalize">
                {t('Stay up-to-date with our Student Scoop Blog')}
              </h1>
            </div>

            {/* Posts */}
            <div className="flex sm:gap-x-[4%] md:gap-x-[3.5%] gap-y-10 flex-wrap">
              {posts?.data?.map((post) => (
                <div
                  key={post.id}
                  className="max-w-full sm:max-w-[48%] md:max-w-[31%]"
                >
                  <Link aria-label={post.title} to={`/blog/${post.id}`} className='text-inherit'>
                    <PostCard
                      title={post.title}
                      dateString={post.created_at}
                      bannerImage={post.bannerImage}
                    />
                  </Link>
                </div>
              ))}
            </div>

            {/* Pagination */}
            <div className="mt-16">
              <PaginationPages
                data={posts}
              />
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default Blog;
