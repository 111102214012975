import React, { useEffect } from "react";
import VirtualTourRequestForm from "../../components/Modals/VirtualTourRequest";
import { useNavigate } from "react-router-dom";

const VirtualTourRequestPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Virtual Tour Request";
  }, []);

  return (          
      <main className="page about-page">
        <VirtualTourRequestForm onclose={(()=>{navigate(-1)})}  />                
      </main>
  );
};

export default VirtualTourRequestPage;
