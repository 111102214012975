
const DocumentationItem = ({ title, className, children }) => {

  
  return (
    <div className={`${className}`}>
      {title && (
        <div className="flex items-center justify-start space-x-6 md:space-x-16 relative">
          <div className="bg-btn-background-1 absolute -translate-x-[50%] w-5 h-5 rounded-full flex items-center justify-center">
            <div className="bg-black w-2.5 h-2.5 rounded-full"></div>
          </div>
          <h2 className="text-[24px] md:text-[32px] uppercase font-medium leading-8 md:leading-10">
            {title}
          </h2>
        </div>
      )}

      <div className="mt-2 md:mt-6 pl-6 md:pl-16 text-base md:text-xl font-normal leading-6 md:leading-9">
        {children}
      </div>
    </div>
  );
};

export default DocumentationItem;
