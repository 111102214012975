import React from 'react';


const ExplorerSection = () => {
    return (
    <div className='flex justify-center'>
      <div className='w-[1300px]'>
        <div className='flex justify-center'>
          <div className='h-[120px] md:w-[600px] w-full px-4 text-center'>
            <h1 className='font-bold text-3xl md:text-4xl mb-4'>Explore Endless Possibilities.</h1>
            <p className='text-sm md:text-base '>With Paramount Students, your higher education journey is at the tip of the fingers. From South Africa to London, from East to West, Paramount Students has got you covered.</p>
          </div>
        </div>

        <div className='px-4'>
          <div className='md:flex grid grid-cols-1 md:gap-0 mb-3 mt-24'>
            <div className='w-full col-span-1 md:h-auto h-[100vh] md:bg-[length:90%_695px] bg-[length:300px] bg-center md:mb-4' style={{backgroundImage:"url('/image/Frame 48095919.png')", backgroundRepeat:"no-repeat"}}></div>
            <div className='col-span-3'>
              <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mb-6'>
                <div className='md:col-span-2 col-span-1 bg-[#F7F0E8] rounded-[28px] p-4 md:h-[301px]'>
                  <div className='flex justify-around  my-6'>
                    <div className='w-[45%]'>
                      <h1 className='font-bold text-lg mb-3'>Unlock multiple faculties</h1>
                      <p className='text-sm'>We ensure a seamless experience, guiding you towards your academic aspirations.</p>
                    </div>
                    <div className='mt-10'>
                      <img alt='ps' src='./image/yellow-folder-with-security-key-removebg-preview 1.png' className='md:w-[100%] mt-4 mx-auto' />
                    </div>
                  </div>
                </div>

                <div className='col-span-1 bg-[#B8CEDC] rounded-[28px] p-4 text-center h-[300px] md:h-[301px] '>
                  <h1 className='font-bold text-lg mb-1'>Quick Support</h1>
                  <p className='text-sm'>We ensure a proper guide throughout our platform for a seamless application process.</p>
                  <div className=''>
                   <img alt='ps' src='./image/iphone.jpg' className='mt-3 w-[75%] md:w-full rounded-t-lg mx-auto' style={{objectFit: "cover", objectPosition: "top"}}/>
                  </div>
                </div>
              </div>
              <div className='bg-purple-700 rounded-[28px] relative  text-white h-[auto] md:h-[350px] grid grid-cols-1' style={{backgroundImage:'url(./image/bg-section.png)'}}>            
                 <div className='md:w-[350px] w-[150px] md:h-[50%] h-[60%] bottom-0 absolute bg-[#9F25FF] rounded-tr-[35px]'>
                    <img alt='ps' src='/image/man.png' className=' md:w-[57%] w-[130px] h-[200px] md:h-[160%] w-[100px] absolute bottom-[0px] md:left-[19%]'/>
                  </div>
                 <div  className='md:h-[60px] h-[80px] '></div>
                 <div className='w-[80%] bg-[#B85DFF] md:p-[25px] p-[15px] place-self-end flex justify-between items-center'>
                    <div></div>
                    <div className='w-[280px] md:text-xl font-light ml-[95px] leading-8'>
                      Application process in 3 simple steps
                    </div>
                    <div className=''>
                        <img alt='ps' src='./image/icon.png' className='w-[20px] md:w-[55px] ' />
                      </div>
                  </div>
                  <ul className='place-self-end items-start md:h-[auto] md:mt-0 h-[80px] mt-3 sm:text-sm text-xs pl-3 md:pl-0 md:text-lg md:w-[56.5%] w-[63%] md:leading-10 leading-6	'>
                    <li className='float-left mx-10'>Select university</li>
                    <li className='float-left mx-10'>Apply for a program</li>
                    <li className='float-left mx-10'>Get Accepted</li>
                  </ul>
              </div>
            </div>
          </div>

          <div className='flex flex-col md:flex-row gap-4 mb-6'>
            <div className='bg-[#BAF96D] rounded-[28px] p-6 md:p-14 flex-1 h-[350px]'>
              <div className='md:w-[350px]'>
              <h1 className='font-bold text-xl mb-2'>Unlock 5000 plus international programs</h1>
              <p className='text-sm'>Using education technology we have simplified the university section, application, and admission process for anyone to study abroad. Paramount Students offers students the opportunity to apply to university at destinations around the world, through a simple process. Studying abroad has never been this easy, safe, and transparent. Our solutions ease the burden on students, challenges, and universities alike, making studying abroad easier than ever before.</p>
              </div>
            </div>
            <div className='bg-yellow-400 rounded-[28px] p-4 md:w-[400px] md:h-[350px]'>
              <div className='text-center mb-4 w-[300px] mx-auto'>
                <h1 className='font-bold text-lg'>Chat Section</h1>
                <p className='text-sm'>Communicate with a university of your choice, get answers to all your questions.</p>
              </div>
              <div className=''>
                <img alt='ps' src='./image/iPhone 15.png' className='  md:w-[342px] w-[200px]' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
};

export default ExplorerSection;
