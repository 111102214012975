import { Link } from "react-router-dom";
import rightArrow from "../../../assets/Icon/VectorR.svg";
import elipse3 from "../../../assets/Images/elipse3.png";

import { teamList } from "../../../lib/static/teamList.js";
import "../../../pages/Team/Team.module.css";
import "./Team.css";
import { useTranslation } from "react-i18next";
import React from "react";

const Team = () => {
  const { t } = useTranslation();
  // Calculate the number of team members available
  const maxTeamMembers = Math.min(7, teamList.length);
  console.log("maxTeamMembers:", maxTeamMembers, teamList.length);

  return (
    <div className="my-20 md:my-28">
      <div className="flex justify-between items-center mb-8 px-3">
        <h3 className="text-xl md:text-4xl max-w-[32rem] capitalize font-medium md:font-semibold">
          {t("Global Innovators Uniting to Transform the Industry")}
        </h3>

        <img
          src={elipse3}
          alt="spiral"
          className="max-w-[5rem] md:max-w-[20rem] w-full"
        />
      </div>
      <div className="cards grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-y-8 md:gap-y-16 mx-auto px-3">
        {teamList.slice(0, maxTeamMembers).map((item, index) => {
          return (
            <div
              key={index}
              className="justify-between flex flex-col items-center w-[100%]">
              <img
                loading="lazy"
                key={index}
                alt={item.name}
                className="w-[70px] md:w-[100px]"
                src={require("../../../" + item.image())}
              />
              <h3 className="mt-2 capitalize font-medium text-sm md:text-base text-center">
                {item.name}
              </h3>
              <p className="mt-1 capitalize text-xs md:text-sm text-center">
                {item.designation}
              </p>
            </div>
          );
        })}
      </div>
      <div className="mt-8 md:mt-16">
        <Link
          className="bannerBtn1 h-14 md:h-20 px-6 md:px-8 bg-[#FFFEFB] text-[#131313] border border-solid border-[#FFC400] text-sm capitalize max-w-[16rem] mx-auto"
          to="/meet-the-team">
          {t("Explore more")}
          <img loading="lazy" src={rightArrow} alt="icon" />{" "}
        </Link>
      </div>
    </div>
  );
};

export default Team;
