import React, { useState } from 'react';
import './style.css';
import FloatingLabelInput from '../../Generic/Input';
import bgImage from '../../../assets/Images/demo-request-page.png';
import close from '../../../assets/Icon/close.svg';
import Button from '../../Generic/Button';
import CustomModal from '../Modal';
import { postData } from '../../../api/axios';
const d = {
  universityName: '',
  fullName: '',
  phoneNumber: '',
  email: '',
  universityVirtualTour: '',
};
const UniversityRequestForm = ({ onclose }) => {
  const [form, setForm] = useState(JSON.parse(JSON.stringify(d)));

  const handleChange = (name, value) => {
    setForm({ ...form, [name]: value });
  };

  // Define an array of input fields with configurations
  const inputFields = [
    {
      name: 'universityName',
      label: 'University Name',
      type: 'text',
      placeholder: 'Enter university name',
    },
    {
      name: 'fullName',
      label: 'Full Name',
      type: 'text',
      placeholder: 'Enter full name',
    },
    {
      name: 'phoneNumber', // Fixed name
      label: 'Phone Number', // Adjusted label
      type: 'text',
      placeholder: 'Enter phone number', // Adjusted placeholder
    },
    {
      name: 'email',
      label: 'Email: Please write your email address',
      type: 'email',
      placeholder: 'Enter email',
    },
    {
      name: 'universityVirtualTour',
      label: 'Type here about the university virtual tour...',
      type: 'textarea',
      placeholder: 'Enter text',
    },
  ];

  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      data: form,
      type: 'virtual_tour',
    };
    await postData('api/send-mail-request', data);
    openModal();
    setForm(JSON.parse(JSON.stringify(d)));
  };

  const handleClose = (e) => {
    onclose();
  };

  return (
    <div className="bg-gray-100 h-[100vh] fixed w-[100%] z-[100]">
      <div className="flex  w-[100%] h-[inherit]">
        <div
          className="hidden sm:block flex-initial sm:w-[60%]"
          style={{
            backgroundImage: `url(${bgImage})`,
            backgroundSize: 'cover',
          }}
        >
          <div
            className="cursor-pointer inline-block p-4"
            onClick={handleClose}
          >
            <img className="w-[30px]" src={close} alt="Close" />
          </div>
        </div>
        <div className="flex-initial w-[100%] sm:w-[40%] mx-auto px-4 py-16 sm:px-6 sm:py-24 lg:px-8 lg:py-32 xl:px-12 bg-white">
          <div className="">
            <h2 className="text-4xl font-extrabold text-gray-900 sm:text-5xl">
              Let’s partner up
            </h2>
            <p className="mt-4 text-[16px] leading-6 text-gray-900">
              Let’s level up your brand, together.
            </p>
          </div>
          <div className="mt-12 sm:mt-16">
            <form onSubmit={handleSubmit}>
              <div className="space-y-4">
                {inputFields.map((field, index) => (
                  <FloatingLabelInput
                    type={field.type}
                    key={index}
                    value={form[field.name]}
                    onValueChange={(value) => handleChange(field.name, value)}
                    labelText={field.label}
                    placeholder={field.placeholder}
                  />
                ))}
                <Button
                  click={handleSubmit}
                  children="Submit"
                  className="bg-amber-400 w-[100%] md:py-3 border-0 md:rounded-md md:mt-[20px]"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="w-[100%]">
        {isModalOpen && (
          <CustomModal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            icon="success"
            title="Request Sent"
            width="335px"
            height="264px"
            text="We'll get back to you within 24 hours. Thank you so much for getting in touch with us"
          />
        )}
      </div>
    </div>
  );
};

export default UniversityRequestForm;
