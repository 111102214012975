import { useEffect, useState } from "react";

import Layout from "../../components/Layout";

import TopSection from "../../components/Home/TopSection";
import AboutUs from "../../components/Home/AboutUs";
import Services from "../../components/Home/Services";
import Products from "../../components/Home/Products";
import Blog from "../../components/Home/Blog";
import IncubationCenters from "../../components/Home/IncubationCenters";
import Community from "../../components/Home/Community";
import AffilatedUniversities from "../../components/Home/AffilatedUniversities";
import Team from "../../components/Home/Team";

import UniVirtualTour from "../../components/Home/UniVirtualTour";
import "./Home.css";
import "./Responsive.css";
import Loading from "../../components/Generic/Loading/index";
import { useDispatch, useSelector } from "react-redux";
import { isloading } from "../../redux/loadingSlice";

import elipse2 from "../../assets/Images/elipse2.png";
import elipse from "../../assets/Images/elipse.png";
import usePageMetadata from "../../lib/usePageMetadata";

const Home = () => {
  const dispatch = useDispatch();
  const isloaded = useSelector((state) => state.loading.status);
  const [isLoaded, setIsLoaded] = useState(false);
  // const [isloading, setIsloading] = useState(false)
  usePageMetadata("Revolutionizing Study Abroad | Paramount Students");
  useEffect(() => {
    //document.title = "Revolutionizing Study Abroad | Paramount Students";
    const image1 = new Image();
    image1.src = elipse;
    image1.onload = () => {
      setIsLoaded(true);
    };

    const image2 = new Image();
    image2.src = elipse2;
    image2.onload = () => {
      setIsLoaded(true);
    };
    setTimeout(() => {
      dispatch(isloading(true));
    }, 5000);
  }, [dispatch]);
  const backgroundStyle1 = isLoaded
    ? {
        backgroundImage: `url(${elipse})`,
        backgroundSize: "contain",
        backgroundPosition: "right top",
        backgroundRepeat: "no-repeat",
      }
    : {};
  const backgroundStyle2 = isLoaded
    ? {
        backgroundImage: `url(${elipse2})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "70%",
        backgroundPosition: "100% 70%",
      }
    : {};

  return (
    <>
      <div className={`absolute z-50 h-full w-full ${isloaded && "hidden"}`}>
        <Loading />
      </div>
      <Layout
        transparentHeaderOnPhone
        className="homepage-layout"
        headerMobileMenuIconClassName="!fill-black">
        {/* <SmoothScroll /> */}
        <main className="page home-page mobile">
          {/* first section */}
          <section>
            <TopSection />
          </section>

          {/* about us section */}
          <section>
            <section>
              <AboutUs />
            </section>
          </section>

          {/* services section */}
          <section>
            <Services />
          </section>

          {/* our products */}
          <section>
            <section>
              <Products />
            </section>
          </section>

          {/* incubation center */}

          <section>
            <IncubationCenters />
          </section>

          {/* affiliated universities */}

          <section>
            <AffilatedUniversities />
          </section>

          {/* universities virtual tour */}
          <section>
            {/*   <div style={{background:'url('+elipse+')',  backgroundSize:'contain', backgroundPosition:'right top', backgroundRepeat:'no-repeat'}}> */}
            <div style={backgroundStyle1}>
              <div className="lg:px-15 sm:px-6 md:px-10">
                <UniVirtualTour />
              </div>
            </div>
          </section>
          <div className="lg:px-15 sm:px-6 md:px-10">
            {/* our community section */}
            <section>
              <Community />
            </section>
          </div>
          {/*  <div style={{ background:'url('+elipse2+')', backgroundRepeat:'no-repeat',backgroundSize:'70%', backgroundPosition:'100% 70%'}}> */}
          <div style={backgroundStyle2}>
            <div className="lg:px-15 sm:px-6 md:px-10">
              <section>
                <Blog />
              </section>
              {/* our team section */}
              <section>
                <Team />
              </section>
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
};

export default Home;
