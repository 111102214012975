import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CommunitiesHero from '../../components/Communities/Hero';
import Layout from '../../components/Layout';
import SmoothScroll from '../../components/App/SmoothScroll';
import PeopleGlobe from '../../assets/Images/people-globe.png';
import FreeWalkingStudents from '../../assets/Images/free-time-students-walking.png';
import TouristSitting from '../../assets/Images/tourist-sitting.png';
import HrHandshake from '../../assets/Images/hr-handshaking-successful.png';
import ArabWomanHandshake from '../../assets/Images/arab-woman-student.png';
import StudentWearingMedicalMask from '../../assets/Images/student-wearing-medical-masks.png';
import AfricanAmericanStudent from '../../assets/Images/african-american-student-walking.png';
import CameraIconGroup from '../../assets/Images/camera-icon-group.svg';
import ChatIconGroup from '../../assets/Images/chat-icon-group.svg';
import PlayIconGroup from '../../assets/Images/play-icon-group.svg';
import TurkeyBridge from '../../assets/Images/turkey-bridge.jpg';
import setPageDescription from "../../lib/utils/setPageDescription";
import usePageMetadata from '../../lib/usePageMetadata';

const Communities = () => {
  const { t } = useTranslation();

  usePageMetadata('Student Experience | Paramount Students',);
  useEffect(() => {    
    setPageDescription('Join our hub! Student Experience will allow you to connect and meet international students where you can embrace new cultures and friends abroad.')
  }, []);

  return (
    <Layout transparentHeaderOnPhone>
      <SmoothScroll />
      <main className="page communities-page pb-8">
        <CommunitiesHero />

        <div>
          <div className="md:bg-gradient-to-r from-[#ffc4004d] from-10% via-[#ffffff] via-30% to-[#ffffff] to-90%">
            <div className="py-16 md:py-20 px-4 max-w-screen-lg mx-auto">
              <div className="flex flex-col-reverse md:flex-row gap-12 justify-center">
                <div className="flex-auto text-center">
                  <img
                    src={PeopleGlobe}
                    alt="new experience"
                    className="max-w-[18rem] rounded-tl-[8rem] rounded-br-[8rem]"
                  />
                </div>
                <div className="flex-auto">
                  <h2 className="heading text-2xl md:text-4xl">
                    {t('A whole new experience')}
                  </h2>
                  <div className="max-w-prose">
                    <p className="mb-3 leading-5">
                      {t(
                        'Ignite Your Study Abroad Adventure with Student Experience: Discover a world of vibrant connections, thrilling events, and unforgettable memories. Student Experience is your gateway to a diverse community of international students, where you can immerse yourself in exciting activities, embrace new cultures, and forge lifelong friendships',
                      )}
                    </p>
                    <p className="leading-5">
                      {t(
                        'Step beyond the classroom and make the most of your study abroad journey by joining Student Experience—a hub that brings together students like you, united in their pursuit of both education and life-changing experiences',
                      )}
                    </p>
                  </div>
                  <div className="mt-10 md:mt-20">
                    <a
                      className="py-4 px-12 bg-[#ffc400] text-black rounded-3xl shadow"
                      href="/communities"
                    >
                      {t('Join Us')}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-[#111]">
            <div className="py-16 md:py-20 px-4 md:px-8">
              <div className="mb-16 md:mb-20">
                <h2 className="heading text-2xl md:text-4xl text-[#FFC400] text-center font-medium">
                  {t('What we offer')}
                </h2>
                <p className="text-center leading-6 text-white text-sm md:text-xl max-w-prose mx-auto">
                  {t(
                    'Get ready to live the best experiences of your life, these are the precious things that life has to offer',
                  )}
                </p>
              </div>
              <div className="flex flex-col md:flex-row gap-5 justify-center items-center md:items-start">
                <div>
                  <div className="relative">
                    <img
                      src={FreeWalkingStudents}
                      alt="free time students walking"
                      className="h-52 rounded-sm"
                    />
                    <p className="m-0 absolute text-white overlay-text font-bold text-lg uppercase">
                      {t('education')}
                    </p>
                  </div>
                </div>
                <div className="md:mt-12">
                  <div className="relative">
                    <img
                      src={TouristSitting}
                      alt="tourist sitting"
                      className="h-52 rounded-sm"
                    />
                    <p className="m-0 absolute text-white overlay-text font-bold text-lg uppercase">
                      {t('memories')}
                    </p>
                  </div>
                </div>
                <div className="md:mt-24">
                  <div className="relative">
                    <img
                      src={HrHandshake}
                      alt="hr successful handshakes"
                      className="h-52 rounded-sm"
                    />
                    <p className="m-0 absolute text-white overlay-text font-bold text-lg uppercase">
                      {t('opportunities')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="py-16 md:py-20 px-4 md:px-8">
              <div className="mb-10 md:mb-14">
                <h2 className="heading text-2xl md:text-4xl text-[#272927] text-center font-medium capitalize">
                  {t('our community')}
                </h2>
              </div>
              <div className="flex flex-col md:flex-row gap-16 justify-center items-center md:items-start">
                <div className="max-w-[18rem] flex flex-col gap-6">
                  <div className="bg-[#FBF2D0] rounded-tl-[5rem] rounded-br-[5rem] drop-shadow-lg">
                    <div>
                      <img
                        src={ArabWomanHandshake}
                        alt="Arab woman handshake"
                        className="max-w-full rounded-tl-[5rem]"
                      />
                    </div>
                    <div className="p-4 pb-8">
                      <p className="mb-2">
                        {t(
                          'With Student Experience, I have felt like I can finally connect with others and have a great time whilst doing it. I feel like I have a home away from home',
                        )}
                      </p>
                      <em className="font-medium text-lg">
                        Raheela Karim - Algeria
                      </em>
                    </div>
                  </div>
                  <div className="text-center">
                    <img
                      src={CameraIconGroup}
                      alt="camera group"
                      className="max-w-[8rem]"
                    />
                  </div>
                </div>
                <div className="max-w-[18rem] flex flex-col-reverse md:flex-col gap-6">
                  <div className="text-center">
                    <img
                      src={ChatIconGroup}
                      alt="camera group"
                      className="max-w-[9rem]"
                    />
                  </div>
                  <div className="bg-[#FBF2D0] rounded-tl-[5rem] rounded-br-[5rem] drop-shadow-lg">
                    <div>
                      <img
                        src={StudentWearingMedicalMask}
                        alt="student wearing medical mask"
                        className="max-w-full rounded-tl-[5rem]"
                      />
                    </div>
                    <div className="p-4 pb-8">
                      <p className="mb-2">
                        {t(
                          "If it wasn't for Student Experience, I surely believe my time as an International Student would never have been the same. I am grateful to have found them",
                        )}
                      </p>
                      <em className="font-medium text-lg">
                        Rukkayah Tukur- Ghana
                      </em>
                    </div>
                  </div>
                </div>
                <div className="max-w-[18rem]">
                  <div className="bg-[#FBF2D0] rounded-tl-[5rem] rounded-br-[5rem] drop-shadow-lg">
                    <div>
                      <img
                        src={AfricanAmericanStudent}
                        alt="African american student"
                        className="max-w-full rounded-tl-[5rem]"
                      />
                    </div>
                    <div className="p-4 pb-8">
                      <p className="mb-2">
                        {t(
                          'I have had the best experiences of my life with Student Experience. I have managed to settle very well as I always have the right support and network around me',
                        )}
                      </p>
                      <em className="font-medium text-lg">
                        Sam Freeman - Canada
                      </em>
                    </div>
                  </div>
                  <div className="text-center mt-6">
                    <img
                      src={PlayIconGroup}
                      alt="camera group"
                      className="max-w-[9rem]"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="py-16 md:py-20 px-4 md:px-8">
              <div className="mb-10 md:mb-14">
                <h2 className="heading text-2xl md:text-4xl text-[#272927] text-center font-medium capitalize">
                  {t('now available in Turkey')}
                </h2>
              </div>
              <div className="text-center max-w-screen-lg mx-auto">
                <img
                  src={TurkeyBridge}
                  alt="turkey bridge"
                  className="max-w-full"
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default Communities;
