const Landing = () => {
  return (
    <div className="flex flex-col mt-20 md:mt-32 w-full h-full relative">
      <img
        src="/mobileapp/left-image.png"
        alt=""
        className="absolute w-[20vw] top-16 transition-all duration-500 ease-in-out"
      />

      <div className="flex flex-col justify-center items-center my-5 text-center sm:space-y-2 relative">
        <p className="[font-size:_clamp(1.5em,5vw,8em)] font-bold text-gray-900 font-recoleta transition-all duration-500 ease-in-out">
          Unlock Your Future with
        </p>
        <p className="[font-size:_clamp(1.5em,5vw,8em)] font-bold text-secondTitle font-recoleta transition-all duration-500 ease-in-out">
          Paramount Students
        </p>
        <div className=" ">
          <p className="[font-size:_clamp(7px,1.2vw,1.3vw)] text-gray-900 font-graphik md:pt-5 transition-all duration-500 ease-in-out">
            The Simplest and Coolest Way To Find Your Dream University
          </p>
        </div>
        <img
          src="/mobileapp/zig-zag.png"
          alt="zig-zag"
          className="absolute top-[7vw] sm:top-[5vw] xl:top-[5vw] left-[53vw] w-20 md:w-32 xl:w-56 max-w-40"
        />
      </div>
      <div className="flex flex-col justify-center items-center md:my-5 relative ">
        <div className=" w-[38vw] sm:w-[30vw] mt-2 flex flex-row 0">
          <a
            href="https://play.google.com/store/apps/details?id=com.paramountstudents&hl=en"
            target="_blank"
            rel="noreferrer">
            <img
              src="/mobileapp/play-store.png"
              alt="playstore"
              className="w-[19vw] sm:w-[15vw] h-7 sm:h-10 md:h-14  lg:h-[65px] xl:h-[80px] transition-transform transform hover:scale-105 hover:shadow-lg duration-500 ease-in-out cursor-pointer"
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.paramountstudents&hl=en"
            target="_blank"
            rel="noreferrer">
            <img
              src="/mobileapp/app-store.png"
              alt="appstore"
              className="w-[19vw] sm:w-[15vw] h-7 sm:h-10 md:h-14  lg:h-[65px] xl:h-[80px] transition-transform transform hover:scale-105 hover:shadow-lg duration-500 ease-in-out cursor-pointer"
            />
          </a>
        </div>
        <img
          src="/mobileapp/left-arrow.png"
          alt="left-arrow"
          className="absolute -top-[6vw] left-[20vw] w-[10vw] transition-all duration-500 ease-in-out"
        />
        <img
          src="/mobileapp/right-arrow.png"
          alt="right-arrow"
          className="absolute -top-[6vw] right-[20vw] w-[10vw] transition-all duration-500 ease-in-out"
        />
      </div>

      <img
        src="/mobileapp/right-image.png"
        alt="leftimage"
        className="absolute w-[20vw] right-0 top-16 transition-all duration-500 ease-in-out"
      />
    </div>
  );
};

export default Landing;
