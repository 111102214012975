import React, { useEffect } from "react";
import SchoolInfoForm from "../../components/Modals/HighRequest";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/Layout";

const UniversityDemoPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "University Demo Request";
  }, []);

  return (
    <Layout>
      <main className="page about-page">            
        <SchoolInfoForm type="university" onclose={(()=>{navigate(-1)})} />
      </main>
    </Layout>
  );
};

export default UniversityDemoPage;
