import { ReactComponent as IconCalendar } from '../../../assets/Icon/icon-calendar.svg';

const cardStyleClasses = {
  sm: {
    title: 'text-base leading-6',
    description: 'text-sm leading-5',
  },
  lg: {
    title: 'text-lg sm:text-xl leading-7',
    description: 'text-base leading-6',
  },
};

const hoverStyleClasses = {
  all: {
    container:
      'hover:bg-[#428BC1] hover:drop-shadow-xl hover:text-white transition-colors',
  },
  none: {
    container: '',
  },
};

const PostCard = ({
  title,
  description,
  dateString,
  bannerImage,
  hoverStyle = 'all',
  cardSize = 'sm',
  containerClassName = '',
  bodyContainerClassName = '',
  bannerImageClassName = '',
}) => {
  return (
    <div
      className={`rounded-lg bg-white border border-solid border-[#D7D7D7] ${containerClassName} ${hoverStyleClasses[hoverStyle].container}`}
    >
      <div style={{height:'200px',backgroundImage:`url(${bannerImage})`,backgroundSize:'cover',backgroundRepeat:'no-repeat', backgroundPosition:'10% center', width:'100%'}} className={`rounded-t-lg w-full ${bannerImageClassName}`} >
        
      </div>
      <div className={`p-6 ${bodyContainerClassName}`}>
        <h3 className={`font-medium mb-3 ${cardStyleClasses[cardSize].title}`}>
          {title}
        </h3>
        {description ? (
          <p className={`${cardStyleClasses[cardSize].description}`}>
            {description}
          </p>
        ) : null}
        <div className="flex mt-4 items-center">
          <IconCalendar className="w-4 mr-3" />
          <span className="text-xs">{dateString}</span>
        </div>
      </div>
    </div>
  );
};

export default PostCard;
