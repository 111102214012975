import React, { useEffect } from "react";
import SchoolInfoForm from "../../components/Modals/HighRequest";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/Layout";

const HighSchoolDemoPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "High School Demo Request";
  }, []);

  return (
    <Layout>    
      <main className="page about-page">            
        <SchoolInfoForm onclose={(()=>{navigate(-1)})} />
      </main>
      </Layout>
  );
};

export default HighSchoolDemoPage;
