import { useEffect } from "react";
import Layout from "../../components/Layout";
import ServicesHero from "../../components/Services/ServicesHero";
import ServiceSection from "../../components/Services/ServiceSection";

import Section2BannerImage from "../../assets/Images/services-section-2.webp";
import Section3BannerImage from "../../assets/Images/services-section-3-banner.webp";
import Section4BannerImage from "../../assets/Images/services-section-4-banner.webp";
import Section4InnerElipse from "../../assets/Images/services-section-4-inner-elipse.webp";
import Section4OuterElipse from "../../assets/Images/services-section-4-outer-elipse.webp";
import Section5BannerImage from "../../assets/Images/services-section-5-banner.webp";
import Section6BannerImage from "../../assets/Images/services-section-6-banner.webp";
import Section6InnerRect from "../../assets/Images/services-section-6-inner-rect.webp";
import Section6OuterRect from "../../assets/Images/services-section-6-outer-rect.webp";
import { ReactComponent as ArrowUpRight } from "../../assets/Icon/arrow-up-right-icon.svg";
import SmoothScroll from "../../components/App/SmoothScroll";
import { useTranslation } from 'react-i18next';
import setPageDescription from "../../lib/utils/setPageDescription";

const Services = () => {
  const {t}=useTranslation()
 
  useEffect(() => {
    document.title = "Our Services | Paramount Students";

    setPageDescription('Explore new opportunities by working with Paramount Students. Discover the best universities around the world. Simplify the student recruitment process for your institution.')
  }, []);

  return (
    <Layout transparentHeaderOnPhone>
      <main className="page services-page">
        <ServicesHero />
        
        <SmoothScroll />
        {/* Section 1 - Hero Section */}

        {/* Section 2 */}
        <ServiceSection
          className="bg-background2"
          sectionHeading="Establishing Connection-oriented Communities"
          sectionText="A welcoming multicultural community, culturally interesting activities, many local events, and interest-based groups combine to provide a once-in-a-lifetime experience. By collaborating with suitable communities and incubation centers throughout the world, we help students build cross-cultural relationships"
          sectionButtonText={t("Communities")}
          sectionButtonIcon={<ArrowUpRight />}
          sectionButtonLink="/communities"
          sectionBannerImage={Section2BannerImage}
          sectionBannerImageAlt="a circle of international students standing together"
        />

        {/* Section 3 */}
        <ServiceSection
          className="bg-background3"
          sectionHeading="We connect students with the top universities"
          sectionText="Students are promptly matched with their preferred universities. Students can simply adapt their academic courses to meet the requirements of a partner university"
          sectionButtonText={t("Our Products")}
          sectionButtonIcon={<ArrowUpRight />}
          sectionButtonLink="/our-products"
          sectionBannerImage={Section3BannerImage}
          sectionBannerImageAlt="A helpful women standing in front of a desk with a laptop"
          alternate
        />

        {/* Section 4 */}
        <ServiceSection
          className="bg-background4"
          sectionHeading="Designed Products for a Centralized Educational System"
          sectionText="Paramount Students is a higher Ed-tech marketplace and student-centered network. We are the new go-to site for education because we build bridges that open up new opportunities for local and international audiences. By connecting students with academic resources and financial options, we enable all parties to engage in the worldwide educational process"
          sectionBannerImage={Section4BannerImage}
          sectionBannerImageAlt="An open plan office with tables and chairs"
          backgroundImages={[
            {
              image: Section4InnerElipse,
              classNames:
                "w-full md:w-2/5 rotate-180 md:rotate-0 md:absolute md:right-20 lg:right-28 md:top-12",
            },
            {
              image: Section4OuterElipse,
              classNames: "hidden lg:block lg:absolute lg:left-[40%]",
            },
          ]}
          variant="dark"
        />

        {/* Section 5 */}
        <ServiceSection
          className="bg-background3"
          sectionHeading="Adequate Accommodation at the Tip of Your Finger"
          sectionText="We understand how difficult it may be to settle into a new community, but don't worry, we offer you the option to choose an accommodation by browsing through the various options we've selected specifically for you"
          sectionBannerImage={Section5BannerImage}
          sectionBannerImageAlt="Student sitting on coach holding her phone"
          alternate
        />

        {/* Section 6 */}
        <ServiceSection
          className="bg-background4 overflow-hidden"
          sectionHeading="Your prospective university at a glance!"
          sectionText={`We provide students a "virtual reality" tour of the school, allowing you to have a 360<span>&#176</span> view of the school’s site. It allows students to see how the location actually appears`}
          sectionBannerImage={Section6BannerImage}
          sectionBannerImageAlt="virtual campus tour for university"
          backgroundImages={[
            {
              image: Section6InnerRect,
              classNames:
                "w-full md:w-2/5 rotate-180 md:rotate-0 md:absolute md:top-20 md:right-4 lg:right-8 ",
            },
            {
              image: Section6OuterRect,
              classNames:
                "hidden lg:block lg:absolute -top-20 lg:-right-16 xl:right-0",
            },
          ]}
          variant="dark"
        />
      </main>
      {/* <SmoothScroll /> */}
    </Layout>
  );
};

export default Services;
