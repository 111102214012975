import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import UniVirtTourImage from '../../../assets/Images/univirtualtour.png';
// import Image1 from "../../../assets/Images/VirtualTour/TaksimSquare.jpg";
import { ImPlay2 } from 'react-icons/im';
import './UniVirtualTour.css';
// import { ReactPhotoSphereViewer } from "react-photo-sphere-viewer";
import { Form, Input, Button, message } from 'antd';

import CountryCodes from '../../../lib/utils/CountryCodes.json';
import FormItem from 'antd/es/form/FormItem';
import { Link } from 'react-router-dom';

const UniVirtualTour = () => {
  const [form] = Form.useForm();
  const formRef = useRef(null);

  const onFinish = (values) => {
    const templateParams = {
      to_email: 'support@paramountstudents.com',
      from_name: values.name,
      from_email: values.email,
      message: values.message,
      university: values.university,
      country_code: values.country_code,
      phone_number: values.phone_number,
    };

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_EMAILJS_USER_ID,
      )
      .then(
        (response) => {
          console.log('SUCCESS!', response.status, response.text);
          message.success('Email sent successfully!');
          form.resetFields();
        },
        (error) => {
          console.log('FAILED...', error);
          message.error('Failed to send email.');
        },
      );
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    message.error('Failed to send email. Please fill in all required fields.');
  };

  // console.log(CountryCodes);
  return (
    <div className="flex md:flex-row flex-col-reverse md:h-[54.5rem] h-[1970px] bg-[#001223]">
      <div className="flex-1  text-white px-16 pt-12">
        <div className="title md:flex items-center container hidden  ">
          <span className="uppercase w-2/3 left-0 ">
            Universities virtual Tour
          </span>
          <div className="h-[1px] bg-white text-white w-full mr-20"></div>
        </div>
        <h3 className="text-[1.75rem] mt-6 mb-2">
          Do you want to virtually tour campuses?
        </h3>
        <h4 className="text-base font-normal leading-4 mr-16 ">
          Lörem ipsum niktig telesk änina reade. Vora dost, nining monosav.
          Tivis sylasar ot.{' '}
        </h4>
        <div className="text-white">
          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            ref={formRef}
          >
            <div className="flex md:flex-row flex-col gap-x-5">
              <div className="flex flex-col mt-6 gap-y-1">
                <label htmlFor="university" className="text-white text-sm">
                  University
                </label>
                <Form.Item
                  className="b-1 border-[#D0D5DD] md:w-60 w-full h-6"
                  placeholder="Name of the university"
                  name="university"
                  rules={[
                    { required: true, message: 'Please enter your university' },
                  ]}
                >
                  <Input
                    className="b-1 border-[#D0D5DD] md:w-60 w-full h-12"
                    placeholder="Enter your university"
                  />
                </Form.Item>
              </div>
              <div className="flex flex-col mt-6 gap-y-1">
                <label htmlFor="fullname" className="text-white text-sm">
                  Full Name
                </label>
                <Form.Item
                  className="b-1 border-[#D0D5DD] md:w-60 w-full h-6"
                  placeholder="Your full name"
                  name="fullname"
                  rules={[
                    { required: true, message: 'Please enter your full name' },
                  ]}
                >
                  <Input
                    className="b-1 border-[#D0D5DD] md:w-60 w-full h-12"
                    placeholder="Enter your full name"
                  />
                </Form.Item>
              </div>
            </div>

            <div className="flex flex-col mt-6 gap-y-1">
              <label htmlFor="phonenumber" className="text-white text-sm">
                Phone Number
              </label>
              <div className="flex  gap-x-1 b-1 border-[#D0D5DD]">
                <Form.Item className="">
                  <select
                    className="b-1 border-[#D0D5DD] w-16 h-12 rounded-md relative p-1"
                    name="country_code"
                    id=""
                    rules={[
                      {
                        required: true,
                        message: 'Please select your country code',
                      },
                    ]}
                  >
                    {CountryCodes.map((code) => (
                      <option value={code.number} className=" p-4 mb-4">
                        {code.number}
                      </option>
                    ))}
                  </select>
                </Form.Item>
                {/* <FormItem> */}
                <FormItem
                  className="b-1 border-[#D0D5DD] md:w-60 w-full h-12"
                  placeholder="(5__) ___ __ __"
                  name="phone_number"
                  type="select"
                >
                  <Input
                    className="b-1 border-[#D0D5DD] md:w-60 w-full h-12"
                    placeholder="Enter your phone number"
                  />
                </FormItem>
              </div>
            </div>
            <div className="flex flex-col mt-6 gap-y-1">
              <label htmlFor="email" className="text-white text-sm">
                E-mail
              </label>
              <Form.Item
                className="b-1 border-[#D0D5DD] md:w-[19.2rem] w-full h-12"
                placeholder="abc@yourdomain.com"
                name="email"
                type="email"
                rules={[
                  {
                    type: 'email',
                    required: true,
                    message: 'Please enter a valid email address',
                  },
                ]}
              >
                <Input
                  className="b-1 border-[#D0D5DD] md:w-60 w-full h-12"
                  placeholder="Enter your email address"
                />
              </Form.Item>
            </div>
            <div className="flex flex-col mt-6 gap-y-1">
              <label htmlFor="message" className="text-white text-sm">
                Message
              </label>
              <Form.Item
                className="b-1 border-[#D0D5DD] md:w-[19.2rem] w-full h-12"
                placeholder="Type here about the university virtual tour..."
                name="message"
                type="textarea"
                showCount
                style={{
                  height: 136,
                  resize: 'none',
                }}
                rules={[{ required: true, message: 'Please enter a message' }]}
              >
                <Input.TextArea placeholder="Enter your message" rows={4} />
              </Form.Item>
            </div>
            <div className="flex justify-center md:justify-start mt-12">
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="bg-[#F89722] mt-2 rounded-lg p-4 h-12 flex items-center text-center"
                >
                  Request For Virtual Tour
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
      <div className={` flex-1 w-full relative mb-32 md:mb-0`}>
        <div className=" flex items-center container my-14 pl-5 md:hidden ">
          <span className="uppercase w-2/3 left-0 text-white ">
            Universities virtual Tour
          </span>
          <div className="h-[1px] bg-white text-white w-1/3 mr-20"></div>
        </div>
        {/* <ReactPhotoSphereViewer
          navbar={true}
          caption="Deneme"
          description="deneme"
          src={Image1}
          height={"100%"}
          width={"100%"}
        ></ReactPhotoSphereViewer> */}
        <img
          src={UniVirtTourImage}
          alt="a large university building with green grass in front of it."
          className="w-full h-full object-cover"
        />
        <Link to="/vtour">
          {' '}
          <ImPlay2 className="absolute z-10 top-[50%] left-[50%] text-white w-24 h-24 cursor-pointer" />
        </Link>
      </div>
    </div>
  );
};

export default UniVirtualTour;
