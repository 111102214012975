import VitalityLogo from "../../assets/Icon/vitality-logo.webp";
import WomenUnionLogo from "../../assets/Icon/women-unity.webp";
import FastTalkLogo from "../../assets/Icon/fasttalk-logo.webp";
import GlobalTribeLogo from "../../assets/Icon/global-tribe-logo.webp";


import VitalityBanner from "../../assets/Images/vitality-community-section-banner.webp";
import WomenUnionBanner from "../../assets/Images/women-community-section-banner.webp";
import FastTalkBanner from "../../assets/Images/fast-talk-community-section-banner.svg";
import GlobalTribeBanner from "../../assets/Images/global-tribe-community-section-banner.webp";
// import VitalityLogo from "../../assets/Images/vitalitylogo.webp";

export const communities = [
  {
    communityName: "Papyrus",
    communityHead: "Head",
    communityLogo: VitalityLogo,
    logoClassName: "w-32",
    communityDesc: `Papyrus is the official society for bookworms. It is a community that brings together bookworms in a fun, friendly environment. Through our exciting community, you will be able to connect with fellow students and passionate book-lovers. At the heart of it all is the book community itself`,
    communityBanner: VitalityBanner,
    bannerAltText: "a women meditating in a yoga position",
    socialLinks: [
      {
        platform: "LinkedIn",
        link: "https://www.linkedin.com/showcase/papyrus-psc/",
      },
    ],
  },

  {
    communityName: "Women Unity",
    communityHead: "Sohail Panjwani",
    communityLogo: WomenUnionLogo,
    communityDesc: `Ever heard of the phrase ‘Women supporting Women? The Women’s Unity Project Community is a great way for women to support one another. Female students can join through existing campus organizations or start on their own. Some focus on networking, others on philanthropy, and some others on empowerment. This organization creates an environment where women are heard and recognized for their achievements`,
    communityBanner: WomenUnionBanner,
    bannerAltText: "A crowd of women with smiling faces",
    socialLinks: [
      {
        platform: "LinkedIn",
        link: "https://www.linkedin.com/showcase/women-unity-project",
      },
      {
        platform: "Instagram",
        link: "https://www.instagram.com/womenunityproject.psc/",
      },
      {
        platform: "Facebook",
        link: "https://m.facebook.com/womenunityproject.psc/",
      },
    ],
    alternate: true,
  },
  {
    communityName: "Fastalk",
    communityHead: "Anisa Sayed",
    communityLogo: FastTalkLogo,
    communityDesc: `Fastalk: Fastalk is an awesome community where "every student presents". We believe that public speaking builds confidence in individuals and that events like Fastalk build community. Fastalk is also about having fun, so don't forget to have fun with other students at the event`,
    communityBanner: FastTalkBanner,
    bannerAltText: "student presenting to class of students",
    socialLinks: [
      {
        platform: "LinkedIn",
        link: "https://www.linkedin.com/showcase/fastalk",
      },
      { platform: "Instagram", link: "https://www.instagram.com/fastalk.psc/" },
      {
        platform: "Facebook",
        link: "https://m.facebook.com/Fastalk.Paramountstudent/",
      },
    ],
  },

  {
    communityName: "Global Tribe",
    communityHead: "Widad Benkiran",
    communityLogo: GlobalTribeLogo,
    communityDesc: `The Global Tribe is a varied community of students from all over the world centered in Istanbul, Turkey.  Our roots are made up of several sub-communities spread among Istanbuls top colleges. These sub-communities merge to form one large family`,
    communityBanner: GlobalTribeBanner,
    bannerAltText: "",
    socialLinks: [
      {
        platform: "LinkedIn",
        link: "https://www.linkedin.com/showcase/globaltribe",
      },
      {
        platform: "Instagram",
        link: "https://www.instagram.com/globaltribe.psc/",
      },
      {
        platform: "Facebook",
        link: "https://m.facebook.com/globaltribe.psc/",
      },
    ],
    alternate: true,
  },
];
