import { Link } from "react-router-dom";
import DOMPurify from "dompurify";
import Button from "../../Generic/Button";

import Container from "../../Layout/Container";
import FadeInLeftRight from "../../Animations/FadeInLeftRight";
import FadeWhileInView from "../../Animations/FadeWhileInView";
import useWindowSize from "../../../hooks/useWindowSize";
import { motion } from "framer-motion";
import { useTranslation } from 'react-i18next';

const ServiceSection = ({
  className,
  sectionHeading,
  sectionText,
  sectionButtonText,
  sectionButtonIcon,
  sectionButtonLink,
  sectionBannerImage,
  sectionBannerImageAlt,
  alternate,
  backgroundImages,
  variant,
}) => {
  const dark = variant === "dark";

  const screenSize = useWindowSize();

  const isMobile = screenSize.width < 900;

  const colors = {
    heading: dark ? "text-white" : "text-text-primary",
    text: dark ? "text-white" : "text-text-primary",
  };
  const { t } = useTranslation();

  return (
    <section
      className={`section services-section w-full relative flex justify-center ${className}`}
    >
      {backgroundImages && backgroundImages.length > 0 ? (
        <div className="services-section-right-bg-images w-full absolute bottom-0 md:top-0">
          <FadeWhileInView delay={isMobile ? 0.25 : 1} duration={0.75}>
            <div className="relative w-full">
              {backgroundImages.map((image, i) => (
                <img
                  key={i}
                  src={image.image}
                  className={image.classNames}
                  alt="services section 4 elipses"
                />
              ))}
            </div>
          </FadeWhileInView>
        </div>
      ) : null}
      <Container maxWidth="xl">
        <div
          className={`services-section-container my-16 md:my-28 flex flex-col-reverse ${
            alternate ? "md:flex-row-reverse" : "md:flex-row md:space-x-12"
          } md:items-start`}
        >
          <div className="services-section-left mt-6 md:mt-0 flex flex-col flex-1 md:flex-[0.45] items-center">
            <FadeInLeftRight
              offset={isMobile ? 0 : alternate ? 75 : -75}
              className="rounded-3xl overflow-hidden"
            >
              <img
                src={sectionBannerImage}
                className="w-full z-[8]"
                alt={sectionBannerImageAlt}
              />
            </FadeInLeftRight>
          </div>
          <div
            className={`services-section-right flex-1 md:flex-[0.55] md:py-2 z-[4] ${
              alternate && "mr-12"
            }`}
          >
            <FadeWhileInView delay={isMobile ? 0.25 : 0.5} duration={0.75}>
              <h2
                className={`font-medium text-lg md:text-4xl capitalize ${colors["heading"]}`}
              >
                {t(sectionHeading)}
              </h2>
            </FadeWhileInView>
            <FadeWhileInView delay={isMobile ? 0.25 : 0.75} duration={0.75}>
              <p
                className={`font-normal text-sm leading-5 md:leading-8 mt-5 md:mt-8 md:text-xl ${colors["text"]}`}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(t(sectionText)),
                }}
              />
            </FadeWhileInView>
            {sectionButtonText && (
              <FadeWhileInView delay={isMobile ? 0.25 : 1} duration={0.75}>
                <Link to={sectionButtonLink}>
                  <div className="mt-5 md:mt-8">
                    <Button
                      variant="outlined"
                      endIcon={sectionButtonIcon && sectionButtonIcon}
                      className="text-base md:text-lg font-normal bg-transparent border-solid border border-text-primary border-opacity-50 hover:bg-btn-background-1 hover:border-btn-background-1 transition-colors duration-150"
                    >
                      {sectionButtonText}
                    </Button>
                  </div>
                </Link>
              </FadeWhileInView>
            )}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default ServiceSection;
