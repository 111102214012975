import PropTypes from "prop-types";
import useWindowSize from "../../hooks/useWindowSize";
//import SmoothScroll from "../App/SmoothScroll";
import Footer from "../Generic/Footer";
import Header from "../Generic/Header";

const Layout = ({
  children,
  className,
  transparentHeaderOnPhone,
  headerMobileMenuIconClassName,
  disableFooter = false,
  disableFooterNewsletter = false,
}) => {
  const size = useWindowSize();

  return (
    <div className={`app-layout global-layout ${className}`}>
      <Header
        // enable transparent header on mobile page sends true and screen is less than 900px
        transparentOnPhone={transparentHeaderOnPhone && size?.width < 900}
        // providing direct class name to the menu icon
        // if you want to give it different style based on your page case
        mobileMenuIconClassName={headerMobileMenuIconClassName}
      />
            
      {children}

      {!disableFooter && <Footer hideNewsletter={disableFooterNewsletter} />}

      {/* <SmoothScroll /> */}
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,

  hideFooter: PropTypes.bool,
};

export default Layout;
