import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { Form, Input, Button, message, Upload } from "antd";
import { MdAttachFile } from "react-icons/md";




const ContactForm = ({ className }) => {
  const [form] = Form.useForm();

  const formRef = useRef(null);

  const onFinish = (values) => {
    const templateParams = {
      to_email: "support@paramountstudents.com",
      subject: values.subject,
      message: values.message,
      attachment: values.attachment ? values.attachment[0].originFileObj : null,
    };

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          message.success("Email sent successfully!");
          form.resetFields();
        },
        (error) => {
          console.log("FAILED...", error);
          message.error("Failed to send email.");
        }
      );
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    message.error("Failed to send email. Please fill in all required fields.");
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      ref={formRef}
      
      layout="vertical"
    >
      <Form.Item
        name="subject"
        label="Subject"
        rules={[{ required: true, message: "Please enter a subject" }]}
        className="text-sm font-normal leading-5  "
        htmlFor="contact-form-subject"
      >
        <Input
          placeholder="Please write your subject of issue"
          id="contact-form-subject"
          className="border border-solid border-[#D0D5DD] placeholder:text-[#98A2B3] rounded-lg p-2.5 mt-1 outline-[#98A2B3] "
        />
      </Form.Item>
      <Form.Item
        name="message"
        label="How can we help?"
        className="text-sm font-normal leading-5"
        rules={[{ required: true, message: "Please enter a message" }]}
      >
        <Input.TextArea
          placeholder="Tell us a little about the issue"
          rows={7}
          id="contact-form-message"
          className="border border-solid font-normal text-base border-[#D0D5DD] placeholder:text-[#98A2B3] placeholder:text-sm placeholder:rounded-lg p-2.5 mt-1 outline-[#98A2B3] "
        />
      </Form.Item>
      <div className="flex justify-between">
      <Form.Item
        name="attachment"
        getValueFromEvent={normFile}
        lassName="flex flex-col md:flex-row items-center justify-start md:justify-between space-y-4 md:space-y-0 mt-[22px]"
      >
        <Upload>
          <Button
            icon={<MdAttachFile />}
            size="large"
            className="bg-[#EAECF0] text-[#667085] flex items-center justify-center space-x-2 border border-solid border-gray-300 rounded-lg text-base font-medium leading-6 w-40 py-2 cursor-pointer shadow-sm hover:opacity-90 transition-colors duration-150"
          >
            Attach File
          </Button>
        </Upload>
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="bg-btn-background-1 rounded-lg text-base font-medium leading-6 border-none outline-none w-40 py-2 cursor-pointer shadow-sm hover:opacity-90 transition-colors duration-150 h-10"
        >
          Submit
        </Button>
      </Form.Item>
      </div>
      
    </Form>
  );

};

export default ContactForm;
