import React from "react";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
//import { useTranslation } from 'react-i18next';

import { ReactComponent as HamburgerIcon } from "../../assets/Icon/hamburger.svg";
import { ReactComponent as LogoBlack } from "../../assets/Icon/paramount-students-logo.svg";
import { ReactComponent as LogoWhite } from "../../assets/Icon/paramount-students-white-logo.svg";

import { Popover } from "@headlessui/react";
import MobileMenu from "../../components/Generic/Header/MobileMenu";
import { useState } from "react";
import { useEffect } from "react";

import GoogleTranslate from "../../components/Translate";

const Header = ({ transparentOnPhone, mobileMenuIconClassName }) => {
  const location = useLocation();
  const [mobileMenuOpen, setMobileMenuOpen] = useState();

  const transparentHeader = transparentOnPhone;

  const handleMobileMenuOpen = () => {
    setMobileMenuOpen(true);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuOpen(false);
  };

  useEffect(() => {
    if (mobileMenuOpen) {
      document.body.style.height = "unset";
    } else {
      document.body.style.height = "100vh";
    }
  }, [mobileMenuOpen]);

  return (
    <Popover className="relative">
      <header
        id="global-header"
        className={`header w-full transition-colors h-20 duration-200 z-10 ${
          transparentHeader ? "absolute top-0" : "fixed top-0 left-0"
        }`}>
        <div className="header-container">
          <div className="header-main">
            <div className="header-main-content flex justify-between items-center min-h-[80px] px-4 md:px-6 lg:px-[68px]">
              <div className="header-main-left">
                <div className="header-logo">
                  <Link to="/">
                    {/* always on desktop */}
                    <LogoBlack
                      id="header-logo-black"
                      className={`header-logo-black ${
                        location.pathname !== "/" && "hidden"
                      } md:block w-[110px] h-10`}
                    />
                    <LogoWhite
                      id="header-logo-white"
                      className={`header-logo-white ${
                        location.pathname === "/" ? "hidden" : "block"
                      } md:hidden w-[110px] h-10`}
                    />
                  </Link>
                </div>
              </div>
              <div className="header-main-righ flex items-center">
                <GoogleTranslate customId="google_translate_element" />
              </div>
            </div>
          </div>
        </div>
      </header>
      <MobileMenu onClose={handleMobileMenuClose} />
    </Popover>
  );
};

Header.propTypes = {
  transparentOnPhone: PropTypes.bool,
  mobileMenuIconClassName: PropTypes.string,
};

export default Header;
