import React, { useState } from "react";
import "./style.css";
import FloatingLabelInput from "../../Generic/Input";
/* import bgImage from '../../../assets/Images/demo-request-page.png';
import close from '../../../assets/Icon/close.svg'; */
import cap from "../../../assets/Images/cap.svg";
import contactIcon from "../../../assets/Images/user.svg";
import Button from "../../Generic/Button";
import CustomModal from "../Modal";
import { postData } from "../../../api/axios";

const d = {
  schoolName: "",
  website: "",
  schoolCountry: "",
  schoolType: "",
  firstName: "",
  lastName: "",
  email: "",
  contactPhoneNumber: "",
};

const SchoolInfoForm = ({ onclose, type = "highschool" }) => {
  const [formData, setFormData] = useState(JSON.parse(JSON.stringify(d)));

  const handleChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      data: formData,
      type: type === "highschool" ? "high_school" : "university",
    };
    console.log(data);
    await postData("api/send-mail-request", data);
    openModal();
    setFormData(JSON.parse(JSON.stringify(d)));
  };

  const handleClose = (e) => {
    onclose();
  };
  return (
    <div className="bg-gray-100  w-[100%]">
      <div className="w-[100%] sm">
        <div className="w-[100%] w-[90%] sm:w-[80%] mx-auto pt-[95px]">
          <span>
            <span style={{ cursor: "pointer" }} onClick={handleClose}>
              Home
            </span>{" "}
            Request for a Demo for High Schools
          </span>
          <h1
            className="md:w-[360px] mx-[auto] text-center mt-8"
            style={{ fontWeight: 500 }}>
            Paramount Students{" "}
          </h1>
          <h1
            className="mx-[auto] text-center mb-[20px]"
            style={{ fontWeight: 500 }}>
            {type === "highschool" ? "High School" : "University"} Partnership
            Inquiry
          </h1>

          {/* <div
            className="cursor-pointer inline-block p-4"
            onClick={handleClose}
          >
            <img className="w-[30px]" src={close} alt="Close" />
          </div> */}
          <form onSubmit={handleSubmit}>
            <h2 className="mt-[55px] text-[20px]">
              <img src={cap} alt="cap" className="w-5 mr-1" />
              {type === "highschool"
                ? "High School Information"
                : "University Information"}
            </h2>
            <div className="grid mt-7 grid-cols-1 sm:grid-cols-2 grid-rows-2 gap-x-4 gap-y-6">
              <div className="">
                <FloatingLabelInput
                  type="text"
                  labelText={
                    type === "highschool"
                      ? "High School Name *"
                      : "University Name *"
                  }
                  value={formData.schoolName}
                  onValueChange={(value) => handleChange("schoolName", value)}
                />
              </div>

              {/* Website */}
              <div className="">
                <FloatingLabelInput
                  type="text"
                  labelText="Website"
                  value={formData.website}
                  onValueChange={(value) => handleChange("website", value)}
                  required={false}
                />
              </div>

              <div className="">
                <FloatingLabelInput
                  type="text"
                  labelText={
                    type === "highschool"
                      ? "High School Country *"
                      : "University Country *"
                  }
                  value={formData.schoolCountry}
                  onValueChange={(value) =>
                    handleChange("schoolCountry", value)
                  }
                />
              </div>
              <div className="">
                <labelText className="text-[16px] text-gray-500">
                  {type === "highschool"
                    ? "High School Type"
                    : "University Type"}
                </labelText>
                <div className="text-[16px] text-gray-500 mt-3">
                  <input
                    type="radio"
                    name="schoolType"
                    value={
                      type === "highschool"
                        ? "Private High School"
                        : "Private University"
                    }
                    onChange={(e) => handleChange("schoolType", e.target.value)}
                  />
                  <labelText className="text-[16px] text-gray-500">
                    {type === "highschool"
                      ? "Private High School"
                      : "Private University"}
                  </labelText>
                  <input
                    className="ml-5"
                    type="radio"
                    name="schoolType"
                    value={
                      type === "highschool"
                        ? "Public High School"
                        : "Public University"
                    }
                    onChange={(e) => handleChange("schoolType", e.target.value)}
                  />
                  <labelText className="text-[16px] text-gray-500">
                    {type === "highschool"
                      ? "Public High School"
                      : "Public University"}
                  </labelText>
                </div>
              </div>
            </div>
            <h2 className="text-[20px] mt-10">
              <img src={contactIcon} alt="user" className="w-5 mr-1" />
              Contact Details
            </h2>

            <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 grid-rows-2 gap-x-4 gap-y-6">
              <FloatingLabelInput
                type="text"
                labelText="First Name *"
                value={formData.firstName}
                onValueChange={(value) => handleChange("firstName", value)}
              />
              <FloatingLabelInput
                type="text"
                labelText="Last Name *"
                value={formData.lastName}
                onValueChange={(value) => handleChange("lastName", value)}
              />
              <FloatingLabelInput
                type="email"
                labelText="Email *"
                value={formData.email}
                onValueChange={(value) => handleChange("email", value)}
              />
              <FloatingLabelInput
                type="text"
                labelText="Contact Phone Number *"
                value={formData.contactPhoneNumber}
                onValueChange={(value) =>
                  handleChange("contactPhoneNumber", value)
                }
              />
            </div>

            <Button
              type="submit"
              className="bg-amber-400 w-[100%] md:py-3 border-0 md:mt-[25px]">
              Submit
            </Button>
          </form>
        </div>
      </div>
      <div className="w-[100%]">
        {isModalOpen && (
          <CustomModal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            icon="success"
            title="Request Sent"
            width="335px"
            height="264px"
            text="We'll get back to you within 24 hours. Thank you so much for getting in touch with us"
          />
        )}
      </div>
    </div>
  );
};

export default SchoolInfoForm;
