import React,  { useState, useEffect } from 'react'
import { postData } from '../../api/axios';
import { showModal } from '../../lib/modal';
const InputSection = () => {
   const [waitlist, setWaitlist] = useState({
      email: '',
      high_school_name: '',
      country: ''
    });

    // Function to update waitlist state
    const updateWaitlist = (key, value) => {
      setWaitlist(prevState => ({
        ...prevState,
        [key]: value
      }));
    };

    const joinWaitlist = async () => {
        try {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
         if (!emailRegex.test(waitlist.email)) {
               showModal({ text: 'Invalid Email' });
               return;
         }
          const res = await postData('https://crm-demo.paramountstudents.com/api/waitlist/join', waitlist);
          if (res) {
            showModal({ text: 'Please verify your email to complete' });
          }
        } catch (error) {
          console.error('Error joining waitlist:', error);
          // Optionally, show error to user
        }
    };

    const joinWaitlistGoogle = async () => {
      try {
        const res = await postData('https://crm-demo.paramountstudents.com/api/waitlist/join/google', );
        if (res) {
         window.open(res.auth_link)          
        }
      } catch (error) {
        console.error('Error joining waitlist:', error);
        // Optionally, show error to user
      }
  };
   return (
      <div className=' w-full flex flex-col items-center justify-center py-0 rounded-md'>
         <button type="button" onClick={joinWaitlistGoogle} className="fill-[black] w-full justify-center flex items-center text-black bg-white hover:bg-[#ABC] cursor-pointer focus:ring-4 focus:ring-[#BDC2D0]/70 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-4 dark:bg-[#BDC2D0] dark:hover:bg--[#ABC] focus:outline-none dark:focus:ring-[#BDC2D0]/70" style={{border:'1px solid #ccc'}}>
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 48 48">
               <path fill="#FFC107" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"></path><path fill="#FF3D00" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"></path><path fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"></path><path fill="#1976D2" d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"></path>
            </svg>
            <span className='ml-2'>
               Register with Google
            </span>
         </button>         
            <button type="button" className="fill-[black]  w-full justify-center flex items-center text-black bg-white  hover:bg-[#ABC]  cursor-pointer focus:ring-4 focus:ring-[#BDC2D0]/70 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-4 dark:bg-[#BDC2D0] dark:hover:bg--[#ABC] focus:outline-none dark:focus:ring-[#BDC2D0]/70" style={{border:'1px solid #ccc'}}>
               <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 24 24" style={{ fill: 'inherit' }}>
                  <path d="M 15.904297 1.078125 C 15.843359 1.06875 15.774219 1.0746094 15.699219 1.0996094 C 14.699219 1.2996094 13.600391 1.8996094 12.900391 2.5996094 C 12.300391 3.1996094 11.800781 4.1996094 11.800781 5.0996094 C 11.800781 5.2996094 11.999219 5.5 12.199219 5.5 C 13.299219 5.4 14.399609 4.7996094 15.099609 4.0996094 C 15.699609 3.2996094 16.199219 2.4 16.199219 1.5 C 16.199219 1.275 16.087109 1.10625 15.904297 1.078125 z M 16.199219 5.4003906 C 14.399219 5.4003906 13.600391 6.5 12.400391 6.5 C 11.100391 6.5 9.9003906 5.5 8.4003906 5.5 C 6.3003906 5.5 3.0996094 7.4996094 3.0996094 12.099609 C 2.9996094 16.299609 6.8 21 9 21 C 10.3 21 10.600391 20.199219 12.400391 20.199219 C 14.200391 20.199219 14.600391 21 15.900391 21 C 17.400391 21 18.500391 19.399609 19.400391 18.099609 C 19.800391 17.399609 20.100391 17.000391 20.400391 16.400391 C 20.600391 16.000391 20.4 15.600391 20 15.400391 C 17.4 14.100391 16.900781 9.9003906 19.800781 8.4003906 C 20.300781 8.1003906 20.4 7.4992188 20 7.1992188 C 18.9 6.1992187 17.299219 5.4003906 16.199219 5.4003906 z"></path>
               </svg>
               <span className='ml-2'>Register with an Apple ID</span>
            </button>         
         <div className='w-full flex justify-center my-5'>
            <h3 className="flex items-center w-full">
               <span className="flex-grow bg-gray-400 rounded h-[0.7px]"></span>
               <span className="mx-3 text-lg text-gray-400 font-light">Or</span>
               <span className="flex-grow bg-gray-400 rounded h-[0.7px]"></span>
            </h3>
         </div>

         <div className='mb-5 w-full'>
                <h4 className='mb-2 font-normal'>Email address</h4>
                <input
                    value={waitlist.email}
                    onChange={(e) => updateWaitlist('email', e.target.value)}
                    style={{ border: '1px solid #ccc' }}
                    type='text'
                    placeholder='please enter email address'
                    className='w-full p-3 rounded-[8px] focus:outline-0'
                />
            </div>
            <div className='mb-5 w-full'>
                <h4 className='mb-2 font-normal'>Highschool Name</h4>
                <input
                    value={waitlist.high_school_name}
                    onChange={(e) => updateWaitlist('high_school_name', e.target.value)}
                    style={{ border: '1px solid #ccc' }}
                    type='text'
                    placeholder='please enter high school name'
                    className='w-full p-3 rounded-[8px] focus:outline-0'
                />
            </div>
            <div className='mb-5 w-full'>
                <h4 className='mb-2 font-normal'>Country</h4>
                <input
                    value={waitlist.country}
                    onChange={(e) => updateWaitlist('country', e.target.value)}
                    style={{ border: '1px solid #ccc' }}
                    type='text'
                    placeholder='please select country'
                    className='w-full p-3 rounded-[8px] focus:outline-0'
                />
            </div>

            <button
                type="button"
                onClick={joinWaitlist}
                className="sm:w-full w-[15rem] border-0 cursor-pointer focus:outline-none text-black bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:focus:ring-yellow-900">
                Join Waitlist
            </button>
 
         <p className='w-full'>By clicking on "john waitlist" you agree to our terms and condition</p>
      </div>
   )
};

export default InputSection;