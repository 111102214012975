import React from "react";
import { motion } from "framer-motion";

const FadeWhileInView = ({ children, duration, delay, once }) => {
  return (
    <motion.div
      transition={{ duration: duration || 1, delay: delay || 0.25 }}
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: once || true }}
    >
      {children}
    </motion.div>
  );
};

export default FadeWhileInView;
