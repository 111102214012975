import React from "react";
import DOMPurify from "dompurify";
import { IoIosArrowRoundForward } from "react-icons/io";

const CarousalSlide = ({ title, description, detailsLink, bannerImage }) => {
  return (
    <div className="about-swiper-carousal-slide flex justify-center md:justify-end">
      <div className="about-carousal-slide-content flex flex-col md:flex-row md:items-center md:space-x-8 lg:space-x-16 xl:space-x-24 px-4 md:px-0 md:pl-4 lg:pl-32 xl:pl-36">
        <div className="about-carousal-slide-left flex-1 md:flex-[0.5]">
          <div className="about-carousal-slide-left-content">
            <h3 className="font-medium text-2xl md:text-3xl lg:text-4xl leading-8 md:leading-10 lg:leading-[45px] xl:leading-[50px]">
              {title}
            </h3>
            <div className="my-8 md:my-4 lg:my-6 xl:my-8 bg-[#BDBDBD] w-full min-h-[1px]" />
            <p
              className="mt-8 md:mt-4 lg:mt-8 text-sm md:text-base lg:text-lg leading-5 md:leading-7 lg:leading-8 xl:leading-9"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(description),
              }}
            />
            {detailsLink && (
              <button className="border-none outline-none capitalize bg-transparent flex items-center space-x-1 mt-5 md:mt-3 lg:mt-5 text-base text-[#5151FD] hover:opacity-80 transition-opacity duration-150 cursor-pointer">
                <span>learn more</span>
                <span>
                  <IoIosArrowRoundForward className="w-8 h-8" />
                </span>
              </button>
            )}
          </div>
        </div>
        <div className="about-carousal-slide-right mt-5 md:mt-0 flex-1 md:flex-[0.5]">
          <img src={bannerImage} alt={title} className="w-full" />
        </div>
      </div>
    </div>
  );
};

export default CarousalSlide;
