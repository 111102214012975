import { useMemo } from 'react';

export const usePagination = ({ totalCount, pageSize }) => {
   const paginationRange = useMemo(() => {
      const totalPageCount = Math.ceil(totalCount / pageSize);
      return totalPageCount;
   }, [totalCount, pageSize]);

   return paginationRange;
};
