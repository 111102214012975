export const teamList = [
  {
    name: "Khalid Boulhala",
    designation: "CEO",
    department: "",
    
    hobby:["Idea Machine", "Lasagna Lord", "Salsa Sensation","Captain Charisma"],
    country: "Morocco",
    flag: function () {
      return `assets/Images/flags/${this.country}.webp`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  {
    name: "Wajahat Sheikh",
    designation: "Head Of Product",
    department: "Product",
     hobby:["Biryani Boss", "Tech Trailblazer", "Gaming Gourmet","Curiosity Connoisseur"],
    country: "Pakistan",
    flag: function () {
      return `assets/Images/flags/${this.country}.webp`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  {
    name: "Muath Qannita",
    designation: "Head Of Human Resources",
    department: "Human Resources",
     hobby:["Yaprak sarma enthusiast", "loves catching Z's", "Footie Fanatic"],
    country: "Palestine",
    flag: function () {
      return `assets/Images/flags/${this.country}.webp`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  {
    name: "Suhail Panjwani",
    designation: "CMO",
    department: "Marketing",
     hobby:["Seafood Fan", "Beach Bum", "Car Maniac","Sports Enthusiast"],
    country: "Malawi",
    flag: function () {
      return `assets/Images/flags/${this.country}.webp`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  {
    name: "Ademola Adeoti",
    designation: "CTO",
    department: "Technical",
     hobby:["Tech Titan","Opinionator","The Nomad"],
    country: "Nigeria",
    flag: function () {
      return `assets/Images/flags/${this.country}.webp`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
 
  {
    name: "Jade Martin",
    designation: "Head Of Creativity",
    department: "Creative",
     hobby:["Art-o-holic", "Sketchy Wizard", "Page-turning Prodigy","Citizen of the World"],
    country: "Philippines",
    flag: function () {
      return `assets/Images/flags/${this.country}.webp`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  {
    name: "Abdulkarim Abdullahi",
    designation: "Backend Laravel/PHP Engineer",
    department: "Technical",
     hobby:["Code Ninja", "Football Maestro", "Double Degree Dynamo"],
    country: "Nigeria",
    flag: function () {
      return `assets/Images/flags/${this.country}.webp`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.png`;
    },
  },
  {
    name: "Madina Igilik",
    designation: "Intl Business Developer",
    department: "IBD",
     hobby:["Lipstick lover", "Music non-believer", "Ambidextrous writer"],
    country: "Kazakhstan",
    flag: function () {
      return `assets/Images/flags/${this.country}.webp`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  {
    name: "Sevde Esma GÜLEÇ",
    designation: "Relationship Coordinator",
    department: "IBD",
     hobby:["Wanderlust for Indonesia", "Tahini Ice Cream Connoisseur", "Childhood Firefighter Dreamer"],
    country: "Turkey",
    flag: function () {
      return `assets/Images/flags/${this.country}.webp`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  {
    name: "Kenadid Ahmed Osman",
    designation: "School Partnership Coordinator",
    department: "Partner",
     hobby:["Multi-degree achiever", "Somali lit lover", "Furry friend phobia","Learning enthusiast"],
    country: "Somalia",
    flag: function () {
      return `assets/Images/flags/${this.country}.webp`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  {
    name: "Umuhire Ingabire Grace",
    designation: "School Partnership Coordinator",
    department: "Partner",
     hobby:["Stat Storyteller", "Pattern Detective", "Polyglot Dreamer"],
    country: "Rwanda",
    flag: function () {
      return `assets/Images/flags/${this.country}.webp`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  {
    name: "Anthony Gichuki",
    designation: "School Partnership Coordinator",
    department: "Partner",
     hobby:["Football Enthusiast", "Victory Spectator", "Socialpreneur"],
    country: "Kenya",
    flag: function () {
      return `assets/Images/flags/${this.country}.webp`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  {
    name: "Anas Jamani",
    designation: "School Partnership Coordinator",
    department: "Partner",
     hobby:["Entertainment enthusiast", "Multifaceted explorer", "Renaissance mind","Cultural Skeptic"],
    country: "Morocco",
    flag: function () {
      return `assets/Images/flags/${this.country}.webp`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  {
    name: "N'guessan Amani Marcel",
    designation: "School Partnership Coordinator",
    department: "Partner",
     hobby:["Jokester", "Challenge Connoisseur", "Smiling Ambassador"],
    country: "Senegal",
    flag: function () {
      return `assets/Images/flags/${this.country}.webp`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  {
    name: "Sanuwer Husen Shojib",
    designation: "School Partnership Coordinator",
    department: "Partner",
     hobby:["Leadership", "Dancing", "Highly Approachable"],
    country: "Bangladesh",
    flag: function () {
      return `assets/Images/flags/${this.country}.webp`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  {
    name: "Inakwu Isaac",
    designation: "School Partnership Coordinator",
    department: "Partner",
     hobby:["Leadership", "Dancing", "Highly Approachable"],
    country: "Nigeria",
    flag: function () {
      return `assets/Images/flags/${this.country}.webp`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  {
    name: "Aqsa Razzaq",
    designation: "School Partnership Coordinator",
    department: "Partner",
     hobby:["Data nerd", "problem solver", "Innovation junkie"],
    country: "Pakistan",
    flag: function () {
      return `assets/Images/flags/${this.country}.webp`;
    },
    image: function () {
      return 'assets/Icon/test-avatar.svg';
    },
  },
  {
    name: "Oluwasegun Adesina",
    designation: "Intl Business Developer",
    department: "IBD",
     hobby:["Towering Smile", "Listening Ear", "Attentive Connector"],
    country: "Nigeria",
    flag: function () {
      return `assets/Images/flags/${this.country}.webp`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  
  {
    name: "Rayen Ben Khedher",
    designation: "Relationship Coordinator",
    department: "IBD",
     hobby:["Choco-cheese sandwich lover", "Light-on sleeper", "Roadside bread muncher"],
    country: "Tunusia",
    flag: function () {
      return `assets/Images/flags/${this.country}.webp`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  
  {
    name: "Sabeeha Tariq",
    designation: "Content Writing",
    department: "Marketing",
     hobby:["Giggle Guru", "Lizardphobe", "Uncontrollable Chuckler"],
    country: "Nigeria",
    flag: function () {
      return `assets/Images/flags/${this.country}.webp`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  {
    name: "Begüm Değer Soy",
    designation: "Translation",
    department: "Marketing",
     hobby:["Self-Talking Linguist", "Teleportation Dreamer", "Yaprak Sarma Enthusiast"],
    country: "Turkey",
    flag: function () {
      return `assets/Images/flags/${this.country}.webp`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  {
    name: "David Ayegba",
    designation: "Quality Assurance",
    department: "Marketing",
     hobby:["Movie Maven", "Bookworm by Night", "Pool Shark"],
    country: "Nigeria",
    flag: function () {
      return `assets/Images/flags/${this.country}.webp`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  
 
  
  {
    name: "Martina Krstova",
    designation: "Social Media Marketing",
    department: "Marketing",
     hobby:["Basketball player", "Tech wizard with an engineering degree", "Foodie extraordinaire with insatiable hunger"],
    country: "Bulgarian",
    flag: function () {
      return `assets/Images/flags/${this.country}.webp`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  {
    name: "Mustafa ÖRS",
    designation: "Frontend Developer",
    department: "Technical",
     hobby:["Wanderlust Coder", "Football Fanatic", "Puzzle Mastermind"],
    country: "Turkey",
    flag: function () {
      return `assets/Images/flags/${this.country}.webp`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  {
    name: "John Ebah",
    designation: "PHP Developer Mobile",
    department: "Technical",
     hobby:["Food lover", "Sarcastic in a joke way", "Music is his thing"],
    country: "Nigeria",
    flag: function () {
      return `assets/Images/flags/${this.country}.webp`;
    },
    image: function () {
      return 'assets/Icon/test-avatar.svg';
    },
  },
  

  {
    name: "Eweje Femi",
    designation: "Backend Engineer",
    department: "Technical",
     hobby:["Leadership", "Dancing", "Highly Approachable"],
    country: "Nigeria",
    flag: function () {
      return `assets/Images/flags/${this.country}.webp`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  {
    name: "Andrew Blessing",
    designation: "Full Stack-Vue JS Engineer",
    department: "Technical",
     hobby:["Digital architect", "Chess Maniac", "Gaming Addict"],
    country: "Kenya",
    flag: function () {
      return `assets/Images/flags/${this.country}.webp`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  
  {
    name: "Ashika Cherikkaparambath",
    designation: "Frontend Developer",
    department: "Technical",
     hobby:["Kitchen magician","Traveling","Wellness enthusiast"],
    country: "India",
    flag: function () {
      return `assets/Images/flags/${this.country}.webp`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  {
    name: "Onyebuchi Okofu",
    designation: "Frontend Developer",
    department: "Technical",
     hobby:["Collaborative spirit","Curious Explorer","Talkative butterfly"],
    country: "Nigeria",
    flag: function () {
      return `assets/Images/flags/${this.country}.webp`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  {
    name: "Samuel Awofodu",
    designation: "Backend Developer",
    department: "Technical",
     hobby:["Accidental programmer", "Power Napper Moviegoer", "Dog phobia"],
    country: "Nigeria",
    flag: function () {
      return `assets/Images/flags/${this.country}.webp`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
 
  {
    name: "İrem Bayrak",
    designation: "People Operations Assistant",
    department: "Human Resources",
     hobby:["Art Aficionado", "Aspiring Web-Weaver", "Yoga Enthusiast"],
    country: "Turkey",
    flag: function () {
      return `assets/Images/flags/${this.country}.webp`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  {
    name: "Nur Gökçemen",
    designation: "People Operations Assistant",
    department: "Human Resources",
     hobby:["Mermaid", "Sunshine in human form", "Motivation station"],
    country: "Turkey",
    flag: function () {
      return `assets/Images/flags/${this.country}.webp`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  
  
  {
    name: "Rehana Maryyam",
    designation: "Up2Us Project Manager",
    department: "Marketing",
     hobby:["Dancing Dynamo", "Linguistic Luminary", "Opening Act Ace"],
    country: "Pakistan",
    flag: function () {
      return `assets/Images/flags/${this.country}.webp`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  {
    name: "Muhammad Ehtesham",
    designation: "SEO",
    department: "Marketing",
     hobby:["Brainstorm champ", "Food Explorer", "Social butterfly"],
    country: "Pakistan",
    flag: function () {
      return `assets/Images/flags/${this.country}.webp`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  
  
  
  {
    name: "Sara Abbasi",
    designation: "UI/UX Designer",
    department: "Product",
     hobby:["Feline fanatic", "Adventure seeker", "Efficiency expert"],
    country: "Iran",
    flag: function () {
      return `assets/Images/flags/${this.country}.webp`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },

  {
    name: "Aisha Shahab",
    designation: "Creative Designer",
    department: "Creative",
     hobby:["Caninephobe", "Yolkless Yum-seeker", "Karaoke Queen"],
    country: "Pakistan",
    flag: function () {
      return `assets/Images/flags/${this.country}.webp`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  {
    name: "Ubong Nse Titus",
    designation: "Videography",
    department: "Creative",
     hobby:["Gunner for life", "From law dreams to movie reels", "Movies Over Meals"],
    country: "Nigeria",
    flag: function () {
      return `assets/Images/flags/${this.country}.webp`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  
  
];
