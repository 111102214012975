import Call from "../../../assets/Icon/iconCall.svg";
import Ellipse from "../../../assets/Icon/Ellipse 23.webp";
import Polygon from "../../../assets/Icon/Polygon 1.webp";
import Star from "../../../assets/Icon/Star 1.webp";
import Circle from "../../../assets/Icon/Ellipse 24.webp";
import BannerImage from "../../../assets/Images/Group 15.webp";

import "./TopSection.css";
import { useTranslation } from "react-i18next";
import { formatCompactNumber } from "../../../lib/utils/formatCompactNumber";

const TopSection = () => {
  const { t } = useTranslation();
  return (
    <div className="pt-20">
      <div className="section1">
        <div className="banner px-8 md:mt-10">
          <div className="bannerText">
            <div className="bigText">
              <p>{t("Revolutionizing Study Abroad")}</p>
            </div>
            <div className="smallText">
              <p className="">
                At <span className="notranslate">Paramount Students</span>, we
                believe that the journey of studying abroad goes beyond earning
                a degree. It's about creating connections that transcend borders
                and fostering a global community of high schools, parents,
                teachers, and universities. We simplify the student recruitment
                process and provide a seamless solution to enhance the overall
                student experience.
              </p>
            </div>
            <div className="buttons ">
              <a
                className="bannerBtn2 py-4 hover:bg-primary text-black items-center flex gap-[9px]"
                href="mailto:khalid@paramountstudents.com">
                {t("Contact Us")}
                <img loading="lazy" src={Call} alt="phone" />
              </a>
            </div>
          </div>
          <div className="bannerImg">
            <div className="groupImg">
              <div className="bigImg">
                <div className="trIcon">
                  <img loading="lazy" src={Ellipse} alt="Elipse" />
                </div>
                <div className="starIcon">
                  <img loading="lazy" src={Star} alt="icon" />
                </div>
                <div className="polygonIcon">
                  <img loading="lazy" src={Polygon} alt="icon" />
                </div>
                <div className="starIcon">
                  <img loading="lazy" src={Star} alt="icon" />
                </div>
                <div className="circleIcon">
                  <img loading="lazy" src={Circle} alt="icon" />
                </div>
                <div className="bigImage">
                  <img
                    loading="lazy"
                    src={BannerImage}
                    alt="A collage of university students with laptops and smiling"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="relative bg-primary bg-texture-cta">
        <div className="figures grid grid-cols-2 gap-y-10 gap-x-2 py-10 px-2 md:gap-[151px] md:flex justify-center">
          <div className="content one">
            <h4 className="text-2xl md:text-6xl">
              {formatCompactNumber(7000)}+
            </h4>
            <p className="text-sm md:text-lg">{t("Students")}</p>
          </div>
          <div className="content two">
            <h4 className="text-2xl md:text-6xl">{formatCompactNumber(50)}+</h4>
            <p className="text-sm md:text-lg">
              {t("International Universities")}
            </p>
          </div>
          <div className="content three">
            <h4 className="text-2xl md:text-6xl">
              {formatCompactNumber(200)}+
            </h4>
            <p className="text-sm md:text-lg">{t("High Schools")}</p>
          </div>
          <div className="content four">
            <h4 className="text-2xl md:text-6xl">{formatCompactNumber(15)}+</h4>
            <p className="text-sm md:text-lg">{t("Communities")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopSection;
