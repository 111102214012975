import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "./pages/About";
import Communities from "./pages/Communities";
import Home from "./pages/Home";
import IncubationCenters from "./pages/Incubation Centers";
import Services from "./pages/Services";
import Products from "./pages/Products";
import Team from "./pages/Team";
import ScrollToTop from "./components/App/scrollToTop";
import NewsletterThanks from "./pages/Newsletter/Thanks";
import TermsAndConditions from "./pages/Docs/TermsAndConditions";
import PrivacyPolicy from "./pages/Docs/PrivacyPolicy";
import FAQs from "./pages/Docs/FAQs";
import Vtour from "./pages/Vtour";
import "./pages/style.css";
import HighSchoolDemoPage from "./pages/HighSchoolDemo";
import UniversityDemoPage from "./pages/UniversityDemo";
import VirtualTourRequestPage from "./pages/VirtualTourRequest";
import Blog from "./pages/Blog";
import ReadBlog from "./pages/ReadBlog";
import WaitList from "./pages/WaitList";
import Deactivate from "./pages/Deactivate";
import MobileAppLandingPage from "./pages/mobileapp/MobileAppLandingPage";

function App() {
  return (
    <div>
      <BrowserRouter>
        <ScrollToTop>
          <Routes>
            <Route path="/mobileapp" element={<MobileAppLandingPage />} />
            <Route path="/">
              <Route index element={<Home />}></Route>
            </Route>
            <Route path="/our-services">
              <Route index element={<Services />}></Route>
            </Route>
            <Route path="/our-products" element={<Products />} />
            <Route path="/incubation-centers" element={<IncubationCenters />} />
            <Route path="/communities" element={<Communities />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/vtour" element={<Vtour />} />
            <Route path="/meet-the-team">
              <Route index element={<Team />}></Route>
            </Route>
            <Route path="/blog">
              <Route index element={<Blog />}></Route>
              <Route path=":blogId" element={<ReadBlog />}></Route>
            </Route>
            <Route path="/terms-and-conditions">
              <Route index element={<TermsAndConditions />}></Route>
            </Route>
            <Route path="/privacy-policy">
              <Route index element={<PrivacyPolicy />}></Route>
            </Route>
            <Route path="/frequently-asked-questions">
              <Route index element={<FAQs />}></Route>
            </Route>
            <Route path="/newsletter-thanks">
              <Route index element={<NewsletterThanks />}></Route>
            </Route>
            <Route path="/high-school-demo">
              <Route index element={<HighSchoolDemoPage />}></Route>
            </Route>
            <Route path="/university-demo">
              <Route index element={<UniversityDemoPage />}></Route>
            </Route>
            <Route path="/virtual-tour-request">
              <Route index element={<VirtualTourRequestPage />}></Route>
            </Route>
            <Route path="/network">
              <Route index element={<WaitList />}></Route>
            </Route>
            <Route path="/deactivate-account">
              <Route index element={<Deactivate />}></Route>
            </Route>
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    </div>
  );
}

export default App;
