import Layer1 from '../../../assets/Icon/Layer 0 1.svg';
import Layer2 from '../../../assets/Icon/resume 1 copy 1.svg';
import Layer3 from '../../../assets/Icon/online-community 1 copy 1.svg';
import Layer4 from '../../../assets/Icon/Layer 0 2.svg';
import Call from '../../../assets/Icon/iconCall.svg';
import rightArrow from '../../../assets/Icon/arrow-right.svg';
import './Services.css';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Services = () => {
  const { t } = useTranslation();
  const servicesCard = [
    {
      title: 'Discover Universities',
      desc: 'Unlock a world of possibilities: Discover the best universities in the world around the globe for higher education with Paramount Students',
      icon: Layer1,
    },
    {
      title: 'Guided Application Support',
      desc: 'Personalized tools and expert guidance for informed decision-making and smooth application processes with paramount students',
      icon: Layer2,
    },
    {
      title: 'A Global Student Network',
      desc: 'Connecting students worldwide: embracing diversity, fostering unity, and building a global community for a student experience of a lifetime',
      icon: Layer3,
    },
    {
      title: 'Simplified Access To Accommodation',
      desc: 'Simplified access to student accommodation services. We take the hassle out of searching for a place to call home during your studies.',
      icon: Layer4,
    },
  ];
  return (
    <div className="services md:px-10 py-10 md:py-16">
      <div className="intro px-3 mb-4">
        <p>{t('Our Services')}</p> <hr />
      </div>
      <div className="heading px-3 text-2xl md:text-4xl">
        <h4>
          {t('Join A Valuable Platform Designed For Go Getters!')}
        </h4>
      </div>
      <div className="service grid grid-cols-2 md:flex md:justify-center gap-3 md:gap-5 mb-4 md:mb-10">
        {servicesCard.map((card, index) => {
          return (
            <div
              className="service-card px-3 md:px-5 py-5 md:justify-center md:w-[313px]"
              key={index}
            >
              <div className="icon ">
                <img
                  loading="lazy"
                  src={card.icon}
                  className="flex"
                  alt="icon"
                />
              </div>
              <div className="title">
                <h4 className="text-lg md:text-[1.75rem] leading-[1.5rem] md:leading-[2.25rem] font-medium">
                  {t(card.title)}
                </h4>
              </div>
              <div className="text">
                <p className="text-xs md:text-sm leading-[1.125rem] md:leading-[1.25rem]">
                  {t(card.desc)}
                </p>
              </div>
            </div>
          );
        })}
      </div>

      <div className="buttons">
        <Link
          className="bannerBtn1 h-14 md:h-20 bg-[#ffc400] flex items-center md:text-[#272927] text-base md:text-lg gap-1"
          title="Services"
          to="/our-services"
        >
          {t('Explore More')}{' '}
          <img loading="lazy" src={rightArrow} alt="arrow" />
        </Link>
        <a
          href="mailto:khalid@paramountstudents.com"
          title="Contact"
          className="bannerBtn2 h-14 md:h-20 bg-transparent hover:bg-[#ffc400] hover flex items-center text-[#272927] text-base md:text-lg gap-1"
        >
          {t('Contact Us')}{' '}
          <img loading="lazy" className="w-6 h-6" src={Call} alt="phone" />
        </a>
      </div>
    </div>
  );
};

export default Services;
