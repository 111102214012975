import UskudarUniversityImage from "../../assets/Images/uskudar-university.webp";

const virtualTourUniversities = [
  {
    university: "Uskudar university",
    location: "Turkey",
    image: UskudarUniversityImage,
    link:"https://uskudar.edu.tr/sanaltur/merkez-yerleske/tr.html"
  },
  {
    university: "BAU university",
    location: "Turkey",
    image: UskudarUniversityImage,
    link:"https://my.matterport.com/show/?m=pccoEjLAGov&brand=0&sr=-1.34,-1.46&ss=2",
  },
];

export default virtualTourUniversities;
