import React from "react";
import SmoothScroll from "../../../components/App/SmoothScroll";
import Layout from "../../../components/Layout";
import Container from "../../../components/Layout/Container";
import NewsletterThanksInfo from "../../../assets/Images/newsletter-thanks.svg";

const NewsletterThanks = () => {
  return (
    <Layout
      transparentHeaderOnPhone
      disableFooter
      headerMobileMenuIconClassName="!fill-black"
    >
      <SmoothScroll />
      <main className="page about-page">
        <section className="section top-section flex justify-center pt-20">
          <Container maxWidth="xl">
            <div className="flex flex-col items-center md:items-start pt-6">
              <div className="">
                <h2 className="text-text-primary font-medium text-5xl md:text-7xl leading-[54px] md:leading-[90px]">
                  Welcome !
                </h2>
                <p className="text-text-dim font-normal font-sm leading-7 md:text-2xl">
                  Thank you for subscribing to us.
                </p>
              </div>
              <div className="mt-12 flex justify-center w-full">
                <img
                  src={NewsletterThanksInfo}
                  alt="newsletter thanks infographic"
                  className="w-2/3 md:w-96"
                />
              </div>
              <div className="text-text-dim mt-8 md:mt-16 text-center font-normal font-sm leading-7 md:text-2xl">
                <p>
                  Welcome to Sunday Snippets! You're officially in, with more
                  than 135,000 friendly readers. Since you're already here, you
                  might want to check out some of the most popular articles:
                </p>
              </div>
            </div>
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default NewsletterThanks;
