import React from "react";
import Container from "../../../Layout/Container";
import DocumentationItem from "../../Layout/DocumentationItem";

const DocumentationContent = () => {
  const classes = { DocumentationItem: "mt-6 md:mt-12" };
  return (
    <section className="section tac-conent-section flex flex-col items-center py-2">
      <Container maxWidth="lg">
        <div className="border-solid border-l-2 border-y-0 border-r-0 border-l-[#4F4F4F]">
          <DocumentationItem title="Introduction" className={classes["DocumentationItem"]}>
            <p>
              Welcome to the Paramount Students website. These Terms and Conditions govern your use of our website, including all its features, content, and services. By accessing and using our website, you agree to be bound by these Terms and Conditions. If you do not agree with any part of these terms, please refrain from using our website.
            </p>
          </DocumentationItem>
          <DocumentationItem title="Intellectual Property" className={classes["DocumentationItem"]}>
            <p>
              All content, materials, and intellectual property on this website, including but not limited to text, graphics, logos, images, and software, are the property of Paramount Students or its licensors and are protected by applicable copyright and intellectual property laws. You may not reproduce, distribute, or use any content from this website without prior written permission from Paramount Students.
            </p>
          </DocumentationItem>
          <DocumentationItem title="User Conduct" className={classes["DocumentationItem"]}>
            <p>
              When using our website, you agree to:
            </p>
            <ol type="a">
              <li> Abide by all applicable laws and regulations.</li>
              <li> Provide accurate and truthful information when using our services.</li>
              <li> Refrain from engaging in any activity that may harm, disrupt, or interfere with the website's functioning or security.</li>
              <li> Respect the intellectual property rights of Paramount Students and others.</li>
              <li> Refrain from using our website for any unlawful or prohibited purposes.</li>

            </ol>
          </DocumentationItem>
          <DocumentationItem title="User Accounts and Security" className={classes["DocumentationItem"]}>
            <p>
              To access certain features or services on our website, you may be required to create a user account. You are responsible for maintaining the confidentiality of your account information, including your username and password. You agree to accept responsibility for all activities that occur under your account.
            </p>
          </DocumentationItem>
          <DocumentationItem title="Third-Party Links" className={classes["DocumentationItem"]}>
            <p>
              Our website may contain links to third-party websites or resources that are not controlled by Paramount Students. We are not responsible for the content or practices of any linked websites. Visiting third-party links is at your own risk, and we recommend reviewing their respective terms and privacy policies.
            </p>
          </DocumentationItem>
          <DocumentationItem title="Disclaimer of Warranties" className={classes["DocumentationItem"]}>
            <p>
              Our website and its content are provided on an "as-is" basis. Paramount Students makes no warranties, express or implied, regarding the accuracy, reliability, or completeness of the information or materials on this website. Your use of our website is at your own risk.
            </p>
          </DocumentationItem>
          <DocumentationItem title="Limitation of Liability" className={classes["DocumentationItem"]}>
            <p>
              In no event shall Paramount Students be liable for any direct, indirect, incidental, special, or consequential damages arising from or in connection with the use or inability to use our website or its content. This includes damages for loss of data, profits, or business opportunities.
            </p>
          </DocumentationItem>
          <DocumentationItem title="Modifications to the Website and Terms" className={classes["DocumentationItem"]}>
            <p>
              Paramount Students reserves the right to modify, suspend, or discontinue the website or any part thereof at any time without prior notice. We may also update or amend these Terms and Conditions from time to time. By continuing to use our website after such modifications, you accept and agree to the revised terms.
            </p>
          </DocumentationItem>
        </div>
      </Container>
    </section>
  );
};

export default DocumentationContent;
