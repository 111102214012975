import React from "react";
import Marquee from "react-fast-marquee";

const Universities = () => {
  return (
    <>
      <div>
        <div className="flex flex-row justify-center items-center mt-10 sm:mt-28 md:mt-72 transition-all duration-500 ease-in-out">
          <img
            src="/mobileapp/left-flower.png"
            alt="left flower"
            className="w-10 h10"
          />
          <p className="font-bold text-gray-900 text-xl md:text-4xl mx-2 font-recoleta">
            Trusted Universities
          </p>
          <img
            src="/mobileapp/right-flower.png"
            alt="right flower"
            className="w-10 h-10"
          />
        </div>
      </div>
      <div className="my-14">
        <Marquee className="mb-4 md:mb-8" speed={100}>
          <div className="mx-10 md:mx-20">
            <img
              className="w-16 md:w-24"
              loading="lazy"
              src="/mobileapp/imbs.png"
              alt="universities"
            />
          </div>
          <div className="mx-10 md:mx-20">
            <img
              className="w-16 md:w-24"
              loading="lazy"
              src="/mobileapp/bhms.png"
              alt="universities"
            />
          </div>
          <div className="mx-10 md:mx-20">
            <img
              className="w-16 md:w-24"
              loading="lazy"
              src="/mobileapp/vistula.png"
              alt="universities"
            />
          </div>
          <div className="mx-10 md:mx-20">
            <img
              className="w-16 md:w-24"
              loading="lazy"
              src="/mobileapp/uni.png"
              alt="universities"
            />
          </div>
          <div className="mx-10 md:mx-20">
            <img
              className="w-16 md:w-24"
              loading="lazy"
              src="/mobileapp/istanbul.png"
              alt="universities"
            />
          </div>
          <div className="mx-10 md:mx-20">
            <img
              className="w-16 md:w-24"
              loading="lazy"
              src="/mobileapp/miu.png"
              alt="universities"
            />
          </div>
          <div className="mx-10 md:mx-20">
            <img
              className="w-16 md:w-24"
              loading="lazy"
              src="/mobileapp/dankook.png"
              alt="universities"
            />
          </div>
        </Marquee>
      </div>
    </>
  );
};

export default Universities;
