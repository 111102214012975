import React from "react";
import { motion } from "framer-motion";

const Services = () => {
  return (
    <div className="flex flex-col justify-center items-center">
      <p className="font-bold text-secondTitle font-recoleta text-2xl lg:text-6xl my-10">
        Our Services
      </p>
      <div className="mt-10">
        <div
          className="w-[50vw] h-[50vw] md:h-[48vw] md:w-[48vw]  lg:w-[30vw] lg:h-[30vw] rounded-[50%] bg-gradient-to-b from-background13 to-background14 
      flex justify-center items-center border shadow-inner shadow-[rgba(0,0,0,0.6)] shadow-y-4 shadow-blur-10 transition-all duration-500 ease-in-out">
          <div
            className="w-[35vw] h-[35vw] md:h-[36vw] md:w-[36vw] lg:w-[23vw] lg:h-[23vw] rounded-[50%] bg-background15 flex items-center justify-center relative 
          transition-all duration-500 ease-in-out">
            <motion.div
              className="wrapper"
              transition={{ duration: 1, delay: 0.25 }}
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}>
              <div
                className="w-[120px] h-[50px] sm:w-[160px] sm:h-[70px] md:w-[220px] md:h-[100px] lg:w-[300px] lg:h-[120px]
               bg-white absolute p-2 rounded-lg sm:rounded-xl flex flex-col space-y-1 justify-center 
          shadow-[0_4px_24px_0px_rgba(207,208,209,1)] -top-5 -left-28 sm:-left-36 md:-left-52 lg:-left-64 px-2">
                <p className="text-pinkText  text-[7px] sm:text-[9px] md:text-[12px] lg:text-[16px] font-bold">
                  Unlock 500+ Uni Programs
                </p>
                <p className="text-gray-900 font-inter text-[5px] sm:text-[9px] md:text-[12px] lg:text-[14px]">
                  Paramount Students offers you the opportunity to unlock access
                  to numerous universities worldwide without any obstacles.
                </p>
              </div>
            </motion.div>
            <motion.div
              className="wrapper"
              transition={{ duration: 1, delay: 0.25 }}
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}>
              <div
                className="w-[120px] h-[50px] sm:w-[160px] sm:h-[70px] md:w-[220px] md:h-[100px] lg:w-[300px] lg:h-[120px]
               bg-white absolute p-2 rounded-lg sm:rounded-xl flex flex-col space-y-1 justify-center 
          shadow-[0_4px_24px_0px_rgba(207,208,209,1)] -top-5 -right-28 sm:-right-36 md:-right-52 lg:-right-64 px-2">
                <p className="text-blueText text-[7px] md:text-[12px] lg:text-[16px]  font-bold">
                  Data Security
                </p>
                <p className="text-gray-900 font-inter text-[5px] sm:text-[9px] md:text-[12px] lg:text-[14px] ">
                  Paramount Students ensures the protection and security of
                  every student's legitimate documents.
                </p>
              </div>
            </motion.div>
            <motion.div
              className="wrapper"
              transition={{ duration: 1, delay: 0.25 }}
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}>
              <div
                className="w-[120px] h-[50px] sm:w-[160px] sm:h-[70px] md:w-[220px] md:h-[100px] lg:w-[300px] lg:h-[120px]
               bg-white absolute p-2 rounded-lg sm:rounded-xl flex flex-col space-y-1 justify-center 
          shadow-[0_4px_24px_0px_rgba(207,208,209,1)] -bottom-4 sm:bottom-8 -left-28 sm:-left-40 md:-left-56 lg:-left-72 px-2">
                <p className="text-greenText text-[7px] md:text-[12px] lg:text-[16px]  font-bold">
                  Direct Communication
                </p>
                <p className="text-gray-900 font-inter text-[5px] sm:text-[9px] md:text-[12px] lg:text-[14px] ">
                  You can communicate directly with representatives from your
                  desired universities without the need for intermediaries.
                </p>
              </div>
            </motion.div>
            <motion.div
              className="wrapper"
              transition={{ duration: 1, delay: 0.25 }}
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}>
              <div
                className="w-[120px] h-[50px] sm:w-[160px] sm:h-[70px] md:w-[220px] md:h-[100px] lg:w-[300px] lg:h-[120px]
               bg-white absolute p-2 rounded-lg  sm:rounded-xl flex flex-col space-y-1 justify-center border
          shadow-[0_4px_24px_0px_rgba(207,208,209,1)] -bottom-4 sm:bottom-8 -right-28 sm:-right-40  md:-right-44 lg:-right-56 px-2">
                <p className="text-amberText text-[7px] md:text-[12px] lg:text-[16px]  font-bold">
                  Free Applications
                </p>
                <p className="text-gray-900 font-inter text-[5px] sm:text-[9px] md:text-[12px] lg:text-[14px] ">
                  You can communicate directly with representatives from your
                  desired universities without the need for intermediaries.
                </p>
              </div>
            </motion.div>

            <img
              loading="lazy"
              src="/mobileapp/login-image.png"
              alt="login"
              className="h-[60vw] w-[25vw] md:h-[60vw] lg:h-[35vw] lg:w-[15vw] transition-all duration-500 ease-in-out"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
