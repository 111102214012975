import React from "react";
import Applinsk from "./Applinsk";

const DownloadApp = () => {
  return (
    <div className="flex flex-row mt-10 pl-2 md:pl-0 ">
      <div className="w-1/2 flex flex-col justify-center items-center  p-2">
        <div className="flex flex-col  w-[190px] sm:w-[300px] md:w-[400px] lg:w-[560px]  space-y-4 lg:space-y-10">
          <p className="font-graphik font-bold text-[14px] sm:text-[21px] md:text-[28px] lg:text-[35px]">
            Download the Paramount Students app today and take the first step
            towards a brighter future!
          </p>
          <div className="w-full  mt-2 h-auto flex flex-row relative justify-between ">
            <Applinsk />
            <img
              src="/mobileapp/spiral-pointer.png"
              alt="spiral pointer"
              className="absolute w-8 sm:w-12 md:w-16 lg:w-24 -right-8 -top-3 sm:-right-12 sm:-top-8 md:-top-6 md:-right-20 lg:-right-28 lg:-top-12"
            />
          </div>
        </div>
      </div>
      <div className="w-1/2 flex flex-col items-center ">
        <div className="h-48 w-28 sm:h-64 sm:w-48 md:h-[400px] md:w-72 lg:h-[550px] lg:w-[450px] xl:w-[550px] xl:h-[600px] bg-background15 rounded-t-xl sm:rounded-t-3xl flex flex-row justify-center items-end rel">
          <img
            src="/mobileapp/home-section.png"
            alt="home section"
            className=" w-24 h-44 sm:w-36 sm:h-60 md:h-[370px] md:w-56 lg:h-[490px] lg:w-[350px] xl:w-[400px] xl:h-[550px] rounded-t-2xl"
          />
        </div>
      </div>
    </div>
  );
};

export default DownloadApp;
