import React, { useState, useEffect } from 'react';
import View360, { EquirectProjection } from "@egjs/react-view360";
import "@egjs/react-view360/css/view360.min.css";
import { fetchData } from '../../api/axios';
import "./style.css"
import { showModal } from '../../lib/modal';
import { Swiper, SwiperSlide } from 'swiper/react'; // Import Swiper components
import 'swiper/swiper-bundle.min.css'; // Import Swiper styles
import V4 from '../../assets/Images/VirtualTour/p.jpg';
import { useNavigate } from 'react-router-dom';
const Vtour = () => {
  const navigate = useNavigate();
  const [scene, setScene] = useState({}); // Initialize scene with an empty object
  const [projection, setProjection] = useState(null);
  const [scenes, setScenes] = useState([]);

  const [clickedHotspotIndex, setClickedHotspotIndex] = useState(null);

  const toggleActions = (index) => {
    setClickedHotspotIndex(clickedHotspotIndex === index ? null : index);
  };

  const viewNewScene = (sceneData) => {
    setScene(sceneData);
    setProjection(new EquirectProjection({ src: sceneData.url + '?not-from-cache-please' }));
    setClickedHotspotIndex(null);
  };

  const getScene = (id) => {
    return scenes.find(item => item.id === id);
  };

  const resolveHotspotAction = (action) => {
    if (action.type === 'NAVIGATE_TO_SCENE') {
      viewNewScene(getScene(action.data));
    } else if (action.type === 'DISPLAY_INFORMATION') {
      showModal({ text: action.data });
    } else if (action.type === 'PRINT') {
      // Handle print action
    } else if (action.type === 'OPEN_URL') {
      // Handle open URL action
    } else if (action.type === 'PLAY_VIDEO') {
      // Handle play video action
    }
  };

  useEffect(() => {
    const fetchSceneData = async () => {
      try {
        const fetchedScenes = await fetchData('https://crm-demo.paramountstudents.com/api/virtual_tours/1');
        if (fetchedScenes.length > 0) {
          setScenes(fetchedScenes);
          viewNewScene(fetchedScenes[0]);
        }else{
          setProjection(new EquirectProjection({ src:V4 }));
        }
      } catch (error) {
        setProjection(new EquirectProjection({ src:V4 }));
        console.error('Error fetching scene data:', error);
      }
    };

    fetchSceneData();
  }, []);
  const  onready = (e)=>{       
    window.panor = e
    //this.loading = false
  }
  return (
    <div className='w-full h-screen'>
      <div style={{ position: 'relative' }}>
        <div style={{background:'#0003', display:'flex',justifyContent:'space-between',alignItems:'center',padding:'10px', position:'absolute',top:'0px',left:'0px', width:'100%', zIndex:'10'}}>
          <button onClick={(()=>{navigate(-1)})}  className='py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-amber-300 text-white hover:bg-amber-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800'  >Exit</button>          
          <div style={{color:'white'}}>{scene.name}</div>
          <div style={{visibility:'hidden'}}>-</div>
        </div>
        {projection && (
          <View360
            className="is-16by9 h-[100vh] w-[100%]"
            projection={projection}
            onReady={onready()}
            autoplay={true}
          />
        )}

        <div className="vSlide-content w-[100%]">
          <Swiper
            className="px-3"            
            scrollbar={{ draggable: true }}
            spaceBetween={5}
            slidesPerView={5}
            loop={false}
          >
            {scenes.map((sceneIn, k) => (
              <SwiperSlide key={k}>
                <div className={scene.url== sceneIn.url?'ActiveScene h-[90px] md:h-[124px]':'h-[90px] md:h-[124px]'} style={{ cursor: 'pointer', }}>
                  <h5 className='text-[12px] md:text-[20px]' style={{textShadow:'1px 1px #555', fontWeight:500,position:'absolute',color:'white',bottom:'15px',left:'15px'}}>{sceneIn.name}</h5>
                  <img className={scene.url== sceneIn.url?'ActiveScene2':''}  onClick={() => viewNewScene(sceneIn)} alt='panoramic-img'  src={sceneIn.url} style={{  width: '100%', height: '90%' }} />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        {scene?.hotspots && scene.hotspots.length > 0 && scene.hotspots.map((hot, i) => (
          <div key={i} style={{ position: 'absolute', zIndex: 5 }} className={`x-${hot.position_x} y-${hot.position_y} v-hotspot`}>
            <button className="hotsportBtn ripple bg-transparent" title={hot.name} onClick={() => toggleActions(i)}>
              {hot.name}
            </button>
            {clickedHotspotIndex === i && (
              <div style={{ minWidth: '200px', position: 'absolute', background: 'rgb(0 0 0 / 44%)', borderRadius: '5px', color: 'white' }}>
                {hot?.actions && hot.actions.length > 0 && hot.actions.map((action, j) => (
                  <div key={j} tabIndex={j} className="hotspotlist" onClick={() => resolveHotspotAction(action)}>
                    {action.type === 'DISPLAY_INFORMATION' && (
                      <div className="hidden-xx">
                        <img alt='icon' style={{ width: '30px' }} src="/info2.svg" />
                        <span className="ms-2">{action.data}</span>
                      </div>
                    )}
                    {action.type === 'NAVIGATE_TO_SCENE' && (
                      <div className="hidden-xx">
                        <img alt='icon' style={{ width: '30px' }} src="/up_arrow.svg" />
                        <span className="ms-2">{action.description || `Go to ${getScene(scenes, action.data)?.name}`}</span>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Vtour;
