import React from "react";

const TeamMemberCard = ({ name, designation,image }) => {
  
  
  return (
    <div className="flex flex-col relative flex-nowrap justify-start items-center p-3 gap-3 md:h-[269px]  min-h-[348px]">
      <img
      
        src="https://e7.pngegg.com/pngimages/799/987/png-clipart-computer-icons-avatar-icon-design-avatar-heroes-computer-wallpaper-thumbnail.png"
        alt=""
        className="w-[160px] h-[160px] rounded-full"
      />
      <h3 className="text-white text-2xl font-semibold leading-8 text-center ">
        {name}
      </h3>

      <p className="text-white font-normal text-base capitalize text-center leading-6">
        {designation}{" "}
      </p>

      <div className="bg-white w-[24px] h-[24px] items-center text-center justify-center flex ">
        <i className="fa-brands fa-linkedin-in"></i>
      </div>
      <span className="text-[18px] font-semibold capitalize leading-6 text-white md:hidden">
        {" "}
        Fun Fact About Me
      </span>
      <p className="font-normal text-[18px] leading-5 capitalize text-white/[.7] md:hidden text-center">
        Leadership, Dancing, Highly Approachable
      </p>
    </div>
  );
};

export default TeamMemberCard;
