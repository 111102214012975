import React from "react";
import { BsInstagram } from "react-icons/bs";
import { FaTiktok, FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { IoCall } from "react-icons/io5";
import { MdOutlineMail } from "react-icons/md";
import { footerLinks } from "../../lib/static/socialLinks";

const socialIcons = [
  {
    icon: BsInstagram,
    sizeClass: "text-2xl md:text-4xl lg:text-6xl",
    link: footerLinks.find((link) => link.platform === "Instagram").link,
  },
  {
    icon: FaTiktok,
    sizeClass: "text-2xl md:text-4xl lg:text-6xl",
    link: footerLinks.find((link) => link.platform === "TikTok").link,
  },
  {
    icon: FaFacebookF,
    sizeClass: "text-2xl md:text-4xl lg:text-6xl",
    link: footerLinks.find((link) => link.platform === "Facebook").link,
  },
  {
    icon: FaLinkedinIn,
    sizeClass: "text-2xl md:text-4xl lg:text-6xl",
    link: footerLinks.find((link) => link.platform === "LinkedIn").link,
  },
];

const contactDetails = [
  { icon: IoCall, text: "+442081596934", size: 15 },
  {
    icon: MdOutlineMail,
    text: "Hi@Paramountstudent.com",
    size: 15,
    link: "mailto:Hi@Paramountstudent.com",
  },
];

const IconContainer = ({ children, link }) => (
  <a href={link} target="_blank" rel="noopener noreferrer">
    <div className="rounded-full flex flex-col justify-center items-center bg-white w-20 h-20 sm:h-28 sm:w-28 md:w-40 md:h-40 lg:w-[220px] lg:h-[220px] hover:cursor-pointer transition-all duration-500 ease-in-out">
      {children}
    </div>
  </a>
);

const ContactDetail = ({ icon: Icon, text, size, link }) => (
  <a href={link} target="_blank" rel="noopener noreferrer">
    <div className="flex flex-row space-x-2 hover:cursor-pointer items-center">
      <Icon color="#fff" size={size} />
      <p className="text-white font-sans text-[8px] sm:text-[10px] md:text-xl">
        {text}
      </p>
    </div>
  </a>
);

const CustomFooter = () => {
  return (
    <div className="bg-[#1A1919] flex flex-col py-20 space-y-5 px-2">
      <div className="flex flex-row justify-around my-2">
        {socialIcons.map(({ icon: Icon, sizeClass, link }, index) => (
          <IconContainer key={index} link={link}>
            <Icon color="#000" className={sizeClass} />
          </IconContainer>
        ))}
      </div>
      <div className="flex flex-row justify-around">
        <hr className="w-[90vw] bg-white" />
      </div>
      <div className="flex flex-row justify-around">
        <div>
          <p className="text-white font-sans text-[8px] sm:text-[10px] md:text-xl">
            2024 paramount students all right reserved
          </p>
        </div>
        <div>
          <img
            src="/mobileapp/black-logo.png"
            alt="paramount logo"
            className="w-20 sm:w-32 md:w-40 h-auto"
          />
        </div>
        <div className="flex flex-col space-y-4">
          {contactDetails.map((detail, index) => (
            <ContactDetail key={index} {...detail} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CustomFooter;
