import React, { useEffect } from "react";
import Layout from "../../components/Layout";
import AboutHero from "../../components/About/AboutHero";
import AboutMentorsSection from "../../components/About/MentorsSection";
import AboutCarousalSection from "../../components/About/AboutCarousal";
import AboutFAQsSection from "../../components/About/AboutFAQs";
import SmoothScroll from "../../components/App/SmoothScroll";
import setPageDescription from "../../lib/utils/setPageDescription";
import usePageMetadata from "../../lib/usePageMetadata";


const AboutPage = () => {
  usePageMetadata("About Us | Paramount Students", "Learn more about working with Paramount Students and how we build radical study-abroad solutions. We make studying abroad effortless.");
  useEffect(() => {    

    setPageDescription('Learn more about working with Paramount Students and how we build radical study-abroad solutions. We make studying abroad effortless.');
    
  }, []);

  return (
    <Layout transparentHeaderOnPhone>
      <SmoothScroll />
      <main className="page about-page">
        <AboutHero />
        <AboutMentorsSection />
        <AboutCarousalSection />
        <AboutFAQsSection />
      </main>
    </Layout>
  );
};

export default AboutPage;
