import React from "react";

const Button = ({ children, className, startIcon, endIcon, click=(()=>{}) }) => {
  return (
    <button onClick={click()}
      className={`flex items-center justify-center cursor-pointer space-x-3 text-base md:text-lg leading-5 rounded-full outline-none px-6 md:px-8 py-4 md:py-6 ${className}`}
    >
      {startIcon && <span>{startIcon}</span>}
      <span>{children}</span>
      {endIcon && <span className="flex items-center">{endIcon}</span>}
    </button>
  );
};

export default Button;
