import React from "react";
import { motion } from "framer-motion";

const FadeInLeftRight = ({
  children,
  duration,
  delay,
  once,
  offset,
  className,
}) => {
  return (
    <motion.div
      transition={{ duration: duration || 1, delay: delay || 0.25 }}
      initial={{ opacity: 0, translateX: offset ? offset : 0 }}
      whileInView={{ opacity: 1, translateX: 0 }}
      viewport={{ once: once || true }}
      className={className}
    >
      {children}
    </motion.div>
  );
};

export default FadeInLeftRight;
