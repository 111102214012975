import React from "react";
import Container from "../../../Layout/Container";
// import ContactForm from "../../../Widgets/ContactForm";
import  ContactForm  from "../../../Widgets/ContactForm";
import { useTranslation } from 'react-i18next';

const FAQsContactForm = () => {
  const {t}=useTranslation()
  return (
    <section className="section faqs-contact-form-section flex flex-col items-center mt-20 md:mt-40">
      <Container maxWidth="md">
        <div>
          <div className="flex flex-col items-center">
            <h3 className="text-[28px] md:text-4xl font-normal leading-[44px]">
              {t("Still have a question?")}
            </h3>
            <p className="mt-10 font-xl font-normal flex flex-col md:flex-row items-center md:space-x-1.5">
              <span>{t("You can reach us anytime via")} </span>
              <a
                href="mailto:Support@paramountstudents.com"
                className="mt-1 md:mt-0"
              >
                Support@paramountstudents.com
              </a>
            </p>
          </div>
          <ContactForm className="mt-10" />
        </div>
      </Container>
    </section>
  );
};

export default FAQsContactForm;
