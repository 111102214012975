import React from "react";
import "./AboutHero.css";
import { useTranslation } from 'react-i18next';

const AboutHero = () => {
  const {t}=useTranslation();
  return (
    <section className="section about-hero-section bg-background1 pt-20 relative overflow-hidden">
      <div className="about-section-left-blur-shadow w-[478px] h-[478px] bg-[#5151FD]/[0.1] rounded-full hidden md:block md:absolute -left-[190px] bottom-0 blur-[75px]" />

      <div className="about-hero-section-container px-8 md:px-[90px] lg:px-[225px] xl:px-[300px] pt-[50px] md:pt-[100px] pb-8 sm:pb-[75px] md:pb-[150px]">
        <h2 className="about-hero-section-heading text-white font-bold text-3xl md:text-6xl text-center capitalize leading-[120%]">
          {t("We Build a Platform for Centralized Educational System")}
        </h2>
        <p className="text-sm md:text-xl leading-5 md:leading-8 font-normal text-[#F2F2F2] text-center mt-5">
          {t("At Paramount Students, we are true believers that every student should have access to a higher education that will help them shape their future and discover life-changing opportunities. Our goal is to help the next generation discover the numerous possibilities of universities and courses with us so we can make studying abroad effortless and seamless")}
        </p>
      </div>

      <div className="about-section-right-blur-shadow w-[478px] h-[478px] bg-[#FF5619]/[0.1] rounded-full absolute -top-[120px] -right-[190px] blur-[75px]" />
    </section>
  );
};

export default AboutHero;
