import {useEffect} from "react";

import FAQsContactForm from "../../../components/Docs/FAQs/FAQsContactForm";
import FAQsSection from "../../../components/Docs/FAQs/FAQsSection";
import DocsPagesLayout from "../../../components/Docs/Layout";
import Layout from "../../../components/Layout";
import setPageDescription from "../../../lib/utils/setPageDescription";

const FAQs = () => {
  useEffect(() => {
    document.title = 'Frequently Asked Questions | Paramount Students';
    setPageDescription('')
  }, []);

  return (
    <Layout transparentHeaderOnPhone>
      <main className="page documentation-page terms-and-conditions-page pt-0 md:pt-20">
        <DocsPagesLayout pageTitle="Frequently Asked Questions">
          <FAQsSection />
          <FAQsContactForm />
        </DocsPagesLayout>
      </main>
    </Layout>
  );
};

export default FAQs;
