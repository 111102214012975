import React from "react";
import DOMPurify from "dompurify";
import Container from "../../Layout/Container";
import { Disclosure, Transition } from "@headlessui/react";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { aboutFAQs } from "../../../lib/static/aboutFAQs";
import { useTranslation } from 'react-i18next';

const AboutFAQsSection = () => {
  const {t}=useTranslation();
  return (
    <section className="section about-section about-faqs-sections bg-background11 py-16 md:py-36 flex justify-center">
      <Container maxWidth="xl">
        <div className="about-faqs-content">
          <div className="about-faqs-header">
            <div className="flex items-center justify-start space-x-4">
              <h3 className="uppercase font-normal text-lg md:text-xl leading-4 md:leading-6 text-[#FFC400]">
                {t("Our business")}
              </h3>
              <div className="bg-[#FFC400] w-full max-w-[250px] flex-1 h-[1px]" />
            </div>

            <h2 className="mt-5 text-white capitalize font-bold text-4xl md:text-6xl leading-10 md:leading-[72px]">
              {t("What We Represent")}
            </h2>
          </div>

          <div className="about-faqs-disclosures mt-12 w-full">
            {aboutFAQs.map((faq,index) => (
              <div key={index} className="mt-4 md:mt-6 pb-10 md:pb-12 border-b-[0.5px] border-t-0 border-x-0 border-solid border-b-[#F4F4F4]/[0.25]">
                <Disclosure as="div" className="about-faq">
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex items-center w-full justify-between px-4 py-2 text-left text-sm text-white bg-transparent border-none">
                        <span className="font-normal text-xl md:text-3xl leading-10 text-white/[0.9]">
                          {t(faq.title)}
                        </span>
                        <div className="disclousure-icon bg-[#FFC400] rounded-full w-6 h-6 flex items-center justify-center cursor-pointer">
                          {open ? (
                            <AiOutlineMinus className="h-5 w-5 text-background11" />
                          ) : (
                            <AiOutlinePlus className="h-5 w-5 text-background11" />
                          )}
                        </div>
                      </Disclosure.Button>
                      <Transition
                        enter="transition duration-150 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-150 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                      >
                        <Disclosure.Panel className="px-4 pt-4 pb-2 text-[#F2F2F2]/[0.8] font-normal text-base md:text-lg leading-6 md:leading-9 mt-4 md:mt-8">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(t(faq.description)),
                            }}
                          />
                        </Disclosure.Panel>
                      </Transition>
                    </>
                  )}
                </Disclosure>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default AboutFAQsSection;
