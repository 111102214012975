import { useState, useMemo } from 'react';
import { getPaginationPages } from '../lib/utils/getPaginationPages';

const usePaginationPages = ({
  defaultCurrentPage = 1,
  defaultPostsPerPage = 6,
  defaultTotalPosts = 0,
  defaultPosts = [],
}) => {
  const [currentPage, setCurrentPage] = useState(defaultCurrentPage);
  const [postsPerPage] = useState(defaultPostsPerPage);
  const [totalPosts, setTotalPosts] = useState(defaultTotalPosts);
  const [posts, setPosts] = useState(defaultPosts);

  const paginationPages = useMemo(
    () => getPaginationPages(totalPosts, postsPerPage),
    [totalPosts, postsPerPage],
  );

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  const currentPosts = useMemo(
    () => posts.slice(indexOfFirstPost, indexOfLastPost),
    [indexOfFirstPost, indexOfLastPost, posts],
  );

  const paginate = (pageNumber) => {
    if (pageNumber < 1 || pageNumber > paginationPages.length) {
      return;
    }

    setCurrentPage(pageNumber);
  };

  const handleSetTotalPosts = (newTotalPosts) => {
    setTotalPosts(newTotalPosts);
  };

  const handleSetPosts = (newPosts) => {
    setPosts(newPosts);
  };

  return {
    currentPage,
    paginationPages,
    paginate,
    indexOfFirstPost,
    indexOfLastPost,
    handleSetTotalPosts,
    handleSetPosts,
    currentPosts,
  };
};

export { usePaginationPages };
