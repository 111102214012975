import AboutCarousalSlide1Banner from "../../assets/Images/about-carousal-slide-1-banner.jpg";
import AboutCarousalSlide2Banner from "../../assets/Images/about-carousal-slide-2-banner.jpg";
import contentImage3 from '../../assets/Images/ourStory.png';

export const aboutCarousalSlides = [
  {
    title: 'Our Story',
    description: `We at Paramount Students are dedicated to helping students reach their full potential. Our story is driven by the belief that every student should have access to tertiary education and life-changing experiences that will shape their future. We understand the challenges and excitement of studying abroad, and we're here to make the process seamless. Our dedicated team of professionals offers customized guidance and support to each student along the way to ensure they find the university program that best suits their educational and career objectives`,
    bannerImage: contentImage3,
    alttext: "a man on a virtual meeting",
  },
  {
    title: "What is paramount students?",
    description: `Paramount Students was created to allow overseas students apply directly to their favorite universities. We provide a platform for high schools to submit their students' credentials and for students to communicate directly with the institution of their choice`,
    bannerImage: AboutCarousalSlide1Banner,
    alttext: "a group of people sitting around a table with laptops",
  },
  {
    title: "Our Mission",
    description: `The mission of Paramount Students is to develop a management system that assures a sophisticated student recruitment process. This ensures high-quality services that will help high schools create a verified student base and allow universities to save 50% of their time answering questions`,
    bannerImage: AboutCarousalSlide2Banner,
    alttext: "A meeting of people sitting around a table",
  },
];
