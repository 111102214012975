import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
//import { useTranslation } from 'react-i18next';

import { ReactComponent as HamburgerIcon } from '../../../assets/Icon/hamburger.svg';
import { ReactComponent as LogoBlack } from '../../../assets/Icon/paramount-students-logo.svg';
import { ReactComponent as LogoWhite } from '../../../assets/Icon/paramount-students-white-logo.svg';

import { headerNavigation } from '../../../lib/static/headerNavigation';
import NavigationLink from './NavigationLink';
import { Popover } from '@headlessui/react';
import MobileMenu from './MobileMenu';
import { useState } from 'react';
import { useEffect } from 'react';

import './Header.css';
import GoogleTranslate from '../../Translate';

const Header = ({ transparentOnPhone, mobileMenuIconClassName }) => {
  const location = useLocation();
  const [mobileMenuOpen, setMobileMenuOpen] = useState();

  const transparentHeader = transparentOnPhone;
  /* const { t } = useTranslation(); */



  const handleMobileMenuOpen = () => {
    setMobileMenuOpen(true);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuOpen(false);
  };

  useEffect(() => {
    if (mobileMenuOpen) {
      document.body.style.height = 'unset';
    } else {
      document.body.style.height = '100vh';
    }
  }, [mobileMenuOpen]);

  return (
    <Popover className="relative">
      <header
        id="global-header"
        className={`header w-full transition-colors h-20 duration-200 z-10 ${
          transparentHeader
            ? 'bg-transparent absolute top-0'
            : 'bg-white shadow-header fixed top-0 left-0'
        }`}
      >
        <div className="header-container">
          <div className="header-main">
            <div className="header-main-content flex justify-between items-center min-h-[80px] px-4 md:px-6 lg:px-[68px]">
              <div className="header-main-left">
                <div className="header-logo">
                  <Link to="/">
                    {/* always on desktop */}
                    <LogoBlack
                      id="header-logo-black"
                      className={`header-logo-black ${
                        location.pathname !== '/' && 'hidden'
                      } md:block w-[110px] h-10`}
                    />
                    <LogoWhite
                      id="header-logo-white"
                      className={`header-logo-white ${
                        location.pathname === '/' ? 'hidden' : 'block'
                      } md:hidden w-[110px] h-10`}
                    />
                  </Link>
                </div>
              </div>
              <div className="header-main-righ flex items-center">
                <nav className="header-main-navigation hidden md:block md:mx-2 lg:mx-4 xl:mx-8">
                  <ul className="flex items-center md:gap-3 lg:gap-5 xl:gap-8 list-none text-center">
                    {headerNavigation.map((navItem, i) => (
                      <NavigationLink
                        key={navItem.href}
                        href={navItem.href}
                        button={navItem?.button}
                        external={navItem?.external}
                        className="navigateLinks"
                      >
                        {navItem.label}
                      </NavigationLink>
                    ))}
                  </ul>
                </nav>
                {/* mobile menu hamburger icon */}
                <div className="md:hidden">
                  <Popover.Button
                    className="outline-none border-none bg-transparent cursor-pointer hover:opacity-90 transition-opacity duration-150"
                    onClick={handleMobileMenuOpen}
                  >
                    <span className="sr-only">Open menu</span>
                    <HamburgerIcon
                      id="header-menu-icon"
                      className={`w-6 fill-white transition-colors duration-100 ${mobileMenuIconClassName}`}
                      aria-hidden="true"
                    />
                  </Popover.Button>
                </div>

                {/* language cta - not needed for now - always hidden */}
                {/* Currently visible - based on figma design */}
                {/* <div className="hidden lang-widget cursor-pointer md:flex items-center justify-center bg-[#f4f4f4] rounded-full space-x-2 p-2 py-4 px-3 w-20">
                  <p className="font-medium text-[18px] uppercase">EN</p>
                  <img src={BritianFlag} alt="flag" />
                </div> */}
              {/*   <Dropdown
                  className="hidden md:flex cursor-pointer gap-2 justify-between items-center mr-[2.62rem] rounded-full border-white w-20 h-9font-bold bg-[#F4F4F4] text-black"
                  menu={{
                    items,
                    selectable: true,
                    defaultSelectedKeys: [language],
                    onClick,
                  }}
                  placement="bottom"
                  arrow
                >
                  <Button className="text-white flex gap-1 justify-center ">
                    {language}
                    {flag !== '' ? (
                      <img
                        className="w-[1.31rem] h-4 ml-2"
                        src={flag.image}
                        alt=""
                      />
                    ) : (
                      <img
                        className="w-[1.31rem] h-4 ml-2"
                        src={require('../../../assets/Icon/Flags.webp')}
                        alt=""
                      />
                    )}
                  </Button>
                </Dropdown> */}
                <GoogleTranslate customId="google_translate_element" />
              </div>
            </div>
          </div>
        </div>
      </header>
      <MobileMenu onClose={handleMobileMenuClose} />
    </Popover>
  );
};

Header.propTypes = {
  transparentOnPhone: PropTypes.bool,
  mobileMenuIconClassName: PropTypes.string,
};

export default Header;
