import React from "react";
import PropTypes from "prop-types";
import "./DocsHero.css";
import { useTranslation } from 'react-i18next';

const HeroSection = ({ title }) => {
  const {t}=useTranslation()
  return (
    <section className="section hero-section docs-pages-hero-section flex flex-col items-center justify-center min-h-[276px] bg-background1">
      <h1 className="heading docs-pages-title text-center text-4xl md:text-6xl mt-20 md:mt-0 font-bold leading-10 md:leading-[79px]">
        {t(title)}
      </h1>
    </section>
  );
};

HeroSection.propTypes = {
  title: PropTypes.string,
};

export default HeroSection;
