import React, { useCallback, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import { MdArrowBack } from "react-icons/md";
import FadeWhileInView from "../../Animations/FadeWhileInView";

import UniversitySlide from "./UniversitySlide";
import virtualTourUniversities from "../../../lib/static/virtualTourUniversities";
import "./UniVirtualTour.css";
import { useTranslation } from 'react-i18next';

SwiperCore.use([Navigation]);

const UniVirtualTour = () => {
  const { t } = useTranslation();
  const sliderRef = useRef(null);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  return (
    <section className="section virtual-tour-section mt-24 py-10 md:py-[120px] overflow-x-hidden">
      <div className="virtual-tour-content w-full ml-0 md:ml-10 lg:ml-16 xl:ml-20">
        <div className="virtual-tour-header px-4 sm:px-6">
          <div className="flex items-center justify-start space-x-4">
            <h3 className="uppercase font-normal text-lg md:text-xl leading-4 md:leading-6 text-[#F2F2F2]">
              {t("Universities virtual Tour")}
            </h3>
            <div className="bg-[#F2F2F2] w-full max-w-[250px] flex-1 h-[1px]" />
          </div>

          <h2 className="mt-5 text-white capitalize font-bold text-[28px] leading-8 md:text-4xl2 md:leading-[48px] max-w-xl">
            {t("Join a valuable resource built for decision makers")}
          </h2>
        </div>

        <div className="virtual-tour-slider mt-8 md:mt-16 flex items-center space-x-0 md:space-x-5 px-4 sm:px-6 md:px-0">
          <div className="virtual-tours-swiper-carousal-navigation hidden md:block">
            <FadeWhileInView>
              <div
                className="about-carousal-forward bg-[#B3FC47] text-text-primary border border-solid shadow cursor-pointer rounded-full w-16 h-16 flex items-center justify-center hover:opacity-90 transition-opacity duration-150"
                onClick={handleNext}
              >
                <MdArrowBack className="w-8 h-8 text-text-primary" />
              </div>
            </FadeWhileInView>
          </div>
          <Swiper
            slidesPerView={"auto"}
            spaceBetween={30}
            ref={sliderRef}
            loop={true}
            className="virtual-tour-swiper-carousal w-full rounded-[20px] md:rounded-3xl"
          >
            {virtualTourUniversities.map((university, i) => (
              <SwiperSlide>
                <UniversitySlide key={i} university={university} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default UniVirtualTour;
