import React from "react";
import Applinsk from "./Applinsk";

const ParamountStudentsFeatures = () => {
  return (
    <div
      className="py-10 relative xl:py-20 pl-4 md:pl-0"
      style={{
        background:
          "radial-gradient(circle at 75% 50%, rgba(83, 217, 219) 0%, rgba(166, 242, 243) 10%, rgba(220, 255, 212 ) 20%, rgba(255,255,255,0) 30%)",
      }}>
      <div className="flex flex-row justify-center items-center">
        <p className="font-recoleta font-bold text-gray-900 text-2xl lg:text-6xl">
          Paramount Students Features
        </p>
      </div>
      <div className="flex flex-row items-center pt-10 w-full mt-10 h-ful pb-10">
        <div className="w-1/2 flex flex-col items-center h-full">
          <div className="flex flex-col  w-[200px] sm:w-[300px] md:w-[400px] lg:w-[560px] transition-all duration-500 ease-in-out lg:space-y-10">
            <div className="space-y-2">
              <h4 className="font-bold font-recoleta sm:text-xl md:text-2xl lg:text-3xl transition-all duration-500 ease-in-out">
                Scholarship Opportunities
              </h4>
              <p className="font-graphik text-[10px] sm:text-[14px] md:text-[16px] lg:text-[24px] transition-all duration-500 ease-in-out">
                Access exclusive scholarship opportunities and financial aid
                resources to help fund your education.
              </p>
            </div>
            <div className="w-full mt-2 h-auto flex flex-row ">
              <Applinsk />
            </div>
          </div>
        </div>

        <div
          className="w-1/2 flex flex-col items-center justify-center relative"
          style={{
            background:
              "radial-gradient(circle at 30% 50%, rgba(191, 252, 165) 0%, rgba(212, 255, 166) 10%, rgba(222, 255, 186) 20%, rgba(255,255,255,0) 30%)",
          }}>
          <div className="transition-all duration-500 ease-in-out h-full">
            <img
              src="/mobileapp/001-copy.png"
              alt="effortless"
              className="h-[250px] w-[120px] sm:h-[300px] sm:w-[180px] md:h-[500px] md:w-[270px] lg:h-[600px] lg:w-[350px] xl:w-[380px]transition-all duration-500 ease-in-out"
            />
          </div>
        </div>
      </div>
      <img
        src="/mobileapp/start.png"
        alt="I"
        className="w-4 md:w-10 absolute rotate-12 top-5 right-5 sm:top-10 sm:right-10 xl:top-16 xl:right-72"
      />
    </div>
  );
};

export default ParamountStudentsFeatures;
