import React, { useState } from "react";

const FloatingLabelInput = ({
  value,
  onValueChange,
  labelText,
  classname,
  type,
  required = true,
}) => {
  const [inputFocused, setInputFocused] = useState(false);

  const handleInputFocus = () => {
    setInputFocused(true);
  };

  const handleInputBlur = () => {
    if (value.trim() === "") {
      setInputFocused(false);
    }
  };

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    onValueChange(newValue);
  };

  // Conditionally render an input or textarea based on the type prop
  const InputElement =
    type === "textarea" ? (
      <textarea
        style={{
          border: "none",
          borderBottom: "1px solid #444",
          boxShadow: "none",
        }}
        className={`md:w-full p-3 mb-[-4px] text-gray-400  focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 ${
          inputFocused ? "border-b-1 border-blue-500 border-0 shadow-none" : ""
        }`}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        onChange={handleInputChange}
        value={value}
        required={required}
      />
    ) : (
      <input
        type={type}
        style={{
          border: "none",
          borderBottom: "1px solid #444",
          boxShadow: "none",
        }}
        className={`w-full p-3 text-gray-400  focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 ${
          inputFocused ? "border-b-1 border-blue-500 border-0 shadow-none" : ""
        }`}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        onChange={handleInputChange}
        value={value}
        required={required}
      />
    );

  return (
    <div className={classname}>
      <div className={"relative w-full"}>
        {InputElement}
        <label
          style={{ pointerEvents: "none" }}
          className={`absolute top-[10px]  px-2 left-[-5px] text-[16px] text-gray-500 transition-all duration-300 ease-in-out transform origin-left ${
            inputFocused || value.trim() !== ""
              ? "scale-75 -translate-y-5  text-blue-600"
              : ""
          }`}>
          {labelText}
        </label>
        <div
          className={`absolute bottom-0 left-0 h-0.5 w-full transition-all duration-300 ease-in-out transform origin-left scale-x-0 ${
            inputFocused || value.trim() !== "" ? "scale-x-100 bg-blue-600" : ""
          }`}></div>
      </div>
      <div style={{ height: "6px" }}></div>
    </div>
  );
};

export default FloatingLabelInput;
