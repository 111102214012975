import React, { useEffect } from "react";
import Layout from "../../components/Layout";

import SmoothScroll from "../../components/App/SmoothScroll";
//import UniversityRequestForm from "../../components/Modals/UniversityRequest";
//import SchoolInfoForm from "../../components/Modals/HighRequest";

const DemoPage = () => {
  useEffect(() => {
    document.title = "Demo Page";
  }, []);

  return (
    <Layout transparentHeaderOnPhone>
      <SmoothScroll />
      <main className="page about-page">
        {/* <UniversityRequestForm />    */}     
        {/* <SchoolInfoForm /> */}
      </main>
    </Layout>
  );
};

export default DemoPage;
