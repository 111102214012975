import React from "react";

const Applinsk = () => {
  return (
    <div className="flex flex-row">
      <a
        href="https://play.google.com/store/apps/details?id=com.paramountstudents&hl=en"
        target="_blank"
        rel="noreferrer">
        <img
          src="/mobileapp/play-store.png"
          alt="playstore"
          className="w-[90px] h-[30px] sm:w-[150px]  md:w-[200px] lg:w-[280px] lg:h-[80px] transition-transform transform hover:scale-105 hover:shadow-lg duration-500 ease-in-out cursor-pointer"
        />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=com.paramountstudents&hl=en"
        target="_blank"
        rel="noreferrer">
        <img
          src="/mobileapp/app-store.png"
          alt="appstore"
          className="w-[90px] h-[30px] sm:w-[150px] md:w-[200px] lg:w-[280px] lg:h-[80px] transition-transform transform hover:scale-105 hover:shadow-lg duration-500 ease-in-out cursor-pointer"
        />
      </a>
    </div>
  );
};

export default Applinsk;
