import Header from "./CustomHeader";
import Features from "./Features";
import Services from "./Services";
import Universities from "./Universities";
import Landing from "./Landing";
import CustomFooter from "./CustomFooter";
import MakingEffortless from "./MakingEffortless";
import ParamountStudentsFeatures from "./ParamountStudentsFeatures";
import DownloadApp from "./DownloadApp";

const MobileAppLandingPage = () => {
  return (
    <div className="">
      <Header />
      <Landing />
      <Universities />
      <Services />
      <Features />
      <MakingEffortless />
      <ParamountStudentsFeatures />
      <DownloadApp />
      <CustomFooter />
    </div>
  );
};

export default MobileAppLandingPage;
