import React from "react";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa";

const SocialLink = ({ className, socialIcon, socialLink, socialPlatform }) => {
  const socialIcons = {
    Facebook: <FaFacebook className="text-text-primary  text-[20px]" />,
    Twitter: <FaTwitter className="text-text-primary   text-[20px]" />,
    Instagram: (
      <FaInstagram className="text-text-primary   text-[20px]" />
    ),
    LinkedIn: <FaLinkedin className="text-text-primary  text-md text-[20px]" />,
  };

  return (
    <a
      href={socialLink || "#"}
      target="_blank"
      rel="noreferrer"
      className={className}
    >
      <div role="button" className="flex items-center space-x-2">
        <span>{socialIcons[socialPlatform]}</span>
        <span className="text-text-primary text-xs md:text-sm lg:text-base leading-4 hover:opacity-90 transition-opacity duration-150">
          {/* {socialPlatform} */}
        </span>
      </div>
    </a>
  );
};

export default SocialLink;
