const getPaginationPages = (totalPosts, postsPerPage) => {
  const totalPages = Math.ceil(totalPosts / postsPerPage);
  const pages = [];
  for (let i = 1; i <= totalPages; i++) {
    pages.push(i);
  }
  return pages;
};

export { getPaginationPages };
