import { useState } from 'react';
import { MdArrowBack, MdArrowForward } from 'react-icons/md';

const PaginationPages = ({ data, onPageChange }) => {

  const handlePageChange = (url) => {
    onPageChange(url);
  };

  return (
    <nav aria-label="Pagination" className="flex justify-end">
      <ul className="flex gap-2 list-none">
        {data?.links?.map((link, index) => (
          <li key={index}>
            <button
              className="button text-sm p-1 text-[#828282] transition-colors flex items-center rounded hover:text-black hover:bg-[#f1f1f1]"
              type="button"
              onClick={() => handlePageChange(link.url)}
              disabled={link.url === null}
              aria-disabled={link.url === null}
              aria-label={link.label}
              tabIndex={index}
            >
              {/* <MdArrowBack className="w-4 mr-1" /> */}
              <span dangerouslySetInnerHTML={{ __html: link.label }} />
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default PaginationPages;
