
import React, { useRef, useState } from "react";
import { MdAttachFile } from "react-icons/md";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    subject: "",
    issue: ""
  })
  const form=useRef()
   const handleSubmit=(e)=>{
    e.preventDefault()
    
    console.log(formData);
    window.Email.send({
    SecureToken:"2a7d49f8-a156-44d5-9d23-3bb9388d3938",
      To : 'mstfrs68@gmail.com',
      From : "amazonuser38682@gmail.com",
      Subject : "formData.subject",
      Body : "formData.issue"
  }).then((success)=>{
  alert("message sent successfully")
})
.catch((error)=>{
  alert(error)
})
   }
  return (
    <div>
      <form onSubmit={handleSubmit} className="" method="POST" ref={form}>
        <div className="flex flex-col">
          <label
            className="text-sm font-normal leading-5"
            htmlFor="contact-form-subject"
          >
            Subject
          </label>
          <input
          onChange={(e)=>setFormData({...formData, subject: e.target.value})}
          value={formData.subject}
            type="text"
            id="contact-form-subject"
            placeholder="Please write your subject of issue"
            className="border border-solid border-[#D0D5DD] placeholder:text-[#98A2B3] rounded-lg p-2.5 mt-1 outline-[#98A2B3] "
          />
        </div>
        <div className="flex flex-col mt-[22px]">
          <label
            className="text-sm font-normal leading-5"
            htmlFor="contact-form-message"
          >
            How can we help?
          </label>
          <textarea
            onChange={(e)=>setFormData({...formData, issue: e.target.value})}
            value={formData.issue}
            rows={7}
            id="contact-form-message"
            placeholder="Tell us a little about the issue"
            className="border border-solid font-normal text-base border-[#D0D5DD] placeholder:text-[#98A2B3] placeholder:text-sm placeholder:rounded-lg p-2.5 mt-1 outline-[#98A2B3] "
          />
        </div>
        <div className="flex flex-col md:flex-row items-center justify-start md:justify-between space-y-4 md:space-y-0 mt-[22px]">
          <div className="bg-[#EAECF0] text-[#667085] flex items-center justify-center space-x-2 border border-solid border-gray-300 rounded-lg text-base font-medium leading-6 w-40 py-2 cursor-pointer shadow-sm hover:opacity-90 transition-colors duration-150">
            <span>Attach File</span>
            <span className="translate-y-0.5">
              <MdAttachFile />
            </span>
          </div>
          <input
            type="submit"
            value="Submit"
            className="bg-btn-background-1 rounded-lg text-base font-medium leading-6 border-none outline-none w-40 py-2 cursor-pointer shadow-sm hover:opacity-90 transition-colors duration-150"
          />
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
