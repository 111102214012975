
import { createSlice } from '@reduxjs/toolkit';

const loadingSlice=createSlice({
    name:"loading",
    initialState:{
        status:false
    },
    reducers:{
        isloading:(state,action)=>{
            state.status=action.payload
            console.log(state.status)
            
        }

    }
})

export const {isloading}=loadingSlice.actions
export default loadingSlice.reducer