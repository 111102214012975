import React from "react";
import Button from "../../../Generic/Button";
import { ReactComponent as PlayIcon } from "../../../../assets/Icon/play-icon.svg";

const UniversitySlide = ({ university }) => {
  return (
    <div className="virtual-tour-uni-slide rounded-tr-[20px] md:rounded-tr-3xl rounded-tl-[20px] md:rounded-tl-3xl overflow-hidden">
      <div className="relative">
        <div className="virtual-tour-uni-image">
          <img
            src={university.image}
            alt="Uskudar university"
            className="object-cover w-full rounded-br-3xl rounded-bl-3xl"
          />
        </div>
        <div className="virtual-tour-uni-info w-full absolute bottom-0 left-0 flex flex-col">
          <div className="flex relative">
            <div className="bg-black rounded-tr-2xl md:rounded-tr-3xl text-white font-normal text-xs leading-4 md:text-2xl md:leading-7 px-4 md:px-8 py-1 sm:py-2 border border-b-0 border-solid border-btn-background-2 absolute left-0 -bottom-[2px]">
              {university.location}
            </div>
          </div>
          <div className="bg-black text-white flex items-center justify-between h-16 sm:h-20 md:h-24 lg:h-28 px-3 md:px-8 lg:px-10 border border-solid border-btn-background-2 mb-1 rounded-br-[20px] rounded-bl-[20px] md:rounded-br-3xl md:rounded-bl-3xl overflow-hidden">
            <div className="flex items-start">
              <h3 className="font-bold text-base sm:text-2xl md:text-3xl lg:text-4xl2 leading-6 md:leading-10">
                {university.university}
              </h3>
            </div>
            <div>
              <a href={university.link}>
              <Button
                endIcon={<PlayIcon className="h-4 md:h-8" />}
                className="bg-btn-background-2 text-[11px] px-1.5 !py-2.5 md:!py-6 !space-x-0 md:!space-x-3 border-none hover:opacity-95 transition-opacity duration-150"
              >
                Watch Virtual Tour
              </Button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UniversitySlide;
