import React from "react";
import Container from "../../Layout/Container";
import AboutMentorsImage1 from "../../../assets/Images/about-mentor-image-1.webp";
import AboutMentorsImage2 from "../../../assets/Images/about-mentor-image-2.webp";
import AboutMentorsImage3 from "../../../assets/Images/about-mentor-image-3.webp";
import AboutMentorsImage4 from "../../../assets/Images/about-mentor-image-4.webp";
import FadeWhileInView from "../../Animations/FadeWhileInView";
import { useTranslation } from 'react-i18next';

const AboutMentorsSection = () => {
  const {t}=useTranslation();
  return (
    <section className="section about-section mentors-section flex justify-center bg-background3 py-12 md:py-40">
      <Container maxWidth="xl">
        <div className="mentors-section-content flex flex-col md:flex-row space-y-8 md:space-y-0 md:space-x-8 lg:space-x-20 xl:space-x-36">
          <div className="mentors-left flex-1 md:flex-[0.5] md:pt-4">
            <div className="mentors-left-content">
              <h2 className="text-text-primary font-medium md:font-bold text-[28px] leading-10 md:text-[40px] md:leading-[52px]">
                {t("Made Possible By Our International Mentors")}
              </h2>
              <p className="text-sm md:text-lg leading-4 md:leading-8 text-text-dim mt-5 md:mt-10">
                {t("Without the guidance of our international team of mentors from different countries who provided helpful knowledge of the educational system, study abroad insights and technology, Paramount Students could not have developed such a fantastic platform. With their demonstrated competence, they helped us put the ideas we had into practice by sharing the knowledge they have acquired at their various Universities and workplaces")}
              </p>
            </div>
          </div>
          <div className="mentors-right flex-1 md:flex-[0.5]">
            <div className="mentors-right-gallery relative min-h-[300px] md:min-h-[350px] xl:min-h-[375px] w-full sm:w-3/5 mx-auto md:w-full">
              <FadeWhileInView>
                <img
                  src={AboutMentorsImage1}
                  className="w-32 lg:w-40 xl:w-44 absolute top-[13%] sm:top-[10%] md:top-[16.5%] lg:top-[12.5%] left-0 md:left-[20%]"
                  alt="Mentor 1"
                />
              </FadeWhileInView>
              <FadeWhileInView delay={0.75}>
                <img
                  src={AboutMentorsImage2}
                  className="w-24 lg:w-28 absolute -top-4 right-[33%] md:right-[25%]"
                  alt="Mentor 2"
                />
              </FadeWhileInView>
              <FadeWhileInView delay={1}>
                <img
                  src={AboutMentorsImage3}
                  className="w-32 absolute bottom-0 right-[36%] lg:right-[30%] xl:right-[36%]"
                  alt="Mentor 3"
                />
              </FadeWhileInView>
              <FadeWhileInView delay={1.25}>
                <img
                  src={AboutMentorsImage4}
                  className="w-32 lg:w-36 xl:w-44 absolute right-0 top-1/2 -translate-y-1/2"
                  alt="Mentor 4"
                />
              </FadeWhileInView>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default AboutMentorsSection;
