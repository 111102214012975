import React from "react";
import { useTranslation } from "react-i18next";
import Container from "../../../Layout/Container";
import DocumentationItem from "../../Layout/DocumentationItem";

const DocumentationContent = () => {
  const classes = { DocumentationItem: "mt-6 md:mt-12" };
  const { t } = useTranslation();
  return (
    <section className="section privacy-policy-section flex flex-col items-center">
      <Container maxWidth="lg">
        <div className="border-solid border-l-2 border-y-0 border-r-0 border-l-[#4F4F4F]">
          <DocumentationItem>
            <p>              
              {t(
                "Welcome to the Paramount Students Privacy Policy. This Privacy Policy explains how we collect, use, disclose, and safeguard your personal information when you visit our website or use our services. Please read this policy carefully to understand how we handle your information. By accessing or using the Paramount Students Website, you agree to the terms of this Privacy Policy."
              )}
            </p>
          </DocumentationItem>
          <DocumentationItem
            title={t("Information We Collect")}
            className={classes["DocumentationItem"]}
          >
            <p>
              {t(
                "We may collect personal information that you provide directly to us, including but not limited to:"
              )}
            </p>
            <ol type="a" className="mt-4 pl-5">
              <li>{t("a. Contact Information: Your name, email address, postal address, phone number, or any other contact information you provide when you sign up for an account, subscribe to our newsletter, or communicate with us.")}              </li>
              <li>{t("b. Account Information: Usernames, passwords, or any other account credentials that you use to access our website")}            </li>
              <li>{t("c. Payment Information: If applicable, we may collect payment card information, billing address, and other payment details when you make a purchase.")}             </li>
              <li>{t("d. Usage Data: Information about how you use and interact with our website, including your IP address, browser type, device type, referring/exit pages, and timestamps.")}              </li>
              <li>{t("e. Cookies and Similar Technologies: We may use cookies, web beacons, and similar technologies to collect information about your activities on our website and enhance your user experience. You can control the use of cookies through your browser settings.")}</li>
            </ol>
          </DocumentationItem>
          <DocumentationItem
            title={t("How We Use Your Information")}
            className={classes["DocumentationItem"]}
          >
            <p>
              {t(
                "We may use your personal information for various purposes, including but not limited to:"
              )}
            </p>
            <ol type="a" className="mt-4 pl-5">
              <li>{t("a. Providing and Improving Services: To deliver the products, services, and features you request and to enhance and personalize your experience on our website.")}</li>
              <li>{t("b. Communication: To respond to your inquiries, send transactional emails, updates, and important notices about our service.")}</li>
              <li>{t("c. Analytics: To analyze and understand the usage patterns of our website, gather demographic information, and improve our offerings.")}</li>
              <li>{t("d. Marketing and Advertising: To send you promotional materials, offers, and newsletters related to our products and services unless you opt-out of such communications.")}</li>
              <li>{t("e. Compliance: To comply with applicable laws, regulations, and legal processes.")}</li>
            </ol>
          </DocumentationItem>

          <DocumentationItem
          title={t("How We Share Your Information")}
          className={classes["DocumentationItem"]}
        >
          <p>
            {t(
              "We do not sell, rent, or trade your personal information to third parties for marketing purposes. However, we may share your information in the following circumstances:"
            )}
          </p>
          <ol type="a" className="mt-4 pl-5">
            <li>{t("a. With Service Providers: We may share your information with trusted third-party service providers who help us with website hosting, data analysis, payment processing, and other services necessary for our operations.")}</li>
            <li>{t("b. With Affiliates: We may share information with our affiliates, subsidiaries, or parent companies for internal business purposes.")}</li>
            <li>{t("c. For Legal Reasons: We may disclose your information to comply with applicable laws, regulations, legal processes, or to protect our rights, privacy, safety, or property.")}</li>
            <li>{t("d. With Your Consent: We may share your information with third parties if you provide explicit consent.")}</li>
            <li>{t("e. Compliance: To comply with applicable laws, regulations, and legal processes.")}</li>
          </ol>
        </DocumentationItem>

        <DocumentationItem
          title={t("Your Choices and Rights")}
          className={classes["DocumentationItem"]}
        >        
          <ol type="a" className="mt-4 pl-5">
            <li>{t("a. Access and Correction: You can access and update certain personal information through your account settings.")}</li>
            <li>{t("b. Marketing Communication: You can opt-out of receiving marketing communications from us by following the unsubscribe instructions in our emails or contacting us directly.")}</li>
            <li>{t("c. Cookies and Similar Technologies: You can manage cookies and similar technologies through your browser settings.")}</li>
            <li>{t("d. Do Not Track Signals: Our website does not respond to Do Not Track (DNT) signals.")}</li>
          </ol>
        </DocumentationItem>

          <DocumentationItem
            title={t("Security")}
            className={classes["DocumentationItem"]}
          >
            <p className="pl-4 md:pl-8">
                {t("We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, please note that no method of transmission over the internet or electronic storage is entirely secure.:")}              
            </p>
          </DocumentationItem>
          <DocumentationItem
            title={t("Changes to This Privacy Policy")}
            className={classes["DocumentationItem"]}
          >
            <p>
              {t("We may update this Privacy Policy from time to time, and any changes will be effective upon posting the revised policy on our website. Please review this policy periodically.")}
            </p>
          </DocumentationItem>
          {/* <DocumentationItem
            title={t("YOUR CHOICES ABOUT YOUR PERSONAL DATA")}
            className={classes["DocumentationItem"]}
          >
            <p>
              {t("You can create a profile on our site, including personal information. To ensure its continued accuracy, you may update or correct this information at any time. You can also have your profile deleted by contacting us at ")}{" "}
              <a href="mailto:Hi@Paramountstudent.com">
                Hi@Paramountstudent.com
              </a>
              .
            </p>
            <p className="mt-4">
              {t("Furthermore, Paramount Students gives you choices about how we use the personal information you have provided us. You can, for example, sign up to receive newsletters or email notifications about specific products or services. You can unsubscribe from these optional communications at any time by clicking the 'Unsubscribe' link in the email's footer. Please keep in mind that some emails, such as account password resets or legal notices, will continue to be sent regardless of unsubscribe settings. If you would like more information about your legal rights under applicable law, or if you would like to exercise any of them, please contact the Paramount Students data protection officer at ")}{" "}
              <a href="mailto:Hi@Paramountstudent.com">
                Hi@Paramountstudent.com
              </a>{" "}
              {t("any time. Your local laws (e.g., in the EU) may permit you to request that we:")}
            </p>
            <ul className="mt-4 pl-4 md:pl-8">
              <li>
                {t("provide you with access to and/or a copy of certain information we have on file for you;")}
              </li>
              <li>
                {t("Prevent the processing of your information for direct marketing purposes (including any profiling-based direct marketing processing);")}
              </li>
              <li>
                {t("update out-of-date or incorrect information; delete certain information we hold about you;")}{" "}
              </li>
              <li>
                {t("limit how we process and disclose certain of your information;")}{" "}
              </li>
              <li>
                {t("transfer your information to a third-party provider of services;")}{" "}
              </li>
              <li>
                {t("revoke your consent for the processing of your information.")}
              </li>
            </ul>
            <p className="mt-4">
              {t("We will consider all requests and respond within the time frame specified by applicable law. Please keep in mind that certain information may be exempt from such requests in certain circumstances, such as if we need to continue processing your data for our legitimate interests or to comply with a legal obligation. Before responding to your request, we may ask for additional information to confirm your identity.")}
            </p>
            <p className="mt-4">
              {t("Please contact us if you wish to discuss any aspect of our processing of your personal data at")}{" "}
              <a href="mailto:Hi@Paramountstudent.com">
                Hi@Paramountstudent.com
              </a>
            </p>
          </DocumentationItem>
          <DocumentationItem
            title={t("DATA STORAGE AND RETENTION")}
            className={classes["DocumentationItem"]}
          >
            <p>
              {t("Your personal data is stored on Paramount students' servers as well as the servers of the cloud-based database management services that Paramount students uses, both of which are located in the United States. Unless required or permitted by applicable law, the Paramount students retains data for the appropriate amount of time to fulfill the purposes set forth in this Notice. More information on where and how long your personal data is stored can be found here, please contact Paramount students’ data protection officer at Hi@Paramountstudent.com.")}
            </p>
          </DocumentationItem>
          <DocumentationItem
            title={t("CHILDREN’S PRIVACY")}
            className={classes["DocumentationItem"]}
          >
            <p>
              {t("Our Site is not intended for use by children under the age of 13 in the United States or under the age of 16 in the European Union, and we do not knowingly collect personal information from such children.")}
            </p>
          </DocumentationItem>
          <DocumentationItem
            title={t("LINKS")}
            className={classes["DocumentationItem"]}
          >
            <p>
             {t("This Privacy Notice only applies to information collected by this Site and does not apply to other websites to which you may link from here. The privacy practices of other websites are not the responsibility of Paramount Students. Please be aware that when you leave this Site, you should read the privacy policies of any other websites that request personal information from you.")}
            </p>
          </DocumentationItem>
          <DocumentationItem
            title={t("CHANGE NOTIFICATION")}
            className={classes["DocumentationItem"]}
          >
            <p>
             {t("Any changes to this Privacy Notice will be made available on this website. Unless we receive your consent to change our practices with respect to previously collected information, any information we collect from you will be used in accordance with the version of this Privacy Notice in effect at the time the information was collected.")}
            </p>
            <p className="mt-4">
              {t("Please contact our data privacy officer if you require any additional information or have any comments about our Privacy Notice at")}{" "}
              <a href="mailto:Hi@Paramountstudent.com">
                Hi@Paramountstudent.com
              </a>
            </p>
            <p className="mt-4">Fulya, Fulya,</p>
            <p className="mt-1">
              Fulya Mahallesi Yeşilçimen Sokak Polat Tower Bağımsız
            </p>
            <p className="mt-1">Bölüm 12/430, 34394</p>
            <p className="mt-1">Şişli/İstanbul,</p>
            <p className="mt-1">Turkey</p>
          </DocumentationItem> */}
        </div>
      </Container>
    </section>
  );
};

export default DocumentationContent;
