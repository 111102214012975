/* import blog1 from '../../../assets/Images/blog1.png';
import blog2 from '../../../assets/Images/blog2.png';
import blog3 from '../../../assets/Images/blog3.png';
import blog4 from '../../../assets/Images/blog4.png';
import blog5 from '../../../assets/Images/blog5.png'; */
import { useEffect, useState } from 'react';
import rightArrow from '../../../assets/Icon/VectorR.svg';
import './Blog.css';
//import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { fetchData } from '../../../api/axios';
//import { useMediaQuery } from "react-responsive"; // Import media query hook

const Blog = () => {
  const { t } = useTranslation();
  const [posts, setPosts] = useState([]);

  useEffect(() => {    
    fetchData('/api/blog', false)
      .then((data) => {
        setPosts(data.data.data);
      })
      .catch((error) => {
        console.log('Error fetching blog content:', error);
      });
  }, []);

  return (
    <div className="my-20 md:my-28">
      <div>
        <div className="title text-center">
          <h2 className="heading md:text-4xl">{t('Blogs and Articles')}</h2>
        </div>
        <p className="text-sm leading-6 text-center my-3 w-[95%] max-w-screen-md mx-auto ">
          {t(
            'Student Scoop - your hub for student life insights and success tips. Delve into a collection of campus hacks, career advice, study strategies, and real stories shared by fellow students. Join us as we navigate the student journey together.',
          )}
        </p>
        {posts.length > 0? (
        <center>
          <div className="hidden md:inline-flex mt-8">
            <div className="relative blog-card max-w-[25rem]">
              
                <Link aria-label={posts[0]?.title} to={`/blog/${posts[0]?.id}`} className='text-inherit'>
                  <img loading="lazy" alt="1" src={posts[0]?.bannerImage} className="max-w-full h-full rounded-t-lg" />
                  <div className="blog-grid-item-content">
                    <h3 className="mb-2 capitalize text-left text-sm custom-text-shadow">
                      {posts[0]?.title}
                    </h3>
                    <p className="text-left text-xs">{posts[0]?.description.slice(0,100)}... <b>Read More</b></p>
                  </div>
                </Link>              
            </div>
            <div className="flex ml-3" style={{ flexDirection: 'column' }}>
              <div className="ml-3 flex max-w-[36rem] h-full">
                {posts.slice(1, 3).map((post, i) => (
                  <Link key={i} aria-label={post?.title} to={`/blog/${post?.id}`} className='text-inherit'>
                    <div className="relative blog-card mr-4 h-full">
                      <img loading="lazy" alt={i} src={post?.bannerImage} className="max-w-full h-full rounded-t-lg" />
                      <div className="blog-grid-item-content">
                        <h3 className="mb-2 capitalize text-left text-sm custom-text-shadow">
                          {post?.title}
                        </h3>
                        <p className="text-left text-xs">{post?.description.slice(0,100)}... <b>Read More</b></p>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
              <div className="flex ml-2 h-full mt-3 max-w-[36rem]">
                {posts.slice(3, 5).map((post, i) => (
                  <Link key={i} aria-label={post?.title} to={`/blog/${post?.id}`} className='text-inherit'>
                    <div className="relative blog-card mr-4 h-full inline-block">
                      <img loading="lazy" alt={i} src={post?.bannerImage} className="max-w-full h-full rounded-t-lg" />
                      <div className="blog-grid-item-content">
                        <h3 className="mb-2 capitalize text-left text-sm custom-text-shadow">
                          {post?.title}
                        </h3>
                        <p className="text-left text-xs">{post?.description.slice(0,100)}... <b>Read More</b></p>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
          <div className="block md:hidden mt-8 px-3 h-full">
            {posts.slice(1, 5).map((post, index) => (
              <Link key={index} aria-label={post?.title} to={`/blog/${post?.id}`} className='text-inherit'>
                <div className="mb-5 h-full">
                  <div className="flex gap-3 h-full">
                    <img loading="lazy" alt={index} src={post?.bannerImage} className="w-1/2 h-full" />
                    <div className="text-left w-1/2">
                      <h2 className="text-sm mb-1 capitalize">{post?.title}</h2>
                      <p className="text-xs">{post?.description.slice(0,100)}... <b>Read More</b></p>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
          <Link
            className="bannerBtn1 h-14 md:h-20 px-6 md:px-8 bg-[#FFFEFB] text-[#131313] border border-solid border-[#FFC400] text-sm capitalize mt-8 md:mt-16 mb-10 max-w-[17rem]"
            to="/blog"
          >
            {t('Explore More Blogs')}
            <img loading="lazy" src={rightArrow} alt="icon" />{' '}
          </Link>
        </center>
        ):'loading blogs..'}
      </div>
    </div>
  );
};

export default Blog;
