import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const usePageMetadata = (title, description = null) => {
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = title;
    const metaDescription = document.querySelector('meta[name="description"]');

    if(description){

        if (metaDescription) {
            metaDescription.setAttribute('content', description);
        } else {
            const newMetaDescription = document.createElement('meta');
            newMetaDescription.name = "description";
            newMetaDescription.content = description;
            document.head.appendChild(newMetaDescription);
        }
        
        return () => {
            if (metaDescription) {
                metaDescription.removeAttribute('content');
            }
        }
    };
  }, [title, description, dispatch]);
};

export default usePageMetadata;
