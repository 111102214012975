import React, { useEffect } from 'react';

const GoogleTranslateFooter = ({ customId = 'google_translate_element2' }) => {
    const googleTranslateElementInit = () => {
      if (window.google && window.google.translate) {
        if (document.getElementById(customId)) {
          new window.google.translate.TranslateElement({ pageLanguage: 'en' }, customId);
        } else {
          delete window.google.translate.TranslateElement;
        }
      }
    };
  useEffect(() => {
    googleTranslateElementInit();
    setTimeout(() => {
      const elements = document.querySelectorAll(`.skiptranslate.goog-te-gadget`);

      elements.forEach(element => {
        const childNodes = element.childNodes;
        childNodes.forEach(node => {
          if (node.nodeType === Node.TEXT_NODE && node.textContent.includes('Powered by ')) {
            node.textContent = node.textContent.replace('Powered by', '');
          }
        });

        const spanElement = element.querySelector('span[style="white-space:nowrap"] > a');
        if (spanElement) {
          spanElement.parentNode.removeChild(spanElement);
        }
      });
    }, 1000);


    return () => {
      // Clean-up code if needed
    };
  }, [customId,googleTranslateElementInit]);

  return (
    <div>
      <div id={customId} className='google_translate_iframe'></div>
      <style>{`.google_translate_iframe iframe { display: none; }`}</style>
    </div>
  );
};

export default GoogleTranslateFooter;
