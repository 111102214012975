import House from "../../../assets/Icon/party-06 1.webp";
import rightArrow from "../../../assets/Icon/VectorR.webp";
import Call from "../../../assets/Icon/call.webp";
import "./Community.css";

const Community = () => {
  return (
    <div>
      {" "}
      <div className="community hidden">
        <div className="title">
          <span className="hr-sect">our community</span>
        </div>
        <div className="heading">
          <h3>Join a valuable resource built for decision makers</h3>
        </div>
        <div className="contents">
          <div className="card">
            <div className="card1">
              <div className="main-text">
                <p>
                  Global Tribe is a diverse community of students from all
                  corners on the world, based in Istanbul, Turkey. Our roots
                  consist of many sub communities which are located in all
                  leading universities across Istanbul......
                </p>
              </div>
              <div className="subtext">
                <span>
                  <img src={House} alt="Icon" loading="lazy" />
                  <p>women unity</p>
                  <p>
                    <b>.</b>
                  </p>
                  <p>Community</p>
                </span>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card2">
              <div className="main-text">
                <p>
                  Global Tribe is a diverse community of students from all
                  corners on the world, based in Istanbul, Turkey. Our roots
                  consist of many sub communities which are located in all
                  leading universities across Istanbul......
                </p>
              </div>
              <div className="subtext">
                <span>
                  <img src={House} alt="Icon" loading="lazy" />
                  <p>global tribe</p>
                  <p>
                    <b>.</b>
                  </p>
                  <p>Community</p>
                </span>
              </div>
            </div>
          </div>{" "}
          <div className="card">
            <div className="card3">
              <div className="main-text">
                <p>
                  Global Tribe is a diverse community of students from all
                  corners on the world, based in Istanbul, Turkey. Our roots
                  consist of many sub communities which are located in all
                  leading universities across Istanbul......
                </p>
              </div>
              <div className="subtext">
                <span>
                  <img src={House} alt="Icon" loading="lazy" />
                  <p>fastalk</p>
                  <p>
                    <b>.</b>
                  </p>
                  <p>Community</p>
                </span>
              </div>
            </div>
          </div>{" "}
          <div className="card">
            <div className="card4">
              <div className="main-text">
                <p>
                  Global Tribe is a diverse community of students from all
                  corners on the world, based in Istanbul, Turkey. Our roots
                  consist of many sub communities which are located in all
                  leading universities across Istanbul......
                </p>
              </div>
              <div className="subtext">
                <span>
                  <img src={House} alt="Icon" loading="lazy" />
                  <p>Vitalety</p>
                  <p>
                    <b>.</b>
                  </p>
                  <p>Community</p>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="buttons">
          <button className="bannerBtn1">
            Explore More <img loading="lazy" src={rightArrow} alt="arrow" />
          </button>
          <button className="bannerBtn2">
            Contact Us <img loading="lazy" src={Call} alt="phone" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Community;
