import rightLightArrow from '../../../assets/Icon/arrow-rightV.webp';

import './IncubationCenters.css';

import { Link } from 'react-router-dom';
import { incubationCenters } from '../../Incubation/locations';
import { Disclosure, Transition } from '@headlessui/react';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
const IncubationCenters = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="bgIncubation relative pb-10 mb-12 md:mb-28">
        <div className="incubation">
          <div className="incubation wrap mt-28 md:mt-0">
            <div className="intro">
              <p className="text-sm md:text-lg uppercase">
                {t('Incubation Centers')}
              </p>
              <hr />
            </div>
            <div className="contents">
              <div className="mb-5 md:mb-7">
                <h4 className="text-2xl md:text-3xl font-medium capitalize text-white mb-3 md:mb-6 max-w-md">
                  {t('We have multiple incubation centers around the globe')}
                </h4>
                <p className="text-xs text-white capitalize leading-[1rem] md:leading-[1.25rem] max-w-screen-md">
                  {t(
                    'These centers serve student communities across campuses. We have created a hub to connect, brainstorm ideas and organize physical meetings to improve the quality of our services and not only be an online service provider, but also provide offline services to students around the globe from Africa to Asia to Europe',
                  )}
                </p>
              </div>
              <div className="max-w-screen-md">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-3 md:gap-y-6">
                  {incubationCenters.slice(0, 4).map((center, index) => (
                    <div className="" key={index}>
                      <Disclosure
                        as={'div'}
                        className={`${index} p-4 rounded-lg bg-white relative`}
                      >
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex items-center w-full justify-between  text-left text-sm  bg-transparent border-none cursor-pointer">
                              <span className="flex items-center text-xl gap-3">
                                <img
                                  loading="lazy"
                                  src={center.flag}
                                  alt="flag"
                                />
                                <p className="md:text-[20px] md:leading-23px">
                                  {center.title}
                                </p>
                              </span>
                              <div className=" bg-[#FFF] rounded-full w-6 h-6 flex items-center  cursor-pointer">
                                {open ? (
                                  <AiOutlineMinus className="h-5 w-5 text-background11" />
                                ) : (
                                  <AiOutlinePlus className="h-5 w-5 text-background11" />
                                )}
                              </div>
                            </Disclosure.Button>
                            <Transition
                              show={open}
                              // enter="transition duration-1000 ease-out"
                              // enterFrom="transform scale-95 opacity-0"
                              // enterTo="transform scale-100 opacity-100"
                              // leave="transition duration-100 ease-out"
                              // leaveFrom="transform scale-100 opacity-100"
                              // leaveTo="transform scale-95 opacity-0"
                            >
                              <Disclosure.Panel
                                unmount
                                as="ul"
                                className="bg-white p-4 rounded-lg absolute left-0 right-0 z-10"
                              >
                                <hr />

                                <div className="capitalize mt-5 mb-5 ">
                                  <p className="text-base leading-[14px] mb-2 capitalize">
                                    {center.incubationName}
                                  </p>
                                  <p className="text-sm leading-4 ">
                                    {center.name}
                                  </p>
                                </div>
                                <div className="flex gap-[18px]">
                                  <div className="user-icon">
                                    <span>
                                      <img
                                        loading="lazy"
                                        src={center.userIcon}
                                        alt="user icon"
                                      />
                                    </span>
                                  </div>
                                  <div className="user mb-5  capitalize">
                                    <p className="text-base text-[#5151FD] leading-[14px] mb-2">
                                      {center.rep}
                                    </p>

                                    <p className="text-sm leading-4 ">
                                      {center.repName}
                                    </p>
                                  </div>
                                </div>
                                {/*  */}

                                <div className="flex gap-[18px]">
                                  <div className="location-icon">
                                    <span>
                                      <img
                                        loading="lazy"
                                        src={center.locationIcon}
                                        alt="location icon"
                                      />
                                    </span>
                                  </div>
                                  <div className="user text-sm md:leading-5">
                                    {center.location}
                                  </div>
                                </div>
                              </Disclosure.Panel>
                            </Transition>
                          </>
                        )}
                      </Disclosure>
                    </div>
                  ))}
                </div>
              </div>
              <div className="button mt-6 md:mt-10 justify-center md:justify-start">
                <button>
                  <Link
                    to="/incubation-centers"
                    className="flex gap-3 items-center"
                  >
                    <p>{t('Explore more')}</p>
                    <span>
                      <img loading="lazy" src={rightLightArrow} alt="icon" />{' '}
                    </span>
                  </Link>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IncubationCenters;
