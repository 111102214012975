import React, { useState } from "react";
import "./TemCard.css"
import { useTranslation } from 'react-i18next';

const TeamMemberCard = ({ name, designation,image,hobby,flag }) => {
  const [showModal, setShowModal] = useState(false) 
  const {t}=useTranslation()
  
  return (
    <div className="flex flex-col flex-nowrap justify-start items-center p-3 gap-3" id="card-container">
      <img
      
      src={require(`../../${image}`)}
        alt={name}
        className="w-24 h-24 rounded-full"
      />
      <h3 className="text-white text-xl font-semibold leading-8 text-center ">
        {name}
      </h3>

      <p className="text-white font-normal text-sm capitalize text-center leading-6">
        {t(designation)}{" "}
      </p>

      <div className=" items-center text-center justify-center flex ">
        {/* <i className="fa-brands fa-linkedin-in"></i> */}
        <img
      
      src={require(`../../${flag}`)}
        alt={name}
        className="w-8 h-6 "
      />
      </div>
      <span className="text-[18px] font-semibold capitalize leading-6 text-white md:hidden">
        {" "}
        Fun Fact About Me
      </span>
      <p className="font-normal text-base leading-5 capitalize text-white/[.7] md:hidden text-center">
      {hobby.map((item)=>t(item))+"."}
      </p>
      {/* <div id="hoverlabel" className="hidden absolute" >
        
        <img  src={`assets/cardhover/${Math.floor(Math.random() * 3+1)}.svg`} alt="" className="opacity-[90%] pr-3"/>
      <ul className="absolute top-10 left-10 pr-2 z-30 w-fit text-lg font-semibold leading-5 capitalize text-left items-center">
       {hobby.map((item)=><li>{t(item)}</li>)}{" "}</ul>
       
     
      </div> */}
       <div id="hoverlabel" className="  opacity-[90%] hidden md:flex  bg-yellow-500">
       

       
       {hobby.map((item)=>t(item))+"."}
       
      </div>
      
    </div>
  );
};

export default TeamMemberCard;
