import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import "./i18n.js";
import { Provider } from "react-redux";
import store from "./redux/store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Suspense>
    <Provider store={store}>
      <React.StrictMode>
        <div
          id="mainLoader"
          className=" xloader items-center justify-center fixed bg-[black]/25 w-full h-[100vh] top-0 z-[9000]">
          <span className="ploader"></span>
        </div>
        <App />
      </React.StrictMode>
    </Provider>
  </Suspense>
);
document.addEventListener("DOMContentLoaded", function () {
  const script = document.createElement("script");
  script.src =
    "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
  script.async = true;
  document.body.appendChild(script);
});
