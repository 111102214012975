import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Scrollbar from "smooth-scrollbar";
import useWindowSize from "../../hooks/useWindowSize";

const options = {
  damping: 0.07,
  alwaysShowTracks: true,
};

const SmoothScroll = () => {
  const location = useLocation();
  const screen = useWindowSize();

  useEffect(() => {
    const header = document.getElementById("global-header");
    const headerMenuIcon = document.getElementById("header-menu-icon");
    const headerLogoWhite = document.getElementById("header-logo-white");
    const headerLogoBlack = document.getElementById("header-logo-black");

    const scrollbar = Scrollbar.init(document.body, options);

    scrollbar.addListener(({ offset }) => {
      // header.style.transform = `translate3d(${offset.x}px, ${offset.y}px, 0px)`;
      header.style.top = offset.y + "px";
      header.style.left = offset.x + "px";

      if (screen.width < 900) {
        // mobile
        if (offset.y > 10) {
          // mobile - scrolled
          header.style.backgroundColor = "white";
          headerMenuIcon.style.fill = "black";
          headerLogoWhite.classList.add("hidden");
          headerLogoBlack.classList.remove("hidden");
          header.classList.add("!shadow-header");
        } else {
          // mobile - unscrolled
          header.style.backgroundColor = "transparent";
          headerMenuIcon.style.fill = "white";
          if (location.pathname !== "/") {
            // except homepage - mobile - unscrolled
            headerLogoWhite.classList.remove("hidden");
            headerLogoBlack.classList.add("hidden");
          }
          header.classList.remove("!shadow-header");
        }
      } else {
        // desktop
        header.style.backgroundColor = "white";
      }
    });

    return () => {
      if (Scrollbar) Scrollbar.destroy(document.body);
    };
  }, [screen.width, location.pathname]);

  return null;
};

export default SmoothScroll;
