import React from "react";
import { ReactComponent as LogoWhite } from "../../../assets/Icon/paramount-students-white-logo.svg";
import { AnimatePresence, motion } from "framer-motion";
import "./Loading.css"
const Index = () => {
  return (
   
      <div
      id="fade_div"
       
       
        className="bg-black flex justify-center items-center w-full h-[100%]"
      >
        <motion.div
        key="modal2"
    initial={{ opacity: 0, scale: 0.5 }}
    animate={{ opacity: 1, scale: 1.2 }}
    
    transition={{ duration: 2 }}>
    <LogoWhite className='md:h-[15vh] h-[5vh]'/>
    </motion.div>
      </div>
   
  );
};

export default Index;
