import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '../../Layout/Container';
import axios from '../../../api/axios';
import { useTranslation } from 'react-i18next';
import './newsletter.css';
import img1 from '../../../assets/Images/image 25.webp';
import img2 from '../../../assets/Images/aboutUsMission.webp';
import img3 from '../../../assets/Images/ourStory.png';
import img4 from '../../../assets/Images/aboutUsMission.webp';
import img5 from '../../../assets/Images/ourStory.png';
const Newsletter = () => {
  const [subscribing, setSubscribing] = useState(false);
  const [email, setEmail] = useState();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubscribe = (e) => {
    e.preventDefault();
    setSubscribing(true);
    axios
      .post(
        `https://paramount-students-server.herokuapp.com/api/v1/subscriber/subscribe/${email}`,
      )
      .then((res) => {
        console.log(res.data);
        if (res.status === 201) {
          // send email to email address

          // navigate to /newsletter-thanks
          navigate('/newsletter-thanks');
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        setSubscribing(false);
      });
  };
  const subscribers = [img1, img2, img3, img4, img5];
  return (
    <section className="section newsletter-section  flex justify-center">
      <Container maxWidth="xl" className="md:px-8">
        <div className="newsletter-section-content py-16 flex flex-col md:flex-row items-center md:justify-between max-w-[1100px] mx-auto gap-8 md:gap-16">
          <div className="newsletter-section-left flex flex-col flex-1">
            <h3 className="text-[28px] leading-8 tracking-tight font-medium">
              {t('Sign up for our newsletter')}
            </h3>
            <p className="mt-2 md:mt-3 text-xs md:text-sm">
              {t(
                'Be the first to stay informed about new releases, industry news, and valuable insights',
              )}
            </p>
            <div className="flex mt-3 w-full">
              <div className="flower-container mr-4 w-[100px]">
                {subscribers.map((item, index) => (
                  <img
                    key={index}
                    src={item}
                    alt={`Flower ${index + 1}`}
                    className="flower"
                  />
                ))}
              </div>
              <p className='w-[50%] text-xs md:text-base'>
                <strong>8k</strong> students have already subscribed
              </p>
            </div>
          </div>
          <div className="w-auto">
            <iframe
              title="email subscribe"
              src="https://studentscoop.substack.com/embed"
              width={480}
              height={320}
              style={{ border: '1px solid #EEE', background: 'white' }}
              className="rounded-xl"
              frameBorder="0"
              scrolling="no"
              loading="lazy"
            ></iframe>
            <p className="mt-2 text-xs md:text-sm leading-5 text-center">
              {t('We care about your data in our ')}
              <a
                href="https://paramountstudents.network/privacy-policy"
                target="_blank"
                rel="noreferrer"
                className="underline text-text-primary cursor-pointer"
              >
                {t('privacy policy')}
              </a>
            </p>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Newsletter;
