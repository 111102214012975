import React from "react";
import Applinsk from "./Applinsk";

const Features = () => {
  return (
    <div
      className="mt-20 py-20 sm:mt-28 md:mt-20 h-full pl-2"
      style={{
        height: "100%",
        backgroundImage: `
          linear-gradient(to right, #ffae001f 10%, #fffcf5 30%, #ffffff 90%),
          radial-gradient(circle at 25% 60%, rgba(280,215,200,1) 10%, rgba(254,215,170,0.5) 10%, rgba(255,255,255,0) 20%)
        `,
      }}>
      <div className="flex flex-row justify-center items-center">
        <p className="font-recoleta font-bold text-gray-900 text-2xl lg:text-6xl">
          Our Features
        </p>
      </div>
      <div
        className="flex flex-row items-center pt-10 w-full mt-10"
        style={{
          height: "100%",
          background:
            "radial-gradient(circle at 25% 50%, rgba(254,220,180,1) 0%, rgba(254,215,170,0.5) 10%, rgba(255,255,255,0) 20%)",
        }}>
        <div className="w-1/2 flex flex-col items-center">
          <div className="flex flex-col items-start w-[180px] sm:w-[300px] md:w-[400px] lg:w-[560px] transition-all duration-500 ease-in-out lg:space-y-10">
            <div className="space-y-2">
              <h4 className="font-bold font-recoleta sm:text-xl md:text-2xl lg:text-3xl transition-all duration-500 ease-in-out">
                Explore Universities
              </h4>
              <p className="font-graphik text-[10px] sm:text-[14px] md:text-[16px] lg:text-[24px] transition-all duration-500 ease-in-out">
                Browse hundreds of universities worldwide and discover programs
                that match your interests and career aspirations.
              </p>
            </div>
            <div className="w-full mt-2 h-auto flex flex-row ">
              <Applinsk />
            </div>
          </div>
        </div>
        <div className="w-1/2 flex flex-col items-center justify-center">
          <div className="w-44 h-44 sm:w-56 sm:h-56 md:w-80 md:h-80 lg:w-[500px] lg:h-[500px] rounded-full flex flex-col items-center justify-center border border-solid border-background15 relative transition-all duration-500 ease-in-out">
            <div className="w-32 h-32 sm:w-40 sm:h-40 md:w-60 md:h-60 lg:w-[400px] lg:h-[400px] rounded-full border border-solid border-background15 transition-all duration-500 ease-in-out">
              <img
                src="/mobileapp/features.png"
                alt="login"
                className="h-52 w-28 sm:h-64 sm:w-36 md:h-96 md:w-52 lg:h-[600px] lg:w-80 absolute -top-5 left-8 sm:left-10 md:left-14 md:-top-10 lg:-top-12 lg:left-24 transition-all duration-500 ease-in-out"
              />
            </div>
            <img
              src="/mobileapp/germany.png"
              alt="germany flag"
              className="w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 lg:w-14 lg:h-14 rounded-full absolute -rotate-12 top-2 -left-8 sm:top-4 lg:top-12 lg:-left-12 transition-all duration-500 ease-in-out"
            />
            <img
              src="/mobileapp/azeibaijan.png"
              alt="azeibaijan flag"
              className="w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 lg:w-14 lg:h-14 rounded-full absolute rotate-45 -top-2 -right-2 lg:-top-6 lg:-right-10 transition-all duration-500 ease-in-out"
            />
            <img
              src="/mobileapp/turkey.png"
              alt="turkey flag"
              className="w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 lg:w-14 lg:h-14 rounded-full absolute -rotate-12 bottom-2 -left-2 lg:bottom-12 lg:-left-4 transition-all duration-500 ease-in-out"
            />
            <img
              src="/mobileapp/malasya.png"
              alt="malasya flag"
              className="w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10 lg:w-14 lg:h-14 rounded-full absolute rotate-45 bottom-10 -right-1 sm:bottom-12 md:bottom-14 lg:bottom-40 lg:-right-8 transition-all duration-500 ease-in-out"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
