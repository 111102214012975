import React from 'react';
import classes from '../../pages/Team/Team.module.css';

export default function TeamCard({ name, designation, country, flag, image }) {
   return (
      <div className={classes['team-card']}>
         <div className={classes.avatar}>
            <img loading='lazy' src={require(`../../${image}`)} alt="" />
         </div>
         <p>{name}</p>
         <div className={classes['member-details']}>
            <h3>{name}</h3>
            <p>{designation}</p>
            <div className={classes.country}>
               <span>
                  <img src={require(`../../${flag}`)} alt="" />
               </span>
               <span>{country}</span>
            </div>
         </div>
      </div>
   );
}
