import React from 'react';
import InputSection from './InputSection';
import './home.css';
import GoogleTranslate from '../Translate';

const Home = () => {
    return (
        <div className='p-[20px] md:p-[0px]'>
            <div className="flex justify-between mt-5 mb-4 md:mx-36">
               <div>
                 <img src='./image/Group 27432.png' className='w-[120px]' />
               </div>
               <div>
                <GoogleTranslate customId='waitlist' />
                 {/* <img src='./image/Frame 1.png' className='w-[70px] ' /> */}
               </div> 
            </div>
          <div className='grid grid-cols-1  md:grid-cols-3 w-full mb-24'>
                <div className="col-span-1 hidden md:grid grid-cols-1 mt-20 gap-4">
                    <div className="relative waitlistAnim w-full">
                        <img src='./image/hat.png' className='w-[40px] hover:animate-pulse md:w-[20%] absolute bottom-10 right-[40px]'/>
                    </div>
                    <div className="relative waitlistAnim w-full animation-delay-1">
                        <div className='bg-[#FFD02880] lg:w-[60%] animate-pulse absolute md:right-[59px] top-[33px] text-sm font-semibold md:text-lg text-center p-[15px] rounded-[30px] origin-center rotate-[-30deg]'>Scholarship opportunities</div>                        
                    </div>
                    <div className="relative waitlistAnim animation-delay-2">
                        <div className='bg-[#5151FD80] absolute animate-pulse right-[50px] bottom-0 md:top-[-19px] text-[#181866] w-[140px] flex items-center md:text-lg font-semibold p-[15px] rounded-[50px] text-center origin-center rotate-[-25deg]'>Simplified application process</div>
                    </div>
                    <div className="relative waitlistAnim animation-delay-3">
                        <img src='./image/bridge.png' className='w-[40px] animate-pulse absolute bottom-[60%] right-[40px]' />
                    </div>
                </div>
                <div className='col-span-1 w-full'>
                    <InputSection />
                </div>

                <div className="col-span-1 hidden md:grid grid-cols-1 gap-4 md:gap-[0px] md:mt-10">
                    <div className="relative waitlistAnim2 w-full">
                        <div className='bg-[#00AD5F4D] w-[140px] animate-pulse  text-xl text-[#006839] text-center absolute left-[45%] lg:left-[10%] bottom-0 p-[10px] rounded-[50px] origin-center rotate-[30deg]'>Virtual University Tours</div>                        
                    </div>              
                    <div className="relative waitlistAnim animation-delay-1">
                        <img src='./image/mobile-phone.png' className='w-[40px] animate-bounce absolute md:w-[16%] left-[80%] lg:left-[27%] top-5' />
                    </div>
                    <div className="relative waitlistAnim2 w-full animation-delay-2">
                        <div className='bg-[#FFDDD1] w-[120px] text-[#4D1A08]  text-center absolute left-[45%] lg:left-[20%] top-[50px]  p-[15px] rounded-[50px] origin-center rotate-[25deg]'>No Hidden Costs</div>                        
                    </div> 
                    <div className="relative waitlistAnim2 w-full animation-delay-3">
                        <img src='./image/university.png' className='w-[40px] md:w-[45px] delay-600	 animate-pulse left-[80%] lg:left-[15%] absolute top-[20px]' />
                    </div>
                    <div className="relative waitlistAnim2 w-full animation-delay-4">
                        <div className='bg-[#FFD02866] w-[130px] text-[#332700] text-center delay-300 animate-pulse absolute left-[60%] lg:left-[35%] top-[20px] text-xl  p-[15px] rounded-[50px] origin-center rotate-[16deg]'>Ease of access</div>                        
                    </div> 
                </div>
          </div>

          <div className='grid md:grid-cols-3 grid-cols-1 w-full mb-24 md:px-[100px]'>
            <div className='w-full  mt-4  flex items-center  text-center md:text-left'>
                <h1 className='font-bold text-md md:text-3xl '>We Make Studying Abroad Effortless</h1>
            </div>
            <div className='w-full mt-4 flex justify-center md:mt-0'>
                <img src='./image/iphone (1).png' className='w-[70%] mx-auto' />
            </div>
            <div className='w-full flex  items-center mt-4  text-center md:text-left'>
                <p>Discover top universities from all around the world, apply to programs of your choice seamlessly and set yourself up for a higher educational journey unlike ever before.</p>
            </div>
          </div>
        </div>
      
    )
};

export default Home;