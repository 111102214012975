import { MASTER_CONTACT_EMAIL } from "../variables";

const headerNavigation = [
  { label: "Home", href: "/", id: "header-navigation-home-link" },
  { label: "About Us", href: "/about-us" },
  { label: "Our Services", href: "/our-services" },
  { label: "Our Products", href: "/our-products" },
  { label: "Student Experience", href: "/communities" },
  { label: "Incubation Centers", href: "/incubation-centers" },
  { label: "Blog", href: "/blog" },
  { label: "Meet The Team", href: "/meet-the-team" },
  {
    label: "Contact Us",
    href: `mailto:${MASTER_CONTACT_EMAIL}`,
    external: true,
    blank: true,
    button: true,
  }, 
  {
    label: "Join Waitlist",
    href: `/network`,
    external: true,
    blank: true,
    button: true,
  },
];

export { headerNavigation };
