import firstUni from '../../../assets/Icon/iu.webp';
import secondUni from '../../../assets/Icon/image 16.webp';
import thirdUni from '../../../assets/Icon/Vector (3).webp';
import fourthUni from '../../../assets/Icon/download.webp';
import fifthUni from '../../../assets/Icon/homeLogo 1.webp';
import sixthUni from '../../../assets/Icon/sabanci-university-side.webp';
import seventhUni from '../../../assets/Icon/image 15.webp';
import eightUni from '../../../assets/Icon/Medipol-University 1.webp';
import ninthUni from '../../../assets/Icon/uskudar-university 1.webp';
import tenthUni from '../../../assets/Icon/image 22.webp';
import eleventhUni from '../../../assets/Icon/University-of-the-Philippines-Logo.png';
import twelfthUni from '../../../assets/Icon/Washington-University-Logo.png';
import thirteenthUni from '../../../assets/Icon/Boston-University-Logo.png';
import fourteenthUni from '../../../assets/Icon/Harvard-University-Logo.png';
import fifteenthUni from '../../../assets/Icon/Chicago-University-Logo.png';
import sixteenthUni from '../../../assets/Icon/Columbia-University-Logo.png';

import '@splidejs/react-splide/css';
import './AffilatedUniversities.css';
import { useTranslation } from 'react-i18next';
import Marquee from 'react-fast-marquee';

const AffilatedUniversities = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="uni mb-12 md:mb-28">
        <div className="m-auto mb-3">
          <span className="hr-sect m-auto text-sm md:text-lg">
            {t('Affiliated universities')}
          </span>
        </div>
        <div className="mb-4 md:mb-8">
          <h3 className="text-[1.75rem] md:text-[2.5rem] font-semibold text-center">
            {t('Meet our Affiliated Universities')}
          </h3>
        </div>
        <div className="flex flex-col">
          <div className="text-center mb-8 md:mb-12 max-w-screen-md mx-auto">
            <p className="text-xs md:text-sm leading-[1.125rem] md:leading-[1.5rem]">
              {t(
                'Browse the list of universities we work with and choose the ones that meet your educational, recreational, developmental and financial standards. The 50+ universities affiliated with us are located around the world and have been vetted by Paramount Students to ensure they are able to meet your needs in every way. You are welcome to join us!',
              )}
            </p>
          </div>
          {
            <div><Marquee className="mb-4 md:mb-8" speed={100}>
            <div className="mx-10 md:mx-20">
              <img className='w-16 md:w-24' loading="lazy" src={firstUni} alt="universities" />
            </div>

            <div className="mx-10 md:mx-20">
              <img className='w-16 md:w-24' loading="lazy" src={secondUni} alt="universities" />
            </div>

            <div className="mx-10 md:mx-20">
              <img className='w-16 md:w-24' loading="lazy" src={thirdUni} alt="universities" />
            </div>
            <div className="mx-10 md:mx-20">
              <img className='w-16 md:w-24' loading="lazy" src={fourthUni} alt="universities" />
            </div>

            <div className="mx-10 md:mx-20">
              <img className='w-16 md:w-24' loading="lazy" src={fifthUni} alt="universities" />
            </div>

            <div className="mx-10 md:mx-20">
              <img className='w-16 md:w-24' loading="lazy" src={sixthUni} alt="universities" />
            </div>

            <div className="mx-10 md:mx-20">
              <img className='w-16 md:w-24' loading="lazy" src={seventhUni} alt="universities" />
            </div>
            <div className="mx-10 md:mx-20">
              <img className='w-16 md:w-24' loading="lazy" src={eightUni} alt="universities" />
            </div>
          </Marquee>
          <Marquee speed={100} direction="right">
            <div className="mx-10 md:mx-20">
              <img className='w-16 md:w-24' loading="lazy" src={ninthUni} alt="universities" />
            </div>

            <div className="mx-10 md:mx-20">
              <img className='w-16 md:w-24' loading="lazy" src={tenthUni} alt="universities" />
            </div>

            <div className="mx-10 md:mx-20">
              <img className='w-16 md:w-24' loading="lazy" src={eleventhUni} alt="universities" />
            </div>
            <div className="mx-10 md:mx-20">
              <img className='w-16 md:w-24' loading="lazy" src={twelfthUni} alt="universities" />
            </div>

            <div className="mx-10 md:mx-20">
              <img className='w-16 md:w-24' loading="lazy" src={thirteenthUni} alt="universities" />
            </div>

            <div className="mx-10 md:mx-20">
              <img className='w-16 md:w-24' loading="lazy" src={fourteenthUni} alt="universities" />
            </div>

            <div className="mx-10 md:mx-20">
              <img className='w-16 md:w-24' loading="lazy" src={fifteenthUni} alt="universities" />
            </div>
            <div className="mx-10 md:mx-20">
              <img className='w-16 md:w-24' loading="lazy" src={sixteenthUni} alt="universities" />
            </div>
          </Marquee>
          </div>
          }
        </div>
      </div>
    </div>
  );
};

export default AffilatedUniversities;
