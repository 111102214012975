export const team = [
   {
      name: 'Khalid Boulhala',
      designation: 'chief executive officer (CEO)',
      country: 'Morocco',
      flag: function () {
         return `assets/Images/flags/${this.country}.webp`;
      },
      image: function () {
         return `assets/Images/profile-images/${this.name}.webp`;
      },
   },
   {
      name: 'Wajahat Sheikh',
      designation: 'Head Of Product',
      country: 'Pakistan',
      flag: function () {
         return `assets/Images/flags/${this.country}.webp`;
      },
      image: function () {
         return `assets/Images/profile-images/${this.name}.webp`;
      },
   },
   {
      name: "Muath Qannita",
      designation: "Head of Human Resources",
      department: "Human Resources",
       hobby:["Leadership", "Dancing", "Highly Approachable"],
      country: "Palestine",
      flag: function () {
        return `assets/Images/flags/${this.country}.webp`;
      },
      image: function () {
        return `assets/Images/profile-images/${this.name}.webp`;
      },
    },
   {
      name: 'Suhail Panjwani',
      designation: 'CMO',
      country: 'Malawi',
      flag: function () {
         return `assets/Images/flags/${this.country}.webp`;
      },
      image: function () {
         return `assets/Images/profile-images/${this.name}.webp`;
      },
   },
   {
      name: "Said Abdulsalam",
      designation: "Tech. HOD",
      department: "Technical",
       hobby:["Coding", "Studying"],
      country: "Nigeria",
      flag: function () {
        return `assets/Images/flags/${this.country}.webp`;
      },
      image: function () {
        return `assets/Images/avater2.jpg`;
      },
    },
    {
      name: 'Jade Martin',
      designation: 'Head of Creativity',
      country: 'Philippines',
      flag: function () {
         return `assets/Images/flags/${this.country}.webp`;
      },
      image: function () {
         return `assets/Images/profile-images/${this.name}.webp`;
      },
   },
   {
      name: 'Oyewumi Ifedayo',
      designation: 'Head of Social Media Team',
      country: 'Nigeria',
      flag: function () {
         return `assets/Images/flags/${this.country}.webp`;
      },
      image: function () {
         return `assets/Images/profile-images/${this.name}.png`;
      },
   },
   {
      name: 'Mariam Zahi',
      designation: 'HR',
      country: 'Turkey',
      flag: function () {
         return `assets/Images/flags/${this.country}.webp`;
      },
      image: function () {
         return `assets/Images/profile-images/${this.name}.png`;
      },
   },
   
   {
      name: 'Muhammad Iqbal',
      designation: 'Backend Engineer',
      country: 'Indonesia',
      flag: function () {
         return `assets/Images/flags/${this.country}.webp`;
      },
      image: function () {
         return `assets/Images/profile-images/${this.name}.png`;
      },
   },
   {
      name: 'Md. Samsul Alam',
      designation: 'Lead Software Engineer ',
      country: 'Bangladesh',
      flag: function () {
         return `assets/Images/flags/${this.country}.webp`;
      },
      image: function () {
         return `assets/Images/profile-images/${this.name}.png`;
      },
   },
   {
      name: 'Azhar Zaman',
      designation: 'MERN Developer',
      country: 'Pakistan',
      flag: function () {
         return `assets/Images/flags/${this.country}.webp`;
      },
      image: function () {
         return `assets/Images/profile-images/${this.name}.webp`;
      },
   },
   {
      name: 'Fabiyi Johnson',
      designation: 'Front End Developer',
      country: 'Nigeria',
      flag: function () {
         return `assets/Images/flags/${this.country}.webp`;
      },
      image: function () {
         return `assets/Images/profile-images/${this.name}.webp`;
      },
   },
   {
      name: 'Hassan Rachael',
      designation: 'Content writing',
      country: 'Nigeria',
      flag: function () {
         return `assets/Images/flags/${this.country}.webp`;
      },
      image: function () {
         return `assets/Images/profile-images/${this.name}.webp`;
      },
   },
   {
      name: 'Moses Fawole',
      designation: 'Front End Developer',
      country: 'Nigeria',
      flag: function () {
         return `assets/Images/flags/${this.country}.webp`;
      },
      image: function () {
         return `assets/Images/profile-images/${this.name}.webp`;
      },
   },
   {
      name: 'Sherifat Alabi',
      designation: 'Content writing',
      country: 'Nigeria',
      flag: function () {
         return `assets/Images/flags/${this.country}.webp`;
      },
      image: function () {
         return `assets/Images/profile-images/${this.name}.webp`;
      },
   },
   {
      name: 'Vesto',
      designation: 'Motion Designer',
      country: 'Nigeria',
      flag: function () {
         return `assets/Images/flags/${this.country}.webp`;
      },
      image: function () {
         return `assets/Images/profile-images/${this.name}.webp`;
      },
   },
   {
      name: 'Abayomi Aluko',
      designation: 'Team Lead Vue.JS ',
      country: 'Nigeria',
      flag: function () {
         return `assets/Images/flags/${this.country}.webp`;
      },
      image: function () {
         return `assets/Images/profile-images/${this.name}.webp`;
      },
   },
   {
      name: 'Balogun Jesutofunmi',
      designation: 'Vue JS Developer',
      country: 'Nigeria',
      flag: function () {
         return `assets/Images/flags/${this.country}.webp`;
      },
      image: function () {
         return 'assets/Icon/test-avatar.svg';
      },
   },
   {
      name: 'Chizoba Ugwuoke',
      designation: 'Vue JS Developer',
      country: 'Nigeria',
      flag: function () {
         return `assets/Images/flags/${this.country}.webp`;
      },
      image: function () {
         return 'assets/Icon/test-avatar.svg';
      },
   },
   {
      name: 'David Okononfua',
      designation: 'Vue JS Developer',
      country: 'Nigeria',
      flag: function () {
         return `assets/Images/flags/${this.country}.webp`;
      },
      image: function () {
         return `assets/Images/profile-images/${this.name}.webp`;
      },
   },
   {
      name: 'Dogukan Karaduman',
      designation: 'Vue JS Developer',
      country: 'Turkey',
      flag: function () {
         return `assets/Images/flags/${this.country}.webp`;
      },
      image: function () {
         return 'assets/Icon/test-avatar.svg';
      },
   },
   {
      name: 'Erica Macedo',
      designation: 'Lead UX Designer',
      country: 'Montenegro',
      flag: function () {
         return `assets/Images/flags/${this.country}.webp`;
      },
      image: function () {
         return `assets/Images/profile-images/${this.name}.webp`;
      },
   },
   {
      name: 'Francisca Biggs',
      designation: 'Intern UX Designer',
      country: 'Chile',
      flag: function () {
         return `assets/Images/flags/${this.country}.webp`;
      },
      image: function () {
         return 'assets/Icon/test-avatar.svg';
      },
   },
   {
      name: 'Yara Kazem',
      designation: 'Junior UI Designer',
      country: 'Egypt',
      flag: function () {
         return `assets/Images/flags/${this.country}.webp`;
      },
      image: function () {
         return 'assets/Icon/test-avatar.svg';
      },
   },
   {
      name: 'Mazeedah Yaqub',
      designation: 'UX Designer',
      country: 'Nigeria',
      flag: function () {
         return `assets/Images/flags/${this.country}.webp`;
      },
      image: function () {
         return `assets/Images/profile-images/${this.name}.webp`;
      },
   },
   {
      name: 'Sulaiman Ademola Idris',
      designation: 'UI Designer',
      country: 'Nigeria',
      flag: function () {
         return `assets/Images/flags/${this.country}.webp`;
      },
      image: function () {
         return 'assets/Icon/test-avatar.svg';
      },
   },
   {
      name: 'Aisha Shahab',
      designation: 'Creative Designer',
      country: 'Pakistan',
      flag: function () {
         return `assets/Images/flags/${this.country}.webp`;
      },
      image: function () {
         return `assets/Images/profile-images/${this.name}.webp`;
      },
   },
   {
      name: 'Eweje Femi',
      designation: 'Backend Engineer',
      country: 'Nigeria',
      flag: function () {
         return `assets/Images/flags/${this.country}.webp`;
      },
      image: function () {
         return 'assets/Icon/test-avatar.svg';
      },
   },
];
