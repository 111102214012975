import {useEffect} from "react";

import DocsPagesLayout from "../../../components/Docs/Layout";
import DocumentationContent from "../../../components/Docs/TermsAndConditions/DocumentationContent";
import Layout from "../../../components/Layout";
import setPageDescription from "../../../lib/utils/setPageDescription";

const TermsAndConditions = () => {
  useEffect(() => {
    document.title = 'Terms and Conditions | Paramount Students';
    setPageDescription('')
  }, []);

  return (
    <Layout transparentHeaderOnPhone>
      <main className="page documentation-page terms-and-conditions-page pt-0 md:pt-20">
        <DocsPagesLayout pageTitle="Terms & Conditions">
          <DocumentationContent />
        </DocsPagesLayout>
      </main>
    </Layout>
  );
};

export default TermsAndConditions;
