import Newsletter from '../Newsletter';
import Container from '../../Layout/Container';
import NavigationLink from '../Header/NavigationLink';
import { headerNavigation } from '../../../lib/static/headerNavigation';
//import { DownOutlined } from '@ant-design/icons';

import { ReactComponent as PhoneIcon } from '../../../assets/Icon/call-calling.svg';
import { ReactComponent as EmailIcon } from '../../../assets/Icon/sms.svg';
import { ReactComponent as LocationIcon } from '../../../assets/Icon/location.svg';
/* import { ReactComponent as EnglandFlag } from '../../../assets/Icon/england-flag.svg';
import { ReactComponent as ArrowUp } from '../../../assets/Icon/arrow-up.svg'; */
import ParamountStudentsLogo from '../../../assets/Icon/paramount-students-logo-black.svg';
/* import TRFlag from '../../../assets/Icon/turkey-flag.webp';
import FRFlag from '../../../assets/Icon/Flag_of_France.webp';
import ARFlag from '../../../assets/Icon/AR Flag.webp'; */

import { footerLinks } from '../../../lib/static/socialLinks';
import SocialLink from './SocialLink';
import { useTranslation } from 'react-i18next';
//import { Dropdown } from 'antd';
//import { useSelector } from 'react-redux';
//import { useDispatch } from 'react-redux';
//import { changeFlag, changeLang } from '../../../redux/langSlice';
//import { Button } from 'antd';
import GoogleTranslate from '../../Translate';
const Footer = ({ hideNewsletter }) => {
  const { t } = useTranslation();
/*   var language = useSelector((state) => state.lang.lang);
  var flag = useSelector((state) => state.lang.langflag);
 */
  //const dispatch = useDispatch();
/*   const onClick = ({ key }) => {
    var selectedflags = items.find((element) => element.key == key);

    dispatch(changeFlag(selectedflags));
    dispatch(changeLang(key));
    const language = key.toLowerCase();
    i18n.changeLanguage(language);
  }; */

  return (
    <div className="footer-newsletter-container">
      {!hideNewsletter && (
        <div className="footer-newsletter-container">
          <Newsletter />
        </div>
      )}
      <footer className="section footer-section flex justify-center bg-background6">
        <div className="max-w-[1100px] mx-auto">
          <Container maxWidth="xl">
            <div className="block md:flex py-10">
              <div className="w-full md:w-[40%]">
                <img
                  style={{ filter: 'grayscale(3)' }}
                  className="w-[50%]"
                  src={ParamountStudentsLogo}
                  key="paramount_logo"
                  alt="paramount logo"
                />
              </div>
              <div className="w-full md:w-[60%]">
                <div className="footer-content grid md:mt-0 mt-10 grid-cols-2 sm:grid-cols-2 md:grid-cols-3 gap-0 ">
                  <div className="">
                    <h2 className="capitalize font-medium text-base md:text-xl leading-5 mb-6">
                      {t('GET INVOLVED')}
                    </h2>
                    <ul className="footer-column1-links list-none child:capitalize flex flex-col gap-3 pl-3">
                      {headerNavigation.map((navItem, i) =>
                        navItem.label === 'Contact Us' ? (
                          <li key={`xj${i}`}  className="text-xs md:text-sm text-black">
                            <a className="hover:opacity-90 " style={{textDecoration:'none',color:'black'}} href={navItem.href}>
                              {t(navItem.label)}
                            </a>
                          </li>
                        ) : (
                          <NavigationLink
                            key={navItem.href}
                            href={navItem.href}
                            className="text-xs md:text-sm"
                          >
                            {t(navItem.label)}
                          </NavigationLink>
                        ),
                      )}
                    </ul>
                    {/* Language */}
                    <div className="language-switch-widget block md:hidden mt-5 bg-white rounded-lg cursor-pointer">
                      <GoogleTranslate customId="google_translate_element" position='footer' />
                    </div>
                    {/* end Language */}
                  </div>
                  <div className="footer-column2 md:col-span-2 grid sm:grid-cols-1 md:grid-cols-2 md:gap-4">
                    <div className="">
                      <h2 className="capitalize font-medium text-base md:text-xl leading-5 mb-6">
                        {t('Polices')}
                      </h2>
                      <ul className="footer-copyright-links list-none flex flex-col gap-3 pl-3">
                        <NavigationLink
                          href="/privacy-policy"                          
                          className="text-[#555] hover:underline underline-offset-1 text-xs md:text-sm"
                        >
                          <span style={{textDecoration:'none',color:'black !important'}}>
                          {t('Privacy Policy')}
                          </span>
                        </NavigationLink>
                        <NavigationLink
                          href="/terms-and-conditions"
                          className="text-[#555] hover:underline underline-offset-1 text-xs md:text-sm"
                        >
                           <span style={{textDecoration:'none',color:'black !important'}}>

                          {t('Terms and Conditions')}
                           </span>
                        </NavigationLink>
                        <NavigationLink
                          href="/frequently-asked-questions"
                          className="text-[#555] hover:underline underline-offset-1 text-xs md:text-sm"
                        >
                           <span style={{textDecoration:'none',color:'black !important'}}>

                          FAQs
                           </span>
                        </NavigationLink>
                      </ul>
                    </div>
                    <div className="footer-column2">
                      <h2 className="capitalize font-medium text-base md:text-xl leading-5 mb-6">
                        {t('SAY HELLO TO US')}
                      </h2>
                      <div className="md:ml-0 ml-5 footer-column2-links flex flex-col gap-3 pl-3">
                        <div className="icon-item flex space-x-3">
                          <span>
                            <PhoneIcon />
                          </span>
                          <span className="text-text-primary leading-4 md:leading-6 text-xs md:text-sm">
                          <span className='notranslate'>+442081596934</span>
                          </span>
                        </div>
                        <div className="icon-item flex space-x-3">
                          <span>
                            <EmailIcon />
                          </span>
                          <span className="text-text-primary leading-4 md:leading-6 text-xs md:text-sm">
                            <a
                              style={{ wordBreak: 'break-word', textDecoration:'none', color:'black' }}
                              href="mailto:Hi@Paramountstudent.com"

                            >
                            <span className='notranslate'>Hi@Paramountstudent.com</span>
                            </a>{' '}
                          </span>
                        </div>
                        <div className="icon-item flex space-x-3">
                          <span>
                            <LocationIcon />
                          </span>
                          <span className="text-text-primary leading-4 md:leading-6 text-xs md:text-sm">
                          <span className='notranslate'>71-75 Shelton Street, Covent Garden, London, WC2H
                            9JQ</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{ background: '#ccc' }}
              className="hidden md:block w-[100%] h-[1px]"
            ></div>
            <div
              className="w-[100%] py-[5px] flex flex-col md:flex-row  justify-center  items-center md:justify-between"
              style={{ alignItems: 'center' }}
            >
              <div className="footer-column3-social-icons flex items-center justify-between w-[160px] my-5">
                {footerLinks.map((social, i) => (
                  <SocialLink
                  className="w-[20px] h-[20px]"
                    key={social.platform}
                    socialPlatform={social.platform}
                    socialLink={social.link}
                  />
                ))}
              </div>
              <div className="text-center text-xs uppercase mb-4 md:mb-0">
                Copyright @ 2021 ALL RIGHTS RESERVED - PARAMOUNT STUDENTS
              </div>
              <div>
              <div className="language-switch-widget bg-white rounded-lg cursor-pointer hidden md:block">
                <GoogleTranslate customId="google_translate_element" position='footer'/>
              </div>
               {/*  <div className="language-switch-widget bg-white rounded-lg cursor-pointer hidden md:block">
                  <Dropdown
                    className="language-switch w-56 h-12 px-6 flex items-center justify-between font-medium bg-[#F4F4F4] text-black border-0"
                    menu={{
                      items,
                      selectable: true,
                      defaultSelectedKeys: [flag.id],
                      onClick,
                    }}
                    placement="bottom"
                    arrow
                  >
                    <Button className="text-white flex justify-between ">
                      <div className="flex gap-3 capitalize items-center">
                      
                        <p className="capitalize">{t(flag.id)}</p>
                      </div>

                      <ArrowUp />
                    </Button>
                  </Dropdown>
                </div> */}
              </div>
            </div>
          </Container>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
