import React, { useEffect, useRef, useState, useCallback } from 'react';
import './style.css';
import languageSVG from "../../assets/Images/language.svg";
import caretDownSVG from "../../assets/Images/caret-down.svg";

const GoogleTranslate = ({ customId = 'google_translate_element', position="header" }) => {
  const countries =[
    { name: "English", short_code: "US", language_code: "en" },
    { name: "Arabic", short_code: "SA", language_code: "ar" },
    { name: "Bengali", short_code: "BD", language_code: "bn" },
    { name: "French", short_code: "FR", language_code: "fr" },
    { name: "German", short_code: "DE", language_code: "de" },
    { name: "Gujarati", short_code: "IN", language_code: "gu" },
    { name: "Hindi", short_code: "IN", language_code: "hi" },
    { name: "Indonesian", short_code: "ID", language_code: "id" },
    { name: "Italian", short_code: "IT", language_code: "it" },
    { name: "Japanese", short_code: "JP", language_code: "ja" },
    { name: "Javanese", short_code: "ID", language_code: "jv" },
    { name: "Korean", short_code: "KR", language_code: "ko" },
    { name: "Malay", short_code: "MY", language_code: "ms" },
    { name: "Malayalam", short_code: "IN", language_code: "ml" },
    { name: "Marathi", short_code: "IN", language_code: "mr" },
    { name: "Mandarin Chinese", short_code: "CN", language_code: "zh" },
    { name: "Persian (Farsi)", short_code: "IR", language_code: "fa" },
    { name: "Polish", short_code: "PL", language_code: "pl" },
    { name: "Punjabi", short_code: "IN", language_code: "pa" },
    { name: "Portuguese", short_code: "PT", language_code: "pt" },
    { name: "Russian", short_code: "RU", language_code: "ru" },
    { name: "Spanish", short_code: "ES", language_code: "es" },
    { name: "Swahili", short_code: "KE", language_code: "sw" },
    { name: "Telugu", short_code: "IN", language_code: "te" },
    { name: "Tamil", short_code: "IN", language_code: "ta" },
    { name: "Thai", short_code: "TH", language_code: "th" },
    { name: "Turkish", short_code: "TR", language_code: "tr" },
    { name: "Ukrainian", short_code: "UA", language_code: "uk" },
    { name: "Urdu", short_code: "PK", language_code: "ur" },
    { name: "Vietnamese", short_code: "VN", language_code: "vi" },
  ];
//  const [position, setPosition] = useState('header');
const translateRef = useRef(null);
  const [languages, setLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    if (languages.length === 0) {
      setLanguages(getSelectOptions());
    }
    setIsOpen(!isOpen);
  };

  function triggerHtmlEvent(element, eventName, item) {
    var event;
    window.langObj = item;
    if (document.createEvent) {
      event = document.createEvent('HTMLEvents');
      event.initEvent(eventName, true, true);
      element.dispatchEvent(event);
    } else {
      event = document.createEventObject();
      event.eventType = eventName;
      element.fireEvent('on' + event.eventType, event);
    }
  }

  const handleSelect = (item) => {
    setSelectedLanguage(item);
    const selectElement = document.querySelector('.goog-te-combo');
    if (!selectElement) {
      googleTranslateElementInit();
    } else {
      selectElement.value = item.language_code;
      triggerHtmlEvent(selectElement, 'change', item);
    }
    setIsOpen(false);
  };

  const getSelectOptions = () => {
    const select = document.querySelectorAll('.goog-te-combo option');
    if (!select) {
      return [];
    }
    return Array.from(select).filter(option => option.value !== '')
      .map(option => ({ value: option.value, text: option.textContent }));
  };

  const googleTranslateElementInit = useCallback(() => {
    if (window.google && window.google.translate) {
      if (document.getElementById(customId)) {
        new window.google.translate.TranslateElement({
          pageLanguage: 'en',
        }, customId);
        setLanguages(getSelectOptions());
      }
    }
  }, [customId]);

  useEffect(() => {
    if (!window.google || !window.google.translate) {
      const script = document.createElement('script');
      script.src = "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
      script.async = true;
      document.body.appendChild(script);
    } else {
      setTimeout(() => {
        googleTranslateElementInit();
      }, 1000);
    }
  }, [googleTranslateElementInit]);

  useEffect(() => {
    const selectElement = document.querySelector('.goog-te-combo');
    const handleCustomEvent = (event) => {
      setSelectedLanguage(window.langObj);
      document.body.style.top = '0px';
    };

    if (selectElement) {
      selectElement.addEventListener('change', handleCustomEvent);
    } else {
      const delayedAttach = () => {
        const delayedSelectElement = document.querySelector('.goog-te-combo');
        if (delayedSelectElement) {
          delayedSelectElement.addEventListener('change', handleCustomEvent);
        }
      };

      const observer = new MutationObserver(delayedAttach);
      observer.observe(document.body, { childList: true, subtree: true });

      return () => {
        observer.disconnect();
      };
    }

    return () => {
      if (selectElement) {
        selectElement.removeEventListener('change', handleCustomEvent);
      }
    };
  }, []);

  /*

  useEffect(() => {
    const currentContainer = position === 'footer' ? 'footerContainer' : 'headerContainer';
    document.getElementById(currentContainer)?.appendChild(translateRef.current);
  }, [position]); */

  return (
    <div>      
      <div className="dropdown relative" translate='no'>
        <button className={[position ==='header'?'w-[90px] ': "w-[150px] ", " flex items-center justify-between text-gray-700 bg-white border border-none rounded-md shadow-sm px-1 py-2 hover:bg-gray-100 focus:outline-none focus:ring-0 focus:ring-none relative"]} onClick={handleClick}>
          <div className=' flex items-center'>
          {selectedLanguage.short_code && (
            <img src={"https://flagcdn.com/20x15/"+selectedLanguage.short_code.toLocaleLowerCase()+".png"} width="20" height="15" alt={selectedLanguage.short_code} />            
          )}
          <span  className="ml-1">
            {
              selectedLanguage.short_code ? 
                position ==='header'?
                selectedLanguage.language_code.toUpperCase() 
                :
                selectedLanguage.name
              :
                (
                  <span className='flex items-center'>  
                    <img className='mr-1' src={languageSVG} alt="Language icon" style={{width:'20px'}} /> 
                    <span>{position ==='header'?'EN':'English'}</span>              
                  </span>
                )
            }
          </span>
          </div>
          <span>
            {
              position ==='header'?
                isOpen && <span className="ml-1">&#8595;</span>
              :
                (<img className='mr-1' src={caretDownSVG} alt="Language icon" style={{width:'12px'}} /> )
            }
          </span>           
        </button>
        {isOpen && (
          <ul className={['absolute z-50 w-[190px] w-[300px] bg-white shadow-md rounded-md h-[50vh] mt-1 overflow-y-scroll ', (position==='header')?' top-full left-[-40%] ':' top-[-360px] left-[-10%]']} >
            {countries.map((item) => (
              <li key={item?.language_code} className="text-gray-700 py-2 px-4 flex items-center hover:bg-gray-100 cursor-pointer" onClick={() => handleSelect(item)}>                
                <img className='mr-1' src={"https://flagcdn.com/20x15/"+item.short_code.toLocaleLowerCase()+".png"} width="20" height="15" alt={item.value}  />
                {item.name}
              </li>
            ))}
          </ul>
        )}
      </div>

      {
        position === 'header'?
          <> 
            <div ref={translateRef} id={customId} className="google_translate_iframe hidden"></div>
            <style>{`.google_translate_iframe iframe { display: none; }`}</style>
          </>
        :''
      }
    </div>
  );
};

export default GoogleTranslate;
