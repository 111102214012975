import { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import classes from "./Team.module.css";
import remoteIcon from "../../assets/Icon/remote.webp";
import balanceIcon from "../../assets/Icon/balance.webp";
import communicationIcon from "../../assets/Icon/communication.webp";
import MeetTeamImage from "../../assets/Images/proxyclick-visitor-management-system-s86WhGhp25Y-unsplash 1.webp";
import TeamMemberCard from "../../components/MeetTheTeam/TeamMemberCard";
import { teamList } from "../../lib/static/teamList";
import "./Team.module.css";
import { useTranslation } from "react-i18next";
import TeamImage from "../../assets/Images/image25.jxl";
import setPageDescription from "../../lib/utils/setPageDescription";

export default function Team() {
  const Avatar = "assets/Images/profile-images/Avatar.webp";
  const Flag_Avatar = "assets/Images/flags/Flag.jpg";
  const [navbuttons, setNavbuttons] = useState();
  const { t } = useTranslation();
  useEffect(() => {
    document.title = "The Heart Of The Company | Paramount Students";
    setNavbuttons(document.getElementById("navbuttons"));

    setPageDescription(
      "Meet the team. Let us introduce you to our multicultural and diverse team of experts. We are a fully remote-based team from all around the globe."
    );
  }, []);
  const handleClickScroll = (e) => {
    const element = document.getElementById(e + "sec");

    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      // 👇 Will scroll smoothly to the top of the next section
    }
  };

  let sticky = 1051;

  navbuttons !== null && navbuttons !== undefined
    ? (sticky = navbuttons.offsetTop)
    : (sticky = "1051");

  function myscroll() {
    if (window.pageYOffset > sticky) {
      navbuttons.classList.add("md:sticky", "md:top-[90px]", "top-4");
    } else {
      navbuttons.classList.remove("md:sticky", "md:top-[90px]", "top-4");
    }
  }
  window.onscroll = function () {
    myscroll();
  };
  return (
    <Layout transparentHeaderOnPhone>
      <main className="page services-page">
        <section className="section meet-the-team-section  bg-[#080808]">
          {/* our-team-section */}
          <div className=" text-center py-20 px-10 md:px-16">
            <img
              src={MeetTeamImage}
              alt="A group of employees sitting in an office"
              className=" rounded-xl md:rounded-[32px] w-full"
            />
            <picture className="h-20 w-40">
              <img
                src={TeamImage}
                alt=""
                className=" rounded-xl md:rounded-[32px] w-full"
              />
              <source srcset={TeamImage} type="image/jxl" />
            </picture>
            <div className="our-team flex md:flex-row flex-col md:justify-between justify-start text-white md:px-[68px] px-[34px]  text-left md:gap-10 gap-5 md:mt-[122px] mt-[100px]">
              <div className="md:w-[45%] w-full">
                <h3 className="relative leading-6 mb-5 font-normal md:text-[22px] text-[12px] after:bg-white after:top-1/2 after:content-[''] after:h-[1px] after:md:w-[200px] after:w-[55%] after:absolute after:left-[120px]">
                  {t("OUR TEAM")}
                </h3>
                <h2 className="text-title1 capitalize leading-[120%]" id="">
                  {t("The culture we have")}
                </h2>
              </div>
              <div className={classes["green-blur-effect"]}></div>
              <div className="md:w-[55%]  w-full md:ml-52">
                <p className=" ourteamtext leading-8 font-normal md:text-[20px] text-xs md:ml-auto ml-0 md:max-w-[51ch] max-w-[100%]">
                  {t(
                    "We are a remote team with members and collaborators working together from all over the world to provide a valuable global perspective on our work"
                  )}
                </p>
              </div>
            </div>
          </div>
          {/* nav-sevciton */}

          <div id="navbuttons" className="z-40">
            <div className="flex flex-wrap  justify-start xl:justify-between md:justify-start gap-6 md:ml-16 mx-9">
              <div
                className={classes["nav-buttons"]}
                onClick={() => handleClickScroll("ceo")}>
                <p className="font-normal  leading-[23px]" id="ceo">
                  {t("Centerpiece")}
                </p>
              </div>
              <div
                className={classes["nav-buttons"]}
                onClick={() => handleClickScroll("marketing")}>
                <p className="font-normal  leading-[23px]" id="marketing">
                  {t("Marketing")}
                </p>
              </div>
              <div
                className={classes["nav-buttons"]}
                onClick={() => handleClickScroll("sales")}>
                <p className="font-normal  leading-[23px]" id="sales">
                  {t("Sales")}
                </p>
              </div>
              <div
                className={classes["nav-buttons"]}
                onClick={() => handleClickScroll("technology")}>
                <p className="font-normal  leading-[23px]" id="technology">
                  {t("Technology")}
                </p>
              </div>

              <div
                className={classes["nav-buttons"]}
                onClick={() => handleClickScroll("hr")}>
                <div className="w-fit">
                  <p className="font-normal  leading-[23px] min-w-fit" id="hr">
                    {t("Human Resources")}
                  </p>
                </div>
              </div>

              <div
                className={classes["nav-buttons"]}
                onClick={() => handleClickScroll("finance")}>
                <div className="w-fit">
                  <p
                    className="font-normal  leading-[23px] min-w-fit"
                    id="finance">
                    {t("Finance")}
                  </p>
                </div>
              </div>

              <div
                className={classes["nav-buttons"]}
                onClick={() => handleClickScroll("product")}>
                <p className="font-normal  leading-[23px]" id="product">
                  {t("Product")}
                </p>
              </div>

              <div
                className={classes["nav-buttons"]}
                onClick={() => handleClickScroll("creativity")}>
                <p className="font-normal  leading-[23px]" id="creativity">
                  {t("Creativity")}
                </p>
              </div>

              <div
                className={classes["nav-buttons"]}
                onClick={() => handleClickScroll("mentors")}>
                <p className="font-normal  leading-[23px]" id="mentors">
                  {t("Mentors")}
                </p>
              </div>

              <div
                className={classes["nav-buttons"]}
                onClick={() => handleClickScroll("studentsexperience")}>
                <p
                  className="font-normal  leading-[23px]"
                  id="studentsexperience">
                  {t("Students Experience")}
                </p>
              </div>
            </div>
          </div>

          {/* team-members-section */}

          <div id="ceosec" className="text-center">
            <h2 className={`${classes["members-section"]}`}>
              {t("Centerpiece")}
            </h2>

            <div
              className={`${classes["member-card-container"]} scrollbar-hide `}>
              {teamList.slice(0, 6).map((item, index) => {
                return (
                  <TeamMemberCard
                    key={index}
                    name={item.name}
                    designation={item.designation}
                    image={item.image()}
                    hobby={item.hobby}
                    flag={item.flag()}></TeamMemberCard>
                );
              })}
            </div>
          </div>

          <div id="partnerssec">
            <h2 className={classes["members-section"]}>
              {t("Paramount Students Coordinators")}
            </h2>

            <div
              className={`${classes["member-card-container"]} scrollbar-hide `}>
              {teamList
                .filter((person) => person.department === "Partner")
                .map((item) => (
                  <TeamMemberCard
                    name={item.name}
                    designation={item.designation}
                    image={item.image()}
                    hobby={item.hobby}
                    flag={item.flag()}
                  />
                ))}
            </div>
          </div>

          {/* Sales Section */}
          <div className="" id="salessec">
            <h2 className={classes["members-section"]}>{t("Sales")}</h2>

            <div className={classes["member-card-container"]}>
              {teamList
                .filter((person) => person.department === "Sales")
                .map((item) => (
                  <TeamMemberCard
                    name={item.name}
                    designation={item.designation}
                    image={item.image()}
                    hobby={item.hobby}
                    flag={item.flag()}
                  />
                ))}
            </div>
          </div>

          {/* Technology section */}
          <div id="technologysec">
            <h2 className={classes["members-section"]}>{t("Technology")}</h2>

            <div
              className={`${classes["member-card-container"]} scrollbar-hide`}>
              {teamList
                .filter((person) => person.department === "Technology")
                .map((item) => (
                  <TeamMemberCard
                    name={item.name}
                    designation={item.designation}
                    image={item.image()}
                    hobby={item.hobby}
                    flag={item.flag()}
                  />
                ))}
            </div>
          </div>

          {/* Human resource section */}
          <div id="hrsec">
            <h2 className={classes["members-section"]}>
              {t("Human Resource")}
            </h2>
            <div
              className={`${classes["member-card-container"]} scrollbar-hide`}>
              {teamList
                .filter((person) => person.department === "Human Resource")
                .map((item) => (
                  <TeamMemberCard
                    name={item.name}
                    designation={item.designation}
                    image={item.image()}
                    hobby={item.hobby}
                    flag={item.flag()}
                  />
                ))}
            </div>
          </div>

          {/* Finance section */}
          <div id="financesec">
            <h2 className={classes["members-section"]}>{t("Finance")}</h2>
            <div
              className={`${classes["member-card-container"]} scrollbar-hide`}>
              {teamList
                .filter((person) => person.department === "Finance")
                .map((item) => (
                  <TeamMemberCard
                    name={item.name}
                    designation={item.designation}
                    image={item.image() === "" ? Avatar : item.image()}
                    hobby={item.hobby}
                    flag={item.flag() === "" ? Flag_Avatar : item.flag()}
                  />
                ))}
            </div>
          </div>

          {/* Product Section*/}
          <div id="productsec">
            <h2 className={classes["members-section"]}>{t("Product")}</h2>

            <div
              className={`${classes["member-card-container"]} scrollbar-hide`}>
              {teamList
                .filter((person) => person.department === "Product")
                .map((item) => (
                  <TeamMemberCard
                    name={item.name}
                    designation={item.designation}
                    image={item.image()}
                    hobby={item.hobby}
                    flag={item.flag()}
                  />
                ))}
            </div>
          </div>

          {/* Creative section */}
          <div id="creativitysec">
            <h2 className={classes["members-section"]}>{t("Creativity")}</h2>
            <div
              className={`${classes["member-card-container"]} scrollbar-hide`}>
              {teamList
                .filter((person) => person.department === "Creativity")
                .map((item) => (
                  <TeamMemberCard
                    name={item.name}
                    designation={item.designation}
                    image={item.image() === "" ? Avatar : item.image()}
                    hobby={item.hobby}
                    flag={item.flag() === "" ? Flag_Avatar : item.flag()}
                  />
                ))}
            </div>
          </div>

          {/* Mentors section */}
          <div id="mentorssec">
            <h2 className={classes["members-section"]}>{t("Mentors")}</h2>
            <div
              className={`${classes["member-card-container"]} scrollbar-hide`}>
              {teamList
                .filter((person) => person.department === "Creative")
                .map((item) => (
                  <TeamMemberCard
                    name={item.name}
                    designation={item.designation}
                    image={item.image()}
                    hobby={item.hobby}
                    flag={item.flag() === "" ? Flag_Avatar : item.flag()}
                  />
                ))}
            </div>
          </div>
          {/* Student Experience section */}
          <div id="studentsexperiencesec">
            <h2 className={classes["members-section"]}>
              {t("Student Experience")}
            </h2>
            <div
              className={`${classes["member-card-container"]} scrollbar-hide`}>
              {teamList
                .filter((person) => person.department === "Student Experience")
                .map((item) => (
                  <TeamMemberCard
                    name={item.name}
                    designation={item.designation}
                    image={item.image() === "" ? Avatar : item.image()}
                    hobby={item.hobby}
                    flag={item.flag() === "" ? Flag_Avatar : item.flag()}
                  />
                ))}
            </div>
          </div>
        </section>
        <section className={classes.culture}>
          <div className={classes.container}>
            <h1>{t("Maintaining Great, Virtual Teamwork Across The Globe")}</h1>
            <div>
              <div
                className={classes["culture-details"]}
                style={{
                  background: "#5D5DFC40",
                  padding: "50px 30px",
                  borderRadius: "20px",
                }}>
                <div>
                  <img src={remoteIcon} alt="" />
                </div>
                <h2>{t("Working Remotely")}</h2>
                <p>
                  {t(
                    "For us, remote work is fundamental to our business model and our way of life. We have globally scattered teams collaborating across time zones while maintaining a physical central office on the Istanbul, Turkey"
                  )}
                </p>
              </div>
              <div
                className={classes["culture-details"]}
                style={{
                  background: "#FF693340",
                  padding: "50px 30px",
                  borderRadius: "20px",
                }}>
                <div>
                  <img src={balanceIcon} alt="" />
                </div>
                <h2>{t("Work-Life Balance")}</h2>
                <p>
                  {t(
                    "The freedom to work flexible hours and maintain a healthy work-life balance. At PS, we uphold Mon to Fri 40-hour workweeks, off days on national holidays, and vacation time to spend with your friends and family"
                  )}
                  .
                </p>
              </div>
              <div
                className={classes["culture-details"]}
                style={{
                  background: "#11BA6E40",
                  padding: "50px 30px",
                  borderRadius: "20px",
                }}>
                <div>
                  <img src={communicationIcon} alt="" />
                </div>
                <h2>{t("Communication")}</h2>
                <p>
                  {t(
                    "Communication is integral to running a successful remote creative team. We have found that transparency, clear expectations, and constant communication are the pillars of successful teamwork"
                  )}
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
}
