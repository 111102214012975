import DOMPurify from "dompurify";
import Container from "../../Layout/Container";
import { ReactComponent as UserOctagonIcon } from "../../../assets/Icon/user-octagon.svg";
import InstagramIcon from "../../../assets/Icon/instagram.png";
import LinkenInIcon from "../../../assets/Icon/linkedin.png";
import FacebookIcon from "../../../assets/Icon/facebook-icon.png";
import { useTranslation } from 'react-i18next';

const CommunitySection = ({ className, data }) => {
  const platformIcons = {
    Instagram: InstagramIcon,
    LinkedIn: LinkenInIcon,
    Facebook: FacebookIcon,
  };

  const platformIconClasses = {
    Instagram: "w-8 md:w-10",
    LinkedIn: "w-[26px] md:w-[34px]",
    Facebook: "w-[26px] md:w-[34px]",
  };
  const { t} = useTranslation();
  return (
    <section
      className={`section community-section w-full relative flex justify-center ${className}`}
    >
      <Container maxWidth="xl">
        <div
          className={`community-section-container my-16 md:my-28 flex flex-col-reverse ${
            data?.alternate
              ? "md:flex-row-reverse"
              : "md:flex-row md:space-x-12"
          } md:items-start`}
        >
          <div className="community-section-left mt-10 md:mt-0 flex flex-col flex-1 md:flex-[0.45] items-center rounded-3xl overflow-hidden">
            <img
              src={data?.communityBanner}
              className="w-full z-[1]"
              alt={data?.bannerAltText}
            />
          </div>
          <div
            className={`community-section-right flex-1 md:flex-[0.55] md:py-2 ${
              data?.alternate && "mr-12"
            }`}
          >
            <div className="community-section-logo">
              <img
                src={data?.communityLogo}
                alt={data?.communityName}
                className={`${data?.logoClassName}`}
              />
            </div>
            <p
              className={`font-normal text-sm mt-5 md:mt-8 md:text-xl leading-5 md:leading-8 text-text-primary`}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(t(data?.communityDesc)),
              }}
            />

            <div className="mt-8 mb-12 bg-black w-full min-h-[1px]" />

            <div className="community-section-head flex justify-between">
              <div>
                <div className="community-section-social-links flex items-center space-x-2 md:space-x-8">
                  {data?.socialLinks.map((item,index) => (
                    <a
                      href={item?.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      key={index}
                    >
                      <img
                        src={platformIcons[item.platform]}
                        alt={item.platform}
                        className={platformIconClasses[item.platform]}
                      />
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default CommunitySection;
