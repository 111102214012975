export const aboutFAQs = [
  {
    title: "Our Mission",
    description:
      "Our mission at Paramount Students is to develop a management system that assures a sophisticated student recruitment process. This ensures high-quality services that will help high schools create a verified student base and allow universities to save 50% of their time answering questions",
  },
  {
    title: "Our Products",
    description:
      "The Paramount Student mobile app provides a platform with the ability to create an authentic student experience. Authenticated students are eligible to apply through the smartphone app for paramount students, the app also guides you through the process of selecting a university that provides a strong atmosphere that fosters your success and there is an opportunity to select adequate accommodation from the convenience of your home. We are working on a centralized platform for handling educational documents such as transcripts and exam scores. Because this information will be shared with other institutions, there is no need to be concerned about privacy. The student data collected on the Paramount Student’s app can also be utilized to apply for future university admissions/transfers",
  },
  {
    title: "Our Services",
    description: `Paramount Students is a higher Ed-tech marketplace and student-centered network for international students, high school counselors, and colleges. We are the new go-to site for education because we build bridges that open up new opportunities for local and international audiences, connecting students all over the world. By connecting students with academic resources and financial options for extracurricular programs, we enable all parties to engage in the worldwide educational process. We provide students a "virtual reality" tour of their prospective institutions, allowing you to have a 360 view of the school’s site to see how the location actually look like. We understand how difficult it may be to settle into a new community, but don't worry, we've thought about that and offer you the option to choose an accommodation by browsing through the various options we've selected specifically for you on our mobile app.`,
  },
  {
    title: "Our Resources",
    description: `Paramount students collaborate with top Universities, Mentors, Talents (Team members), High schools, Communities, and House owners from across the world to make your study abroad dream a reality and also glitch-free. Our affiliated Universities are among the best in their various countries and we also partnered with different educative, fun, healthful, informative and interest-based groups to provide once-in-a-lifetime experience in your new environment. We are also backed up by great, international mentors that provides helpful insights for the platform and our team is made up of different intellectuals from across the globe who come together to achieve the set goals and objectives of the platform`,
  },
];
