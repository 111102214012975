export const footerLinks = [
  {
    platform: "Facebook",
    link: "https://www.facebook.com/paramount.students1",
  },
  {
    platform: "Twitter",
    link: "https://twitter.com/ParamountStds?s=20&t=K1-lAi_aC7eioKcRvIKcWg",
  },
  {
    platform: "Instagram",
    link: "https://www.instagram.com/paramount.students/",
  },
  {
    platform: "LinkedIn",
    link: "https://www.linkedin.com/company/paramount-student/",
  },
  {
    platform: "TikTok",
    link: "https://www.tiktok.com/@paramountstudents",
  },
];
