import { useLocation, useMatch, useResolvedPath } from 'react-router-dom';

export const useIsPathActive = (to) => {
  const resolved = useResolvedPath(to);

  const isMatch = useMatch({
    caseSensitive: false,
    end: false,
    path: resolved.pathname,
  });

  const location = useLocation();

  const splittedPathname = location.pathname.split('/');

  const isIndex = splittedPathname.every((item) => item === '');

  return {
    isMatch,
    isIndex,
  };
};
