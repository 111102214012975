import React from 'react';

const CommunitiesHero = () => {
  return (
    <section className="section hero-section communities-hero-section bg-background7">
      <div className="communities-hero-content flex max-w-full relative overflow-x-hidden overflow-y-clip h-[20rem] sm:h-[44rem] bg-[url('/src/assets/Images/your-journey-starts-here-sm.png')] md:bg-[url('/src/assets/Images/your-journey-starts-here.png')] bg-no-repeat bg-cover"></div>
    </section>
  );
};

export default CommunitiesHero;
