import React, { useEffect } from "react";
import Products from "../../components/Home/Products";
import Layout from "../../components/Layout";
import useWindowSize from "../../hooks/useWindowSize";
import "./Products.css";

import Call from "../../assets/Icon/whiteCall.svg";
import heroImage from "../../assets/Images/productsHero.webp";
import mobileHeroImage from "../../assets/Images/mobileProductsHero.webp";
import { useTranslation } from "react-i18next";
import SmoothScroll from "../../components/App/SmoothScroll";
import setPageDescription from "../../lib/utils/setPageDescription";

const ProductPage = () => {
  // const [offsetY, setOffsetY] = useState();

  // const handleScroll = () => {
  //   setOffsetY(window.pageYOffset);
  // };
  useEffect(() => {
    document.title = "Discover Our Portfolio | Paramount Students";

    setPageDescription(
      "Discover. Explore. Apply. Studying abroad has never been this easy. Join us so we lighten the burden on students, high schools, and universities through a simple and efficient process."
    );

    // window.addEventListener("scroll", handleScroll);

    // return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const size = useWindowSize();
  const { t } = useTranslation();
  return (
    <div>
      <Layout
        transparentHeaderOnPhone={size?.width < 900}
        className="homepage-layout">
        <main className="page products-page">
          <SmoothScroll />
          <section>
            <div className="hero">
              <div className="products-hero-image mb-20 relative">
                <img
                  loading="lazy"
                  className="w-full h-[705px] hidden md:block "
                  src={heroImage}
                  alt="a user portal featuring a black cloud on a black background"
                />
                <img
                  loading="lazy"
                  className="w-full h-[810px] md:hidden"
                  src={mobileHeroImage}
                  alt="hea user portal featuring a black cloud on a black backgroundro"
                />
              </div>

              <div className="products-hero-text text-start md:text-start absolute top-[60px] mt-[40px] md:mt-[100px] md:left-[10%] px-15 md:px-1 ">
                <div className="products-hero-texts sm:max-lg:px-20">
                  <div className="products-hero-heading">
                    <h1 className="uppercase md:capitalize md:w-[625px]">
                      {t("Give centralized products")}
                    </h1>
                  </div>
                  <div className="hero-mini-text">
                    <p className=" text-[#f2f2f2] px-2 mt-5 mb-10 font-normal text-xs md:w-[572px] md:text-[20px] ">
                      {t(
                        "Paramount Students takes the pain out of studying abroad for both students and universities. All courses across the country in one view,"
                      )}
                    </p>
                  </div>
                </div>
                <div className="buttons ">
                  {/* <button className="bannerBtn1  bg-[#FFC400] ">
                    See how it works{" "}
                    <img loading="lazy" src={Play} alt="arrow" />
                  </button> */}
                  {/* <button className="bannerBtn2 bg-transparent"> */}
                  <a
                    className="bannerBtn2 bg-transparent text-white items-center flex gap-[9px]"
                    href="mailto:khalid@paramountstudents.com">
                    {t("Contact Us")}{" "}
                    <img loading="lazy" src={Call} alt="phone" />
                  </a>
                  {/* </button> */}
                </div>
              </div>
            </div>

            <div className="products-section bg-[#fafafa]">
              <Products />
            </div>
          </section>
        </main>
      </Layout>
    </div>
  );
};

export default ProductPage;
