import { Fragment } from "react";
import { XIcon } from "@heroicons/react/outline";
import { Popover, Transition } from "@headlessui/react";
import { ReactComponent as ParamountStudentLogo } from "../../../../assets/Icon/paramount-logo-mobile-menu.svg";
import { headerNavigation } from "../../../../lib/static/headerNavigation";
import MobileMenuItem from "./MobileMenuItem";
import { Link } from "react-router-dom";
/* import BritianFlag from "../../../../assets/Icon/Flags.webp";
import { Dropdown } from 'antd'; */
/* import { useTranslation } from 'react-i18next'; */
/* import ENFlag from "../../../../assets/Icon/Flags.webp";
import TRFlag from "../../../../assets/Icon/turkey-flag.webp";
import FRFlag from "../../../../assets/Icon/Flag_of_France.webp";
import ARFlag from "../../../../assets/Icon/AR Flag.webp";
 */
//import { Button } from 'antd';
//import { useSelector } from 'react-redux';
//import { useDispatch } from 'react-redux';
//import { changeFlag, changeLang } from "../../../../redux/langSlice";

const MobileMenu = ({ onClose }) => {  


/*   var language=useSelector((state)=>state.lang.lang)
  var flag=useSelector((state)=>state.lang.langflag) */
  
  //const dispatch=useDispatch();
/*   const onClick = ({key}) => {
    
    var selectedflags=items.find(element=> element.key==key)
    
    dispatch(changeFlag(selectedflags))
    dispatch(changeLang(key))
    const language=key.toLowerCase()    
    i18n.changeLanguage(language)
    
  }; */
/*   const items = [
    
    {
      key: "EN",
      id:"ENGLISH",
      label: (
        <p className="font-bold ml-[0.75rem] my-1 text-lg leading-[1.31rem] ">
          EN
          <img className="w-[1.31rem] h-4 ml-2" src={ENFlag} alt="" />
        </p>
      ),
      image:"/assets/Icon/Flags.webp",
    },
    {
      key: "TR",
      id:"TURKISH",
      label: (
        <p className="font-bold ml-[0.75rem] my-1 text-lg leading-[1.31rem] ">
          TR
          <img className="w-[1.31rem] h-4 ml-2" src={TRFlag} alt="" />
        </p>
        
      ),
      image:"/assets/Icon/turkey-flag.webp",
    },
    
    {
      key: "FR",
      id:"FRENCH",
      label: (
        <p className="font-bold ml-[0.75rem] my-1 text-lg leading-[1.31rem] ">
          FR
          <img className="w-[1.31rem] h-4 ml-2" src={FRFlag} alt="" />
        </p>

      ),
      image:"/assets/Icon/Flag_of_France.webp",
    },
    {
      key: "AR",
      id:"ARABIC",
      label: (
        <p className="font-bold ml-[0.75rem] my-1 text-lg leading-[1.31rem] ">
          AR
          <img className="w-[1.31rem] h-4 ml-2" src={ARFlag} alt="" />
        </p>

      ),
      image:"/assets/Icon/AR Flag.webp",
    },
  ]; */
  return (
    <Transition
      as={Fragment}
      enter="duration-200 ease-out"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="duration-100 ease-in"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      <Popover.Panel
        focus
        className="z-50 absolute top-0 bottom-0 w-full inset-x-0 transition transform origin-top-right md:hidden"
      >
        <div className="bg-white min-h-screen divide-y-2 divide-gray-600 flex flex-col">
          <div className="mobile-menu-header min-h-[80px] flex items-center px-3">
            <div className="w-full flex items-center justify-between mt-1.5">
              <div>
                <Link to="/">
                  <ParamountStudentLogo />
                </Link>
              </div>
              <div>
                <Popover.Button
                  className="outline-none border-none bg-transparent cursor-pointer"
                  onClick={onClose}
                >
                  <span className="sr-only">Close menu</span>
                  <XIcon className="h-8 w-8" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
          </div>
          <div className="px-4 mt-16 mb-8">
            <nav>
              <ul className="list-none">
                {headerNavigation.map((navItem) => (
                  <MobileMenuItem
                    to={navItem.href}
                    key={navItem.href}
                    isButton={navItem?.button}
                    external={navItem?.external}
                    className="capitalize"
                  >
                    {navItem.label}
                  </MobileMenuItem>
                ))}
              </ul>
            </nav>
            {/* language cta visible - Contact btn made hidden*/}
            {/* <div className="flex lang-widget cursor-pointer items-center justify-center bg-[#f4f4f4] rounded-full space-x-2 p-2 py-4 px-3 w-40">
              <p className="font-medium text-[18px] uppercase">EN</p>
              <img src={BritianFlag} alt="flag" />
            </div> */}
      {/*       <Dropdown
            className="md:hidden mt-4 flex cursor-pointer gap-2 justify-between items-center mr-[2.62rem] rounded-full border-white w-20 h-9font-bold bg-[#F4F4F4] text-black"
            menu={{
              items,
              selectable: true,
              defaultSelectedKeys: [language],
              onClick,
            }}
            placement="bottom"
            arrow
          >
            <Button className="text-white flex gap-1 justify-center ">
              
              {language}
              {flag!==""?<img className="w-[1.31rem] h-4 ml-2" src={flag.image} alt="" />:<img className="w-[1.31rem] h-4 ml-2" src={require("../../../../assets/Icon/Flags.webp")} alt="" />}
            </Button>
          </Dropdown> */}
          </div>
        </div>
      </Popover.Panel>
    </Transition>
  );
};

export default MobileMenu;
