import Arrow from '../../../assets/Icon/blueArrowRight.svg';
import { MdArrowForward, MdArrowBack } from 'react-icons/md';

import contentImage from '../../../assets/Images/image 25.webp';
import contentImage2 from '../../../assets/Images/aboutUsMission.webp';
import contentImage3 from '../../../assets/Images/ourStory.png';

import './AboutUs.css';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
// import "@splidejs/react-splide/css";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const AboutUs = () => {
  const { t } = useTranslation();
  const carouselContents = [
    {
      title: 'Our Story',
      description: "We at <span class='notranslate'>Paramount Students</span> are dedicated to helping students reach their full potential. Our story is driven by the belief that every student should have access to tertiary education and life-changing experiences that will shape their future. We understand the challenges and excitement of studying abroad, and we're here to make the process seamless. Our dedicated team of professionals offers customized guidance and support to each student along the way to ensure they find the university program that best suits their educational and career objectives",
      image: contentImage3,
      alttext:
        "our story",
    },
    {
      title: 'What is paramount students?',
      description: 'Home about desc1',
      image: contentImage,
      alttext: 'a group of people sitting around a table with laptops ',
    },
    {
      title: 'Our Mission',
      description: 'Home about desc2',
      image: contentImage2,
      alttext: 'A meeting of people sitting around a table ',
    },
  ];
  return (
    <div className="about-us relative mt-16 md:mt-28">
      <div className="title mb-4">
        <span className="hr-sect text-lg">{t('About Us')}</span>
      </div>

      <div className='px-2 mb-4 md:mb-16'>
        <h1 className='text-center text-2xl md:text-[2.5rem]'>
          {t('Study Abroad Experts')} <br />{' '}
          {t('Empowering Students to Reach Their Global Potential')}
        </h1>
      </div>

      <Splide
        hasTrack={false}
        aria-label="..."
        options={{
          perPage: 1,
          arrows: true,
          pagination: false,
          drag: 'free',
          type: 'loop',
          breakpoints: {
            768: {
              perPage: 1,
            },
          },
        }}
      >
        <SplideTrack>
          {carouselContents.map((content, index) => {
            return (
              <SplideSlide key={index}>
                <div className="content p-4 md:grid md:grid-cols-2 md:gap-12 md:p-0 ">
                  <div className="content-wrap bg:inherit md:h-[424px] md:mt-[40px]">
                    <div className="content-wrapper md:pl-36 md:pr-10">
                      <div className="mb-4">
                        <h4 className='text-2xl md:text-4xl font-medium'>{t(content.title)}</h4>
                      </div>
                      <hr className="aboutHr" />
                      <div className="content-text">
                        <p className="text-sm leading-5 md:text-[18px] md:leading-[28px]" dangerouslySetInnerHTML={{ __html: content.description }}>                        
                        </p>
                      </div>
                      <div className="content-btn w-fit">
                        <Link
                          to="/about-us"
                          className=" hover:underline hover:opacity-90"
                        >
                          {t('Learn More')} <img src={Arrow} alt="arrow" loading="lazy" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="content-gallery">
                    <div className="gallery-img">
                      <img
                        loading="lazy"
                        src={content.image}
                        className="w-full"
                        alt={content.alttext}
                      />
                    </div>
                  </div>
                </div>
              </SplideSlide>
            );
          })}
        </SplideTrack>
        <div className="splide__arrows">
          <button
            className={'splide__arrow splide__arrow--prev hover:bg-white'}
          >
            <span className="flex">
              <MdArrowBack className="w-6 h-6 xl:w-8 xl:h-8 fill-[white!important]" />
            </span>
          </button>
          <button className="splide__arrow splide__arrow--next md:hover:bg-black">
            <span className="flex">
              <MdArrowForward className="md:hover:fill-[white!important] w-6 h-6 xl:w-8 xl:h-8 " />
            </span>
          </button>
        </div>
      </Splide>

      {/* <AboutCarousalSection /> */}
    </div>
  );
};

export default AboutUs;
