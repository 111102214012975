import React, { useEffect, useState } from 'react';

import Layout from '../../components/Layout';
import PostCard from '../../components/Blog/PostCard';
import { usePaginationPages } from '../../hooks/usePaginationPages';
import PaginationPages from '../../components/Generic/PaginationPages';
import { Link } from 'react-router-dom';
import { fetchData } from '../../api/axios';
import { useNavigate, useParams } from 'react-router-dom';
import './css.css';

const ReadBlog = () => {
  const [posts, setPosts] = useState([]);
  const [post, setPost] = useState({});
  const { blogId } = useParams();  
  const history = useNavigate();
  const openPost = (id) => {    
      history(`/blog/${id}`);
      getPostData(id)
    };
    
  
  const getPostData =(id)=>{
    fetchData('/api/blog/'+id)
      .then((data) => {        
        setPost(data.data); // Update posts state with fetched data
        setPosts(data.latests); // Update posts state with fetched data
      })
      .catch((error) => {
        console.log('Error fetching blog content:', error);
      });
  }
  useEffect(() => {
    document.title = 'Blog | Paramount Students';
    
    getPostData(blogId)
  }, []);

  return (
    <Layout transparentHeaderOnPhone>
      <main className="page">
        <section className="mb-20 md:mb-28 md:mt-28">
        <div className="max-w-screen-lg mx-auto md:px-8">
            <div className="flex flex-col-reverse md:flex-col gap-8 mb-8 md:mb-16">
              <div className="px-4 md:px-0 md:max-w-screen-sm mx-auto md:mx-0">
                <span className="text-xs mb-3 inline-block">
                  {post?.created_at} · less than {post?.estimatedReadTime} min read
                </span>
                <h2 className="text-xl sm:text-2xl md:text-3xl mb-3 capitalize font-bold">
                  {post?.title}
                </h2>
                {/* <p className="text-xs sm:text-sm leading-5">
                  Would Walter White have received better counsel from an AI
                  instead of Saul Goodman......
                </p> */}
              </div>
              <div style={{height:'450px',backgroundImage:`url(${post?.bannerImage})`,backgroundSize:'cover',backgroundRepeat:'no-repeat', backgroundPosition:'10% center', width:'100%'}}               
              alt=" AI will be used as legal assistant in court for first time
              ever in February"
            className="md:rounded-tr-[5rem] md:rounded-bl-[5rem] w-full"
                >              
              </div>
            </div>
            {/* Possible newsletter component */}
            {/* <div></div> */}

            <div className="mt-8 md:mt-12 px-4 md:px-0  sm:mx-auto bdx">
              {/* Blog Html Body */}
              <p className="leading-6 text-sm md:text-base"  dangerouslySetInnerHTML={{ __html: post?.htmlBody }} >
                </p>
            </div>
          </div>
        </section>
        <section className="my-20 md:my-28 bg-white">
          <div className="max-w-screen-lg mx-auto px-4 sm:px-8 py-8">
            <h3 className="mb-6 font-medium text-xl">You might also like...</h3>
            {/* Similar Posts */}
            <div className="flex sm:gap-x-[4%] md:gap-x-[3.5%] gap-y-10 flex-wrap">
              {posts?.map((post,index) => (
                <div
                  key={post.id}
                  className="max-w-full sm:max-w-[48%] md:max-w-[31%]"
                >                  
                     <div style={{'cursor':'pointer', }} aria-label={post.title} onClick={()=>{openPost(post?.id)}} className='text-inherit'>
                    <PostCard
                      title={post.title}
                      dateString={post.created_at}
                      bannerImage={post.bannerImage}
                    />
                  </div>
                </div>
              ))}
            </div>

            {/* Pagination */}
           {/*  <div className="mt-16">
            <PaginationPages
                data={posts}
              />
            </div> */}
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default ReadBlog;
