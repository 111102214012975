import axios from "axios";
import { showModal } from "../../lib/modal";

const apiClient = axios.create({
  //baseURL: "http://localhost:8000",
  baseURL: "https://crm.paramountstudents.com",
 headers: {
    "Content-Type": "application/json",
 },
});

function toggleLoader(state) {
  var loader = document.getElementById("mainLoader");
  loader.style.display = state;
}

// Function to make a GET request
export async function fetchData(route, showLoader=true) {
   try {
      if(showLoader){
        toggleLoader('flex')
      }
     const response = await apiClient.get(route); // Replace with your actual GET endpoint
     toggleLoader('')
     return response.data;
   } catch (error) {
      if(showLoader){
        displayOk(error)
      }
      toggleLoader('')
      return false
     //throw error;
   }
 }
 
 // Function to make a POST request
 export async function postData(route,data) {
   try {
    toggleLoader('flex')
     const response = await apiClient.post(route, data); // Replace with your actual POST endpoint
     toggleLoader('')
     return response.data;
   } catch (error) {
      if(error?.response?.data){
        displayOk(error.response.data?.error||error.response.data?.message)
      }else{
        displayOk(error)
      }
      toggleLoader('')
     throw error;
   }
 }

 function displayOk(text){
   if(!window.modalOpened){
     showModal({
       text:text,
       confirmText: 'Ok',
       cancelText: 'Cancel',
     })    
   }
 }

 export default axios