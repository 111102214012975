import React from "react";
import PropTypes from "prop-types";
import HeroSection from "./Hero";

const DocsPagesLayout = ({ children, pageTitle }) => {
  return (
    <div className="layout docs-page-layout flex flex-col">
      <HeroSection title={pageTitle} />
      <div className="py-20">{children}</div>
    </div>
  );
};

DocsPagesLayout.propTypes = {
  children: PropTypes.node,
  pageTitle: PropTypes.string,
};

export default DocsPagesLayout;
