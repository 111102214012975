import TurkeyFlag from "../../assets/Icon/TR - Turkey.svg";
import SomaliaFlag from "../../assets/Icon/SO - Somalia.svg";
import PakistanFlag from "../../assets/Icon/PK - Pakistan.svg";
import TanzaniaFlag from "../../assets/Icon/TZ - Tanzania.svg";

import IstanbulIncubationImage from "../../assets/Images/incubation-bottom.webp";
import PakistanIncubationImage from "../../assets/Images/pakistanIncubation.webp";
import TanzaniaIncubationImage from "../../assets/Images/tanzaniaRectangle.webp";
import IstanbulIncubationImage2 from "../../assets/Images/newImage.png";

import UserIcon from "../../assets/Icon/user-icon.svg";
import LocationIcon from "../../assets/Icon/location-icon.svg";

export const incubationCenters = [
  {
    title: "Istanbul, Turkey",
    flag: TurkeyFlag,
    image: IstanbulIncubationImage,
    incubationName: "Incubation Name",
    name: "Bilgiyi Ticarileştirme Merkezi (BTM)",
    userIcon: UserIcon,
    locationIcon: LocationIcon,
    rep: "representative Name",
    repName: "Khalid Bolhala (CEO)",
    location:
      "Fulya, Fulya, Fulya Mahallesi Yeşilçimen Sokak Polat Tower Bağımsız Bölüm 12/430, 34394 Şişli/İstanbul, Turkey",
  },
  {
    title: "Pakistan, Karachi",
    flag: PakistanFlag,
    image: PakistanIncubationImage,
    incubationName: "Incubation Name",
    name: "National Incubation Center Karachi (NICK)",
    userIcon: UserIcon,
    locationIcon: LocationIcon,
    rep: "representative Name",
    repName: "Omar Abdin",
    location:
      "National Incubation Center, NED University, Karachi, Karachi City, Sindh 75270 ",
  },

  {
    title: "Somalia, Magadishu",
    flag: SomaliaFlag,
    image: IstanbulIncubationImage,
    incubationName: "Incubation Name",
    name: "Irise Somali’s Innovation Hub",
    userIcon: UserIcon,
    locationIcon: LocationIcon,
    rep: "representative Name",
    repName: "Khalid Bolhala (CEO)",
    location: " Amira Castel Opposit Pizza House 252 Hodan Mogadishu Somalia",
  },

  {
    title: "Dar Es Salaam, Tanzania",
    flag: TanzaniaFlag,
    image: TanzaniaIncubationImage,
    incubationName: "Incubation Name",
    name: "Amcet Innovation Hub Hq",
    userIcon: UserIcon,
    locationIcon: LocationIcon,
    rep: "representative Name",
    repName: "Khalid Bolhala (CEO)",
    location: "76GG+2M7, Ally Sykes road, Dar es Salaam",
  },
  {
    title: "Istanbul, Turkey",
    flag: TurkeyFlag,
    image: IstanbulIncubationImage2,
    incubationName: "Incubation Name",
    name: "International Incubation Center (IIC)",
    userIcon: UserIcon,
    locationIcon: LocationIcon,
    rep: "representative Name",
    repName: "Khalid Bolhala (CEO)",
    location:
      "Istanbul World Trade Center, A1 Block 8th floor, No: 297 34149, Yeşilköy, Istanbul/Turkey",
  },
];
