export const teamList = [
  {
    name: "Khalid Boulhala",
    designation: "CEO",
    department: "",
    
    hobby:["Idea Machine", "Lasagna Lord", "Salsa Sensation"],
    country: "Morocco",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  {
    name: "Madina Igilik",
    designation: "Intl Business Developer",
    department: "IBD",
     hobby:["Lipstick lover", "Music non-believer", "Ambidextrous writer"],
    country: "Kazakhstan",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  {
    name: "Sevde Esma GÜLEÇ",
    designation: "Relationship Coordinator",
    department: "IBD",
     hobby:["Wanderlust for Indonesia", "Tahini Ice Cream Connoisseur", "Childhood Firefighter Dreamer"],
    country: "Turkey",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  {
    name: "Kenadid Ahmed Osman",
    designation: "School Partnership Coordinator",
    department: "Partner",
     hobby:["Multi-degree achiever", "Somali lit lover", "Furry friend phobia"],
    country: "Somalia",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  {
    name: "Umuhire Ingabire Grace",
    designation: "School Partnership Coordinator",
    department: "Partner",
     hobby:["Stat Storyteller", "Pattern Detective", "Polyglot Dreamer"],
    country: "Rwanda",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  {
    name: "Anthony Gichuki",
    designation: "School Partnership Coordinator",
    department: "Partner",
     hobby:["Football Enthusiast", "Victory Spectator", "Socialpreneur"],
    country: "Kenya",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  {
    name: "Anas Jamani",
    designation: "School Partnership Coordinator",
    department: "Partner",
     hobby:["Entertainment enthusiast", "Multifaceted explorer", "Renaissance mind"],
    country: "Morocco",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  {
    name: "N'guessan Amani Marcel",
    designation: "School Partnership Coordinator",
    department: "Partner",
     hobby:["Jokester", "Challenge Connoisseur", "Smiling Ambassador"],
    country: "Senegal",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  {
    name: "Oluwasegun Adesina",
    designation: "Intl Business Developer",
    department: "IBD",
     hobby:["Towering Smile", "Listening Ear", "Attentive Connector"],
    country: "Nigeria",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  {
    name: "Olorunseun Joshua Ogundeji",
    designation: "Relationship Coordinator",
    department: "Partner",
     hobby:["Leadership", "Dancing", "Highly Approachable"],
    country: "Nigeria",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return 'assets/Icon/test-avatar.svg';
    },
  },
  {
    name: "Janet Olawoye",
    designation: "Relationship Coordinator",
    department: "Partner",
     hobby:["Leadership", "Dancing", "Highly Approachable"],
    country: "Nigeria",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return 'assets/Icon/test-avatar.svg';
    },
  },
  {
    name: "Maryam Akintunde",
    designation: "Relationship Coordinator",
    department: "Partner",
     hobby:["Leadership", "Dancing", "Highly Approachable"],
    country: "Nigeria",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return 'assets/Icon/test-avatar.svg';
    },
  },
  {
    name: "Rayen Ben Khedher",
    designation: "Relationship Coordinator",
    department: "IBD",
     hobby:["Leadership", "Dancing", "Highly Approachable"],
    country: "Tunusia",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  {
    name: "Bathabile Sibanda",
    designation: "Relationship Coordinator",
    department: "Partner",
     hobby:["Leadership", "Dancing", "Highly Approachable"],
    country: "Zimbabwe",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return 'assets/Icon/test-avatar.svg';
    },
  },
  {
    name: "Christina Tomas",
    designation: "Relationship Coordinator",
    department: "Partner",
     hobby:["Leadership", "Dancing", "Highly Approachable"],
    country: "Ethiopian",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return 'assets/Icon/test-avatar.svg';
    },
  },
  {
    name: "David Odepidan",
    designation: "CTO",
    department: "Technical",
     hobby:["Tech Titan","Opinionator","The Nomad"],
    country: "Nigeria",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },

  
  {
    name: "Suhail Panjwani",
    designation: "Head of Marketing & Public Relations",
    department: "Marketing",
     hobby:["Seafood Fan", "Beach Bum", "Car Maniac"],
    country: "Malawi",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  
  
  {
    name: "Hassan Rachael",
    designation: "Content writing",
    department: "Marketing",
     hobby:["Leadership", "Dancing", "Highly Approachable"],
    country: "Nigeria",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.png`;
    },
  },
  {
    name: "Sabeeha Tariq",
    designation: "Content writing",
    department: "Marketing",
     hobby:["Giggle Guru", "Lizardphobe", "Uncontrollable Chuckler"],
    country: "Nigeria",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  {
    name: "Begüm Değer Soy",
    designation: "Translation",
    department: "Marketing",
     hobby:["Self-Talking Linguist", "Teleportation Dreamer", "Yaprak Sarma Enthusiast"],
    country: "Turkey",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  {
    name: "David Ayegba",
    designation: "Quality Assurance",
    department: "Marketing",
     hobby:["Movie Maven", " Bookworm by Night", "Pool Shark."],
    country: "Nigeria",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  
  {
    name: "Sherifat Alabi",
    designation: "Content writing",
    department: "Marketing",
     hobby:["Leadership", "Dancing", "Highly Approachable"],
    country: "Nigeria",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.png`;
    },
  },
  {
    name: "Wajiha Zahid",
    designation: "Head of Social Media",
    department: "Social Media",
     hobby:["Leadership", "Dancing", "Highly Approachable"],
    country: "Pakistan",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return 'assets/Icon/test-avatar.svg';
    },
  },
  {
    name: "Farid Talibli",
    designation: "Social Media Manager",
    department: "Social Media",
     hobby:["Leadership", "Dancing", "Highly Approachable"],
    country: "Azerbaijan",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return 'assets/Icon/test-avatar.svg';
    },
  },
  {
    name: "Merve Altınoluk",
    designation: "Social Media Manager",
    department: "Social Media",
     hobby:["Leadership", "Dancing", "Highly Approachable"],
    country: "Turkey",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return 'assets/Icon/test-avatar.svg';
    },
  },
  {
    name: "Nowar Kurdy",
    designation: "Social Media Manager",
    department: "Social Media",
     hobby:["Leadership", "Dancing", "Highly Approachable"],
    country: "Jordan",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return 'assets/Icon/test-avatar.svg';
    },
  },
  {
    name: "Martina Krstova",
    designation: "Social Media Marketing",
    department: "Social Media",
     hobby:["Basketball player", "Tech wizard", "Foodie extraordinaire"],
    country: "Macedonian/Bulgarian",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  {
    name: "Mustafa ÖRS",
    designation: "FrontEnd Developer",
    department: "Technical",
     hobby:["Wanderlust Coder", "Football Fanatic", "Puzzle Mastermind"],
    country: "Turkey",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
 
   {
    name: "Daniel Iwenya",
    designation: "Backend Laravel/PHP Engineer",
    department: "Technical",
     hobby:["Code Ninja", "Football Maestro", "Double Degree Dynamo"],
    country: "Nigeria",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return 'assets/Icon/test-avatar.svg';
    },
  },
  {
    name: "Ogundowole Raheem Opeyemi",
    designation: "PHP Developer Mobile",
    department: "Technical",
     hobby:["Leadership", "Dancing", "Highly Approachable"],
    country: "Nigeria",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return 'assets/Icon/test-avatar.svg';
    },
  },
  {
    name: "John Ebah",
    designation: "PHP Developer Mobile",
    department: "Technical",
     hobby:["Leadership", "Dancing", "Highly Approachable"],
    country: "Nigeria",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return 'assets/Icon/test-avatar.svg';
    },
  },
  {
    name: "Ghadeer Swaity",
    designation: "Translator",
    department: "Technical",
     hobby:["Leadership", "Dancing", "Highly Approachable"],
    country: "Palestine",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return 'assets/Icon/test-avatar.svg';
    },
  },

  {
    name: "Eweje Femi",
    designation: "Backend Engineer",
    department: "Technical",
     hobby:["Leadership", "Dancing", "Highly Approachable"],
    country: "Nigeria",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return 'assets/Icon/test-avatar.svg';
    },
  },
  {
    name: "Abayomi Aluko",
    designation: "Vue JS Developer",
    department: "Technical",
     hobby:["Leadership", "Dancing", "Highly Approachable"],
    country: "Nigeria",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.png`;
    },
  },
  {
    name: "David Okononfua",
    designation: "Vue JS Developer",
    department: "Technical",
     hobby:["Loves Technology"],
    country: "Nigeria",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.png`;
    },
  },
  {
    name: "Adolf Sylensa",
    designation: "Flutter Developer",
    department: "Technical",
     hobby:["Leadership", "Dancing", "Highly Approachable"],
    country: "Ghanaian",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return 'assets/Icon/test-avatar.svg';
    },
  },
 
  {
    name: "Muath Qannita",
    designation: "Head of Human Resources",
    department: "Human Resources",
     hobby:["Yaprak sarma enthusiast", "loves catching Z's", "Footie Fanatic"],
    country: "Palestine",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  {
    name: "İrem Bayrak",
    designation: "People Operations Assistant",
    department: "Human Resources",
     hobby:["Art Aficionado", "Aspiring Web-Weaver", "Yoga Enthusiast"],
    country: "Turkey",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  {
    name: "Nur Gökçemen",
    designation: "People Operations Assistant",
    department: "Human Resources",
     hobby:["Mermaid", "Sunshine in human form", "Motivation station"],
    country: "Turkey",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  
  {
    name: "Abdulrahman Nassar",
    designation: "Organization Design Assistant",
    department: "Human Resources",
     hobby:["Leadership", "Dancing", "Highly Approachable"],
    country: "Palestine",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return 'assets/Icon/test-avatar.svg';
    },
  },
  {
    name: "Mariam Omer",
    designation: "Organization Design Assistant",
    department: "Human Resources",
     hobby:["Leadership", "Dancing", "Highly Approachable"],
    country: "Sudan",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return 'assets/Icon/test-avatar.svg';
    },
  },
  {
    name: "Felix Okoye",
    designation: "Performance Manager",
    department: "Human Resources",
     hobby:["Leadership", "Dancing", "Highly Approachable"],
    country: "Nigeria",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return 'assets/Icon/test-avatar.svg';
    },
  },
  {
    name: "Yosra Farkash",
    designation: "Recruitment Specialist",
    department: "Human Resources",
     hobby:["Leadership", "Dancing", "Highly Approachable"],
    country: "Libya",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return 'assets/Icon/test-avatar.svg';
    },
  },
  {
    name: "Dayeabasi Enoidem",
    designation: "Recruitment Specialist",
    department: "Human Resources",
     hobby:["Leadership", "Dancing", "Highly Approachable"],
    country: "Nigeria",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return 'assets/Icon/test-avatar.svg';
    },
  },
  {
    name: "Priyanka Verma",
    designation: "Recruitment Specialist",
    department: "Human Resources",
     hobby:["Leadership", "Dancing", "Highly Approachable"],
    country: "India",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return 'assets/Icon/test-avatar.svg';
    },
  },
  {
    name: "Seda Öztürk",
    designation: "Recruitment Specialist",
    department: "Human Resources",
     hobby:["Leadership", "Dancing", "Highly Approachable"],
    country: "Turkey",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return 'assets/Icon/test-avatar.svg';
    },
  },
  {
    name: "Tohura Chowdhury",
    designation: "Policy Maker",
    department: "Human Resources",
     hobby:["Leadership", "Dancing", "Highly Approachable"],
    country: "British/Bangladeshi",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return 'assets/Icon/test-avatar.svg';
    },
  },
  {
    name: "Rehana Maryyam",
    designation: "Up2Us Project Manager",
    department: "Communities",
     hobby:["Dancing Dynamo", "Linguistic Luminary", " Opening Act Ace"],
    country: "Pakistan",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  {
    name: "Ghita Morsli",
    designation: "Project Manager",
    department: "Communities",
     hobby:["Leadership", "Dancing", "Highly Approachable"],
    country: "Morocco",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return 'assets/Icon/test-avatar.svg';
    },
  },
  {
    name: "Anıl Akbel",
    designation: "Project Coordinator",
    department: "Communities",
     hobby:["Leadership", "Dancing", "Highly Approachable"],
    country: "Turkey",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return 'assets/Icon/test-avatar.svg';
    },
  },
  {
    name: "Berkay Ocak",
    designation: "Project Manager",
    department: "Communities",
     hobby:["Leadership", "Dancing", "Highly Approachable"],
    country: "Turkey",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return 'assets/Icon/test-avatar.svg';
    },
  },
  {
    name: "Nilay Şentürk",
    designation: "Project Manager",
    department: "Communities",
     hobby:["Leadership", "Dancing", "Highly Approachable"],
    country: "Turkey",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return 'assets/Icon/test-avatar.svg';
    },
  },
  {
    name: "Wajahat Sheikh",
    designation: "Head Of Product",
    department: "Product",
     hobby:["Biryani Boss", "Tech Trailblazer", "Gaming Gourmet"],
    country: "Pakistan",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  {
    name: "Mazeedah Yaqub",
    designation: "UX Designer",
    department: "Product",
     hobby:["Leadership", "Dancing", "Highly Approachable"],
    country: "Nigeria",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return 'assets/Icon/test-avatar.svg';
    },
  },
  {
    name: "Muhammed Tijjani-Garba",
    designation: "UX Designer",
    department: "Product",
     hobby:["Leadership", "Dancing", "Highly Approachable"],
    country: "Nigeria",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return 'assets/Icon/test-avatar.svg';
    },
  },
  {
    name: "Sulaiman Ademola Idris",
    designation: "UI Designer",
    department: "Product",
     hobby:["Leadership", "Dancing", "Highly Approachable"],
    country: "Nigeria",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return 'assets/Icon/test-avatar.svg';
    },
  },
  {
    name: "Owodulu Opeoluwa Emmanuel",
    designation: "Motion Designer",
    department: "Product",
     hobby:["Leadership", "Dancing", "Highly Approachable"],
    country: "Nigeria",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return 'assets/Icon/test-avatar.svg';
    },
  },
  {
    name: "Ihetu Chimdinma",
    designation: "UI/UX Designer",
    department: "Product",
     hobby:["Leadership", "Dancing", "Highly Approachable"],
    country: "Nigeria",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return 'assets/Icon/test-avatar.svg';
    },
  },
  {
    name: "Bersan Okkalı",
    designation: "UI Designer",
    department: "Product",
     hobby:["Leadership", "Dancing", "Highly Approachable"],
    country: "Turkey",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return 'assets/Icon/test-avatar.svg';
    },
  },
  {
    name: "Gift Obafaiye",
    designation: "UI/UX Designer",
    department: "Product",
     hobby:["Leadership", "Dancing", "Highly Approachable"],
    country: "Nigeria",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return 'assets/Icon/test-avatar.svg';
    },
  },
  {
    name: "Sara Abbasi",
    designation: "UI/UX Designer",
    department: "Product",
     hobby:["Feline fanatic", "Adventure seeker", "Efficiency expert"],
    country: "Iran",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  {
    name: "Jade Martin",
    designation: "Head of Graphic Design",
    department: "Creative",
     hobby:["Art-o-holic", "Sketchy Wizard", "Page-turning Prodigy"],
    country: "Philippines",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  {
    name: "Aisha Shahab",
    designation: "Creative Designer",
    department: "Creative",
     hobby:["Caninephobe", "Yolkless Yum-seeker", "Karaoke Queen"],
    country: "Pakistan",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return `assets/Images/profile-images/${this.name}.webp`;
    },
  },
  {
    name: "Paula Domínguez",
    designation: "Graphic Designer",
    department: "Creative",
     hobby:["Leadership", "Dancing", "Highly Approachable"],
    country: "Dominican Republic",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return 'assets/Icon/test-avatar.svg';
    },
  },
  {
    name: "Bonu Saidi",
    designation: "Graphic Designer",
    department: "Creative",
     hobby:["Leadership", "Dancing", "Highly Approachable"],
    country: "Tajikistan",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return 'assets/Icon/test-avatar.svg';
    },
  },
  {
    name: "Pratap Singh",
    designation: "Graphic Designer",
    department: "Creative",
     hobby:["Leadership", "Dancing", "Highly Approachable"],
    country: "India",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return 'assets/Icon/test-avatar.svg';
    },
  },
  {
    name: "Wajiha Attique",
    designation: "Graphic Designer",
    department: "Creative",
     hobby:["Leadership", "Dancing", "Highly Approachable"],
    country: "Pakistan",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return 'assets/Icon/test-avatar.svg';
    },
  },
  {
    name: "Rukaiya Jannath",
    designation: "Graphic Designer",
    department: "Creative",
     hobby:["Leadership", "Dancing", "Highly Approachable"],
    country: "Bangladesh",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return 'assets/Icon/test-avatar.svg';
    },
  },
  {
    name: "Kezzy",
    designation: "Graphic Designer",
    department: "Creative",
     hobby:["Leadership", "Dancing", "Highly Approachable"],
    country: "Nigeria",
    flag: function () {
      return `assets/Images/flags/${this.country}.png`;
    },
    image: function () {
      return 'assets/Icon/test-avatar.svg';
    },
  },
  
];
