import React from "react";
import { Link } from "react-router-dom";

const MobileMenuItem = ({ to, isButton, external, className, children }) => {
  if (!isButton && !external) {
    return (
      <li
        className={`py-4 border-b border-solid border-b-black border-x-0 border-t-0 text-[32px] leading-9 font-normal ${className}`}
      >
        <Link
          to={to}
          className="text-[#131313] hover:opacity-90 transition-opacity duration-150"
        >
          {children}
        </Link>
      </li>
    );
  }

  if (isButton && external) {
    return (
      <li className={`${className}`}>
        {/* Made hidden */}
        {/* <a href={to}>
          <button className="text-base font-normal leading-4 text-text-primary py-3 px-6 bg-btn-background-1 rounded-full border-none outline-none hover:opacity-90 transition-opacity duration-150">
            {children}
          </button>
        </a> */}

      </li>
    );
  }
};

export default MobileMenuItem;
