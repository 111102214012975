import React from 'react';
import { Link } from 'react-router-dom';
import { useIsPathActive } from '../../../../hooks/useIsPathActive';

const NavigationLink = ({ className, button, external, href, children }) => {
  const { isMatch, isIndex } = useIsPathActive(href);

  const isActive = (href) => {
    // We are on the index page and the current href is '/'
    if (isIndex && href === '/') {
      return 'active';
    }

    // We are not on the index page and the current href is '/'
    if (!isIndex && href === '/') {
      return '';
    }

    // The current path matches the current href
    if (isMatch) {
      return 'active';
    }

    return '';
  };

  if (!button) {
    // normal nav link
    return (
      <li>
        <Link to={{ pathname: href || '#' }}>
          <span
            className={`text-[#131313] hover:opacity-80 transition-opacity duration-150 ${className} ${isActive(
              href,
            )}`}
          >
            {children}
          </span>
        </Link>
      </li>
    );
  }

  if (button && external) {
    return (
      <li>
        <a href={href}>
          {/* Made hidden */}
          <button
            className={`text-text-primary hidden bg-btn-background-1 cursor-pointer py-3 px-6 rounded-full border-none outline-none font-normal text-base leading-[18px] hover:opacity-90 transition-opacity duration-150 ${className}`}
          >
            {children}
          </button>
        </a>
      </li>
    );
  }
};

export default NavigationLink;
