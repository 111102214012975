import React, { useState } from 'react';

import Layout from '../../components/Layout';
import { postData } from '../../api/axios';
import usePageMetadata from '../../lib/usePageMetadata';
import { showModal } from '../../lib/modal';

const Deactivate = () => {

  const [email, setEmail] = useState(''); // Fix 1: Initialize with an empty string
  usePageMetadata('Deactivate Account | Paramount Students');

  const deleteAccount = (e) => {
    e.preventDefault(); // Fix 4: Prevent default form submission
    postData('/api/deactivate-account', { email }) // Fix 2: Correct function call
      .then((data) => {
        showModal({ text: 'We have sent a confirmation message to your mail.' });
      })
      .catch((error) => {
        console.log('Error:', error);
      });
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value); // Fix 3: Correctly set email
  };

  return (
    <Layout transparentHeaderOnPhone>
      <div className='h-[100vh] w-full flex justify-center items-center' >
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md"> {/* Fix 5: Use className */}
        <h1 className="text-xl font-semibold mb-4">Delete Account</h1>
        <p className="text-gray-700 mb-8">
          Please note that this action is irreversible. Once your account is deleted, all data associated with it will be<b> permanently removed from Paramount Students.</b>
        </p>
        <form onSubmit={deleteAccount}> {/* Change to handle form submission */}
          <div className="mb-4">
            <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">Confirm Email:</label>
            <input
              type="text"
              id="email"
              onKeyUp={handleEmailChange}
              name="email"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>
          <div className="flex justify-between items-center">
            <button type="submit" className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Delete Account</button>
            <button type="button" className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Cancel</button>
          </div>
        </form>
      </div>
      </div>
    </Layout>
  );
};

export default Deactivate;
