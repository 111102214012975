import { Disclosure } from "@headlessui/react";
import { IoMdArrowDropleft } from "react-icons/io";
import { FAQs } from "../../../../lib/static/frequently_asked_questions";
import Container from "../../../Layout/Container";
import "./FAQsSection.css";
import { useTranslation } from 'react-i18next';

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const FAQsSection = () => {
  const {t}=useTranslation()
  return (
    <section className="section docs-faqs-section flex flex-col items-center">
      <Container maxWidth="md">
        <dl className="space-y-4 md:space-y-6">
          {FAQs.map((faq) => (
            <Disclosure
              as="div"
              key={faq.question}
              className="faqs-faq bg-white rounded-xl md:rounded-3xl p-4 md:p-12"
            >
              {({ open }) => (
                <>
                  <dt className="text-lg">
                    <Disclosure.Button className="text-left w-full flex justify-between items-center text-base md:text-2xl font-normal leading-6 md:leading-9 text-black bg-transparent border-none outline-none">
                      <span className="font-medium text-gray-900">
                        {t(faq.question)}
                      </span>
                      <span className="ml-6 h-7 flex items-center">
                        <IoMdArrowDropleft
                          className={classNames(
                            open ? "-rotate-90" : "rotate-0",
                            "h-6 w-6 transform transition-transform duration-150"
                          )}
                          aria-hidden="true"
                        />
                      </span>
                    </Disclosure.Button>
                  </dt>
                  <Disclosure.Panel as="dd" className="mt-4 md:mt-10 pr-16">
                    <p className="text-xs text-justify md:text-xl font-normal leading-[18px] md:leading-[30px] text-gray-500">
                      {t(faq.answer)}
                    </p>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
        </dl>
      </Container>
    </section>
  );
};

export default FAQsSection;
