import React, { useEffect } from "react";
import Layout from "../../components/Layout";

import useWindowSize from "../../hooks/useWindowSize";
import HeroImage from "../../assets/Images/incubation-center-hero-image.webp";
import MHeroImage from "../../assets/Images/mobile-incubation-hero-image.webp";

import "./IncubationCenters.css";

import { incubationCenters } from "../../components/Incubation/locations";

import rightArrow from "../../assets/Icon/rightArrowVector.svg";

import Call from "../../assets/Icon/iconCall.svg";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { useTranslation } from 'react-i18next';
import setPageDescription from "../../lib/utils/setPageDescription";

const IncubationCenters = () => {
  useEffect(() => {
    document.title = "Incubation Centre | A Network Of Mentors | Paramount Students";

    setPageDescription('At Paramount Students, we are inspired by working collaboratively with many talents around the world to create endless opportunities for aspiring international students.');
    
  }, []);
  const size = useWindowSize();
  const { t} = useTranslation();
  return (
    <div>
      <Layout
        transparentHeaderOnPhone={size?.width < 900}
        className="homepage-layout"
      >
        <main className="page home-page md:pt-20">
          <div className="incubation-hero-section mb-[100px]">
            <div className="hero-image">
              <img
                loading="lazy"
                className="w-full hidden h-auto md:block"
                src={HeroImage}
                alt="icon"
              />
              <img
                loading="lazy"
                className="w-full h-auto block md:hidden"
                src={MHeroImage}
                alt="icon"
              />
              <div className="incubation-hero-overlay  flex flex-col ">
                <div className="p md:p-0 mt-[100px]">
                  <div className="px-4 incubation-heading mb-[20px] ">
                    <p>{t("We Build Relationships across Countries")}</p>
                  </div>
                  <div className="incubation-hero-text">
                    <p className="pr-12 px-4 md:px-0 md:text-[20px] md:w-[642px] md:leading-[28px]">
                      {t("Paramount Students is a place created to nurture and develop talents from all over the world through excellent teamwork. It provides direct access to hands-on intense business support and experience. This is an excellent starting point for implementing research findings and activities that can aid in solving societal problems")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section className="incubation-centers-section">
            <div className="incubation-section ">
              <Splide
                hasTrack={false}
                aria-label="..."
                options={{
                  perPage: 1,
                  arrows: true,
                  pagination: false,
                  drag: "free",
                  type: "loop",
                }}
              >
                <SplideTrack>
                  {incubationCenters.map((centers, index) => {
                    return (
                      <SplideSlide key={index}>
                        <div className="incubation-center-contacts">
                          <div className="incubation-contact relative px-4 md:px-16  md:flex flex-row-reverse gap-14">
                            <div className="profile-wrapper flex flex-col ">
                              <div className="country">
                                <p className="uppercase mb-4  text-[14px] md:text-[22px] leading-6">
                                  {centers.title}
                                </p>
                                <p className="capitalize md:mb-6  text-[20px] md:text-[30px]  md:leading-[50.4px]">
                                  {centers.name}
                                </p>
                              </div>
                              <div className="profile flex items-center gap-4">
                                <div className="profile icon">
                                  <img
                                    loading="lazy"
                                    src={centers.userIcon}
                                    alt="UserIcon"
                                  />
                                </div>
                                <div className="profile details ">
                                  <p className="rep-name   text-[#5151FD] text-base mb-2 leading-4 capitalize">
                                    {centers.rep}
                                  </p>
                                  <p className="text-[20px] mb-5 leading-[23px] md:text-[24px] md:leading-[27px] capitalize">
                                    {centers.repName}
                                  </p>
                                </div>
                              </div>
                              <div className="profile flex  gap-4 ">
                                <div className="profile icon">
                                  <img
                                    loading="lazy"
                                    className=""
                                    src={centers.locationIcon}
                                    alt="locationIcon"
                                  />
                                </div>
                                <div className="profile mb-5 details">
                                  <p className="text-[12px] mb-5 leading-5 md:text-[16px] md:w-[532px] md:leading-[25px]    capitalize">
                                    {centers.location}
                                  </p>
                                </div>
                              </div>
                              <div className="flex items-center  mb-6">
                                <a
                                  className="bannerBtn2 py-4 hover:bg-[#ffc400] text-black items-center flex gap-[9px]"
                                  href="mailto:khalid@paramountstudents.com"
                                >
                                  Contact Us
                                  <img loading="lazy" src={Call} alt="phone" />
                                </a>
                              </div>
                            </div>

                            <div className="incubation-section-carouselImg  ">
                              <img
                                loading="lazy"
                                className="w-full  "
                                src={centers.image}
                                alt="incubation center img"
                              />
                            </div>
                          </div>
                        </div>
                      </SplideSlide>
                    );
                  })}
                </SplideTrack>
                <div className="splide__arrows">
                  <button className="splide__arrow splide__arrow--prev">
                    <span className="">
                      <img
                        loading="lazy"
                        className="w-[13.33px] flex md:w-[26px]"
                        src={rightArrow}
                        alt="left arrow"
                      />
                    </span>
                  </button>

                  <button className="splide__arrow splide__arrow--next">
                    <span className="spanLeft ">
                      <img
                        loading="lazy"
                        className="w-[13.33px] flex md:w-[26px]"
                        src={rightArrow}
                        alt="right arrow"
                      />
                    </span>
                  </button>
                </div>
              </Splide>
            </div>
          </section>
        </main>
      </Layout>
    </div>
  );
};

export default IncubationCenters;
