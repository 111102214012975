const FAQs = [
  {
    id: "1",
    question: "What services do Paramount students provide?",
    answer:
      "Paramount students enable all parties to participate in the global educational process by connecting students with academic resources and financial options. Students are quickly matched with the universities of their choice. Students only need to modify their academic courses to meet the requirements of a partner university.",
  },
  {
    id: "2",
    question: "What types of intakes do you provide?",
    answer:
      "Our primary intake months are February, May, and October. This varies depending on the program; for more information, please see our programs here.",
  },
  {
    id: "3",
    question: "How much are the tuition fees?",
    answer:
      "Tuition fees vary depending on the program you are enrolled in. For more specific information on the cost of a particular program.",
  },
  {
    id: "4",
    question: "Is there an application fee?",
    answer:
      "Tuition fees vary depending on the program you are enrolled in. For more specific information on the cost of a particular program.",
  },
  {
    id: "5",
    question: "Do I need to pay a deposit?",
    answer:
      "If you accept an offer to study on an undergraduate or postgraduate programme, you will be required to pay a deposit. After accepting your conditional offer, you should receive a deposit invoice. Your reservation will be fully secured once we receive your deposit payment. Please contact us if you have any questions about paying your deposit.",
  },
  {
    id: "6",
    question: "Is accommodation services available at Paramount students?",
    answer:
      "In various countries,  Paramount students provide dormitory services to selected students. Look through the numerous options we've hand-picked for you (a link to the dormitory page can be added here).",
  },
  {
    id: "7",
    question: "What exactly are communities?",
    answer:
      "Paramount students communities are platforms designed for students to have fun and connect with others while studying. You can look through our many communities by clicking here  (a link to the communities page can be added here).",
  },
];

export { FAQs };
