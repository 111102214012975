import React, { useCallback, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";

import { MdArrowForward, MdArrowBack } from "react-icons/md";

import "swiper/css";
import "swiper/css/navigation";
import { aboutCarousalSlides } from "../../../lib/static/aboutCarousalSlides";
import CarousalSlide from "./CarousalSlide";
import FadeWhileInView from "../../Animations/FadeWhileInView";
import { useTranslation } from 'react-i18next';

SwiperCore.use([Navigation]);

// Import Swiper styles

const AboutCarousalSection = () => {
  const sliderRef = useRef(null);
const {t}=useTranslation();
  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  return (
    <section className="section about-section about-carousal-section pb-8 md:pb-36 md:pt-8 lg:pt-0">
      <div className="about-carousal-section-content">
        <div className="about-carousal-container relative">
          <Swiper
            ref={sliderRef}
            loop={true}
            className="about-swiper-carousal relative"
          >
            {aboutCarousalSlides.map((slide) => (
              <SwiperSlide key={slide.title}>
                <CarousalSlide 
                  title={t(slide.title)}
                  description={t(slide.description)}
                  bannerImage={slide.bannerImage}
                  altText={slide.alttext}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="about-swiper-carousal-navigation mt-5 md:mt-0 md:absolute md:top-1/2 md:-translate-y-1/2 z-[4] w-full px-4 sm:px-6 md:px-8 lg:px-12">
            <div className="w-full flex justify-center space-x-4 md:space-x-0 md:justify-between relative">
              <FadeWhileInView>
                <div
                  className="about-carousal-previous group bg-text-primary border border-solid border-gray-400 hover:bg-white shadow cursor-pointer rounded-full w-12 xl:w-16 h-12 xl:h-16 flex items-center justify-center"
                  onClick={handlePrev}
                >
                  <MdArrowBack className="w-6 h-6 xl:w-8 xl:h-8 text-white group-hover:text-text-primary transition-colors duration-150" />
                </div>
              </FadeWhileInView>
              <FadeWhileInView>
                <div
                  className="about-carousal-forward group bg-white hover:bg-text-primary border border-solid border-gray-400 hover:border-text-primary shadow cursor-pointer rounded-full w-12 xl:w-16 h-12 xl:h-16 flex items-center justify-center transition-colors duration-150"
                  onClick={handleNext}
                >
                  <MdArrowForward className="w-6 h-6 xl:w-8 xl:h-8 text-text-primary group-hover:text-white transition-colors duration-150" />
                </div>
              </FadeWhileInView>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutCarousalSection;
