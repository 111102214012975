import React from "react";
import Lottie from "react-lottie";
import animation from "../../../assets/Animations/our-services-hero-animation-new.json";
import "./ServicesHero.css";
import { useTranslation } from 'react-i18next';

const ServicesHero = () => {
  const { t } = useTranslation();
  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    renderer: "canvas",
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <section className="section services-hero-section bg-background1 pt-20">
      <div className="services-hero-section-container flex flex-col items-center">
        <div className="services-hero-section-top px-8 md:px-[100px] lg:px-[250px] xl:px-[300px] pt-[50px] md:pt-[80px]">
          <h2 className="services-hero-section-heading text-white font-bold text-3xl md:text-6xl text-center capitalize leading-[120%]">
            {t("We are building a revoluntry product and quality service")}
          </h2>
        </div>
        <div className="services-hero-section-bottom w-full mt-12 md:mt-24 h-[55vh]">
          <Lottie options={lottieOptions} width="100%" isClickToPauseDisabled />
        </div>
      </div>
    </section>
  );
};

export default ServicesHero;
