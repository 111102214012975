import React from "react";
import Applinsk from "./Applinsk";

const MakingEffortless = () => {
  return (
    <div
      className="py-10 relative pr-4"
      style={{
        backgroundImage: `linear-gradient(to bottom right, #f8fff5 15%, #ffffff 80%, #ddbaff 100%)`,
      }}>
      <img
        src="/mobileapp/bottom-curve.png"
        alt="bottom curve"
        className="bottom-0 left-0 w-16 sm:w-20 lg:w-56 absolute"
      />
      <div className="flex flex-row justify-center items-center">
        <p className="font-recoleta font-bold text-gray-900 text-2xl lg:text-6xl">
          Making it effortless for you
        </p>
      </div>
      <div className="flex flex-row-reverse items-center pt-10 w-full mt-10 h-full pb-10 ">
        <div className="w-1/2 flex flex-col items-center h-full ">
          <div className="flex flex-col w-[200px] sm:w-[300px] md:w-[400px] lg:w-[560px] transition-all duration-500 ease-in-out lg:space-y-10">
            <div className="space-y-2">
              <h4 className="font-bold font-recoleta sm:text-xl md:text-2xl lg:text-3xl transition-all duration-500 ease-in-out">
                Streamlined Applications
              </h4>
              <p className="font-graphik text-[10px] sm:text-[14px] md:text-[16px] lg:text-[24px] transition-all duration-500 ease-in-out">
                Say goodbye to tedious application processes. Apply to multiple
                universities with just a few clicks, all from the convenience of
                your mobile device.
              </p>
            </div>
            <div className="w-full mt-2 h-auto flex flex-row ">
              <Applinsk />
            </div>
          </div>
        </div>
        <div
          className="w-1/2 flex flex-col items-center justify-center"
          style={{
            background:
              "radial-gradient(circle at 25% 50%, rgba(198, 255, 176) 0%, rgba(209, 255, 197) 10%, rgba(220, 255, 212 ) 20%, rgba(255,255,255,0) 30%)",
          }}>
          <div className="transition-all duration-500 ease-in-out relative">
            <img
              src="/mobileapp/effortless.png"
              alt="effortless"
              className="h-[250px] w-[120px] sm:h-[300px] sm:w-[150px] md:h-[500px] md:w-[250px] lg:h-[600px] lg:w-[300px] transition-all duration-500 ease-in-out "
            />
            <img
              src="/mobileapp/start.png"
              alt="I"
              className="w-4 md:w-10 absolute -top-4 -left-4 md:-top-16 md:-left-16 rota-12"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MakingEffortless;
